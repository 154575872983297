import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';
import util, {layComponent, getPropertyNavName} from '../util';

const create = PanelFactory.create;

export default function (app) {
  return (
    <Route menuKey="113"
      name="风控"
      path="/risk"
      component={create()}
      onEnter={util.requireAuth}
    >
      <Route menuKey="113001"
        name="风控中心"
        path="/risk/controlCenter"
        component={create()}
        onEnter={util.requireAuth}>
        <IndexRoute onEnter={(nextState, replace) => replace('/risk/controlCenter/center')}/>
        <Route menuKey=""
          name=""
          path="/risk/controlCenter/center"
          component={layComponent(() => import('../../RiskControl/ControlCenter/ControlCenter'))}
          onEnter={util.requireAuth}
          getDisplayName={getPropertyNavName('')}/>
        {/* <Route menuKey=""  name="" path="/risk/controlCenter/control/(:action)" component={layComponent(RiskControl, "ControlIndex")}*/}
        {/*onEnter={util.requireAuth} getDisplayName={getPropertyNavName("")}/>*/}
        {/*<Route name="所有管控记录" path="/risk/controlCenter/all/(:action)" component={layComponent(RiskControl, "ControlIndex")}*/}
        {/*onEnter={util.requireAuth} getDisplayName={getPropertyNavName("")}/>*/}
        <Route menuKey=""
          name="锁定客房管控记录"
          path="/risk/controlCenter/door/(:action)"
          component={layComponent(() => import('../../RiskControl/ControlCenter/index'))}
          onEnter={util.requireAuth}
          getDisplayName={getPropertyNavName('')}/>
        <Route menuKey=""
          name="暂停供电管控记录"
          path="/risk/controlCenter/meter/(:action)"
          component={layComponent(() => import('../../RiskControl/ControlCenter/index'))}
          onEnter={util.requireAuth}
          getDisplayName={getPropertyNavName('')}/>
        <Route menuKey=""
          name="暂停供水管控记录"
          path="/risk/controlCenter/water/(:action)"
          component={layComponent(() => import('../../RiskControl/ControlCenter/index'))}
          onEnter={util.requireAuth}
          getDisplayName={getPropertyNavName('')}/>
      </Route>
      <Route menuKey="113002"
        name="欠费管控"
        path="/risk/arrears"
        component={create()}
        onEnter={util.requireAuth}>
        <IndexRoute onEnter={(nextState, replace) => replace('/risk/arrears/policy/policyConfig')}/>
        <Route menuKey="113002001"
          name="管控策略"
          path="/risk/arrears/policy/(:action)"
          component={layComponent(() => import('../../RiskControl/ControlStrategy'))}
          onEnter={util.requireAuth}
          getDisplayName={getPropertyNavName('')}/>
        <Route menuKey="113002002"
          name="白名单"
          path="/risk/arrears/whiteList"
          component={layComponent(() => import('../../RiskControl/WhiteList/WhiteList'))}
          onEnter={util.requireAuth}/>
      </Route>
    </Route>
  )
}
