/* eslint-disable */
import React from 'react';
import { Router, Route, IndexRoute, hashHistory } from '@unovo/react-router';
import { hot, setConfig } from 'react-hot-loader';

import routeList from './routes/modules';
import util from './routes/util';

function NotFound() {
  return <div>未找到页面!</div>;
}

import BlankLayout from '../layouts/BlankLayout';
import BasicLayout from '../layouts/BasicLayout';
import SignIn from './User/SignIn/index';
import Login from './User/Login/index';
import Center from './User/SignIn/center';
import Home from './Home/index';
import Download from './../components/Header/Download';

const routes = app => (
  <Router history={hashHistory}>
    <Route name="首页" path="/" component={BlankLayout}>
      <IndexRoute onEnter={(nextState, replace) => replace('/home')} />
      <Route name="signin" path="/signin" component={SignIn} />
      <Route name="login" path="/login" component={Login} />
      <Route name="center" path="/center" component={Center} />

      <Route name="wrapper" path="/wrapper" component={BasicLayout} onEnter={util.requireAuth}>
        <Route name="home" path="/home" component={Home} onEnter={util.onHomeEnter} menuKey={'home'} mKey="home" />
        <Route name="下载内容" path="/download" component={Download} onEnter={util.onHomeEnter} menuKey={'download'} mKey="download" />
        {routeList.assets(app)}
        {routeList.assetOwner(app)}
        {routeList.service(app)}
        {routeList.equipment(app)}
        {routeList.property(app)}
        {routeList.operation(app)}
        {routeList.configuration(app)}
        {routeList.zone(app)}
        {routeList.risk(app)}
        {routeList.financial(app)}
        {routeList.report(app)}
        {routeList.room(app)}
        {routeList.customer(app)}
        {routeList.employee(app)}
      </Route>
      <Route path="*" component={NotFound} />
    </Route>
  </Router>
);

function RouterConfig({ history, app }) {
  return <Router history={hashHistory}>{routes(app)}</Router>;
}

export default hot(module)(routes);
