import React from 'react';
import {Route, IndexRoute} from 'react-router';

import Panel from '../../../layouts/Panel';
import util, {layComponent} from '../util';

export default function service(app) {
  return (
    <Route
      name="服务"
      path="/service"
      component={Panel}
      onEnter={util.requireAuth}
      menuKey={108}
    >
      <IndexRoute onEnter={(nextState, replace) => replace('/service/dynamic')}/>
      <Route
        name="服务动态"
        path="/service/dynamic"
        component={layComponent(() => import('../../service/Dynamic'))}
        onEnter={util.requireAuth}
        menuKey={108001}
      />
      <Route
        name="服务管理"
        path="/service/management"
        component={layComponent(() => import('../../service/Management/Management'))}
        onEnter={util.requireAuth}
        menuKey={108002}
      />
      <Route
        name="评价管理"
        path="/service/evaluation"
        component={layComponent(() => import('../../service/Evaluation/Evaluation'))}
        onEnter={util.requireAuth}
        menuKey={108003}
      />
      <Route
        name="用户反馈"
        path="/service/compAndSugg"
        component={layComponent(() => import('../../service/CompAndSugg'))}
        onEnter={util.requireAuth}
        menuKey={108004}
      />
    </Route>
  )
}
