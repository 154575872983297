/**
 * Created by ruiy on 10/13/15.
 */
import React from 'react';
import { Util, MessageNew } from 'component';
import { notification, Button } from 'antd';

import '../../../assets/css/footer.css';
import config from '../../../config/config';
import DeployInfoModal from '../../../components/DeployInfoModal';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      deployment: {},
    };
  }

  componentDidMount() {}

  notification = () => {
    const params = {
      message: '更新提示',
      description: '检查到系统有新功能发布，是否立即查看？',
      duration: 0,
      btn: (
        <Button type="primary" size="small" onClick={this.onVersionClick}>
          查看
        </Button>
      ),
      key: config.deployKey,
    };
    if (this.state.showNotification) {
      notification.open(params);
    }
  };

  onVersionClick = e => {
    e.preventDefault();
    this.refs.update.open();
  };

  render() {
    const { hide = false } = this.props;

    return (
      <footer style={{ display: hide ? 'none' : 'block' }}>
        <div className="footer" ref="footer">
          <span className="nopadding copyright">COPYRIGHT ©上海联寓智能科技有限公司</span>
        </div>
      </footer>
    );
  }
}

export default Footer;
