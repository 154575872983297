import React, { Component } from 'react';
import { Row } from 'antd';
import MethodMe from './MethodMe';

const { checkFieldData } = MethodMe;

export default class TableMe extends Component {
  static displayName = 'TableMe';

  renderColumnDom = (td, spans, otherProps) => {
    if (checkFieldData(td, 'elementType', 'column')) {
      return td.children.map(item => {
        if (checkFieldData(item, 'fieldType', 'url')) {
          return <span className="fieldme">{item.value}</span>;
        }
      });
    }
  };

  renderRowDom = (th, spans, otherProps) => {
    if (checkFieldData(th, 'elementType', 'row')) {
      return (
        <Row className="row" key={'row' + th.order}>
          {th.children.map(item => {
            return this.renderColumnDom(item, spans, otherProps);
          })}
        </Row>
      );
    }
  };

  render() {
    const { data = [], scope = '', ...otherProps } = this.props,
      rows = data.children ? data.children.length : 0;

    if (checkFieldData(data, 'elementType', 'block') && rows > 0) {
      const columns = data.children[0].children.length,
        spans = Math.floor(24 / columns);
      return (
        <div className={'blockme blockme-' + scope}>
          {data.children.map(item => {
            return this.renderRowDom(item, spans, otherProps);
          })}
        </div>
      );
    }
    return null;
  }
}
