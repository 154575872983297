/**
 * 资源价格相关字典
 */

export default {
  // 固定金额 or 计量
  measurementType: {
    measure: 0, // 计量
    regular: 1, // 固定金额
  },
  meterBigType: {
    LIANYU: 1,  // 联寓计量
    NOLIANYU: 2, // 非联寓计量
    NONE: 3,  // 无计量表
    SANGRUI: 4, // LoRa计量表(桑瑞)
  },
  apportionType: {
    None: 0, // 不分摊
    Customer: 1, // 按住客
    Households: 2 // 按住户
  },
  // 不收取该项费用
  withOutFree: {
    measure: -888, // 计量
    regular: -999  // 固定金额
  },
  meterTotalType: {
    TOTAL: 2,  // 总表
    NotTOTAl: 1, // 非总表
  },
}
