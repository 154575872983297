export const authMobile = (args) => {
  const url = common.baseInfo.authMobile;

  return common.ajaxPromise(url, args, 'POST', 'json', false);
}


export const sendCode = (args) => {
  const url = common.baseInfo.sendSignupCode;

  return common.ajaxPromise(url, args, 'POST', 'json');
}
