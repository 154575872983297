import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';

import util, {layComponent} from '../util';

const create = PanelFactory.create;

export default function (app) {
  return (
    <Route
      menuKey="105"
      name="配置"
      path="/config"
      component={create()}
      onEnter={util.requireAuth}
    >
      <IndexRoute onEnter={(nextState, replace) => replace('/config/product/list')}/>
      <Route
        menuKey="105001"
        name="客房产品"
        path="/config/product/(:action)"
        component={layComponent(() => import('../../config/Product'))}
        onEnter={util.requireAuth}
      />
      <Route
        menuKey="105002"
        name="资源价格"
        path="/config/resourcePrice/"
        component={create()}
        onEnter={util.requireAuth}
      >
        <Route
          menuKey=""
          name="阶梯收费方案"
          path="/config/resourcePrice/differentialList/list/(:subBillSubject)"
          component={layComponent(() => import('../../config/DifferentialList'))}
          onEnter={util.requireAuth}
        />
        <Route
          menuKey=""
          name="官方收费方案"
          path="/config/resourcePrice/officalList/list/(:subBillSubject)"
          component={layComponent(() => import('../../config/OfficalList'))}
          onEnter={util.requireAuth}
        />
        <Route
          menuKey="105002001"
          name="资源价格配置"
          path="/config/resourcePrice/(:action)"
          component={layComponent(() => import('../../config/ResourcePrice'))}
          onEnter={util.requireAuth}
        />
      </Route>
      <Route
        menuKey="105003"
        name="共享资源"
        path="/apartment/resources/(:action)"
        component={layComponent(() => import('../../config/SharedResources'))}
        onEnter={util.requireAuth}
      />
      <Route
        menuKey="105004"
        name="支付方式"
        path="/apartment/rentpaymethod"
        component={layComponent(() => import('../../apartment/RentPayMethod'))}
        onEnter={util.requireAuth}
      />
      <Route
        menuKey="105005"
        name="数据导入"
        path="/data/import"
        component={layComponent(() => import('../../DataHandler/DataImport'))}
        onEnter={util.requireAuth}
      />
      <Route
        menuKey="105006"
        name="第三方导入"
        path="/data/thirdimport"
        component={layComponent(() => import('../../DataHandler/ThirdPartImport'))}
        onEnter={util.requireAuth}
      />
      <Route
        menuKey="105007"
        name="参数配置"
        path="/apartment/ManagementCenterParameters"
        component={layComponent(() => import('../../apartment/ManagementCenterParameters'))}
        onEnter={util.requireAuth}
      />
      <Route
        menuKey="105008"
        name="同住人授权"
        path="/apartment/housemate"
        component={layComponent(() => import('../../apartment/Housemate'))}
        onEnter={util.requireAuth}
      />
    </Route>
  )
}
