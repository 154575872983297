//客户
export default {
  107: {
    name: '客户',
    path: '/memberManage',
    icon: 'customer',
  },
  107001: {
    name: '租客管理',
    path: '/memberManage/members/list',
    leaf: true,
  },
  // 107002: {
  //   name: '黑名单',
  //   path: '/memberManage/bmembers/list',
  //   leaf: true,
  // },
  107001001: {
    name: '租客管理',
    path: '',
  },
  107002001: {
    name: '黑名单',
    path: '',
  },
};
