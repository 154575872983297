import Cookies from 'js-cookie';
import { routerRedux } from 'dva/router';
import qs from 'qs';
// import store from '../index';
// eslint-disable-next-line import/no-cycle
import Dict from '../config/dict';
// import * as common from '../config'; // hide

let preUrl = ''; //保存上一次url
const checkAuthorized = () => {};

// 判断siteType的一致性
export const checkSite = function() {
  const site = localStorage[Dict.global.LOCALSTORAGE_KEY.siteType];

  return !!(site && site === Dict.global.systemConfig.siteType);
};

// todo 替换empty
export const isEmpty = function(value) {
  if (value == null) {
    // 等同于 value === undefined || value === null
    return true;
  }
  const type = Object.prototype.toString.call(value).slice(8, -1);
  switch (type) {
    case 'String':
      return !$.trim(value);
    case 'Array':
      return !value.length;
    case 'Object':
      return $.isEmptyObject(value); // 普通对象使用 for...in 判断，有 key 即为 false
    default:
      return false; // 其他对象均视作非空
  }
};

// 路由跳转
export const push = function(path, query, state) {
  if (query) {
    return routerRedux.push({
      pathname: path,
      search: `?${qs.stringify(query)}`,
      state: state || {},
    });
  }

  return routerRedux.push(path);
};

// 获取所有用户信息
export const getUserInfo = function() {
  const userInfo = localStorage.getItem(Dict.global.LOCALSTORAGE_KEY.userInfo);

  if (userInfo) {
    return JSON.parse(userInfo);
  }

  return {
    person: {},
    staffList: [],
  };
};

// 判断是否登陆，用于大部分权限控制页面
export const isLogin = function() {
  const userInfo = localStorage.getItem(Dict.global.LOCALSTORAGE_KEY.userInfo);
  const currentStaff = localStorage.getItem(Dict.global.LOCALSTORAGE_KEY.currentStaff);

  // 满足一下条件
  // 1. localStorage中获取person,staffList
  // 2. currentStaff 当前staff数据为空
  // 3. cookie 未获取到token
  // 4. siteType和系统不一致
  if (isEmpty(userInfo) || isEmpty(currentStaff) || !Cookies.get(Dict.global.tokenCookieName) || checkSite() === false) {
    return false;
  }

  return true;
};

// 设置登陆返回的所有用户信息，包含网站类型
export const setUserInfo = function(userData) {
  localStorage[Dict.global.LOCALSTORAGE_KEY.userInfo] = JSON.stringify(userData);
  localStorage[Dict.global.LOCALSTORAGE_KEY.siteType] = Dict.global.systemConfig.siteType;

  localStorage.timestamp = new Date().getTime();
};

// 设置选中运营商身份的员工信息
export const setCurrentUser = function(user) {
  localStorage[Dict.global.LOCALSTORAGE_KEY.currentStaff] = JSON.stringify(user);

  localStorage.timestamp = new Date().getTime();
};

// 设置完整的用户登陆信息
// 包含
// 1. token(cookie)
// 2. userInfo
// 3. currentStaff(可选)，staffList长度为1时，自动设置
export const setAllUserInfo = function(userData) {
  Cookies.set(Dict.global.tokenCookieName, userData.person.ticket, { expires: 1 });

  setUserInfo(userData);

  if (userData.staffList.length === 1) {
    setCurrentUser(userData.staffList[0]);
  }
};

export const checkLogin = (nextState, replace, next) => {
  if (isLogin()) {
    checkAuthorized();
    next();
  } else {
    // userInfoState 不存在 或者 已过期，则跳转到登录页
    //hashHistory.push('/login');
    push({
      pathname: '/login',
      query: { preUrl: preUrl },
    });
    next();
  }
};

export const getStaffId = () => {
  let currentStaff = null;

  const currentStaffStore = localStorage[Dict.global.LOCALSTORAGE_KEY.currentStaff];

  if (currentStaffStore) {
    currentStaff = JSON.parse(currentStaffStore);
  }

  if (currentStaff) {
    return currentStaff.id;
  }

  return '';
};

export const getStaff = jump => {
  let jFlag = true;

  if (jump === false) {
    jFlag = jump;
  }

  let currentStaff = null;

  const currentStaffStore = localStorage[Dict.global.LOCALSTORAGE_KEY.currentStaff];

  if (currentStaffStore) {
    currentStaff = JSON.parse(currentStaffStore);
  }

  let newStaff = false;

  if (currentStaff) {
    newStaff = { ...currentStaff };

    if (currentStaff.orgId <= 0 || isEmpty(currentStaff.orgId)) {
      newStaff.orgId = -1;
    }
  }

  if (jFlag) {
    if (newStaff === false || newStaff.orgId === -1) {
      // eslint-disable-next-line no-alert
      alert('获取员工信息异常!');

      // const {dispatch} = store;

      // window.location.href = dispatch(push('/login'));
    }
  }

  return newStaff;
};

export const routerLeave = context => {
  preUrl = context.location.pathname;
};

/**
 * 创建异步的action
 * @param httpHandle axios请求
 * @param httpSuccessHandle 请求成功的回调
 */
export const createAsyncAction = (httpHandle, httpSuccessHandle) => {
  httpHandle
    .then(result => {
      httpSuccessHandle(result);
    })
    .catch(err => {
      //message.error(err.message);
    });
};

//判断运营商类型
function whichOrganization(type) {
  const staffInfo = common.getStaff();

  let result = false;

  if (staffInfo && staffInfo.orgTypes) {
    const orgTypes = staffInfo.orgTypes.map(item => item.orgType);

    if (orgTypes.indexOf(type + '') >= 0) {
      result = true;
    }
  }

  return result;
}

//是资产
export const isAssetOrg = function(asset) {
  return !!whichOrganization(asset);
};

//是公寓
export const isApartmentOrg = function(apartment) {
  return !!whichOrganization(apartment);
};

//是电费
export const isElectricityOrg = function(electricity) {
  return !!whichOrganization(electricity);
};

//是物业公司
export const isPropertyOrg = function(property = Dict.OrgTypes.Property) {
  return !!whichOrganization(property);
};

/**
 * 两个字符用第三个字符进行拼接
 * @param v1 第一个字符
 * @param v2 第二个字符
 * @param character 连接符
 * @returns {string} 拼接好的字符
 */
export const joinString = function(v1 = '——', v2 = '——', character = '|') {
  let arr = [];

  arr[arr.length] = v1 || '——';
  arr[arr.length] = character;
  arr[arr.length] = v2 || '——';
  return arr.join('');
};
