import Reflux from 'reflux';
import objectAssign from 'object-assign';

import Actions from '../actions/actions';
import {siteType} from '../config/config';

const selectedKeys = sessionStorage.getItem('selectedKeys');

let signinState = {
  signinbox: {show: false},
  person: common.getPerson(),
  staffList: common.getStaffList(),
  currentStaff: common.getStaff(false),
  showNotification: false,
  inputType: 'login',
  menuData: [],
  menuSelectedKeys: selectedKeys ? JSON.parse(selectedKeys) : [],
};

const SigninStore = Reflux.createStore({
  listenables: Actions,
  getUserInfo() {
    return {
      person: signinState.person,
      staffList: signinState.staffList
    }
  },
  clearSignin() {
    signinState.person = false;
    signinState.staffList = false;
    this.trigger(signinState);
  },
  getStaff() {
    if (signinState.person == false || signinState.staffList) {
      let userInfo = false;
      if (localStorage[common.userInfo] != undefined) {
        userInfo = JSON.parse(localStorage[common.userInfo]);
        signinState.person = userInfo.person;
        signinState.staffList = userInfo.staffList;
      }
    }

    if (signinState.staffList && signinState.staffList.length > 0) {
      return signinState.staffList[0];
    }
    return false;
  },
  updateCurrentStaff(org) {
    common.user.currentStaff = org;
    localStorage[common.currentStaff] = JSON.stringify(org);
    signinState.currentStaff = org;
    this.trigger(signinState);
  },
  onToggleSigninForm(isShow) {
    signinState.signinbox.show = isShow;
    this.trigger(signinState);
  },

  onSigninCompleted(result) {
    const signInData = {
      person: false,
      staffList: false,
      currentStaff: false,

    };

    if (result.errorCode == 0 && result.data) {
      signInData.person = result.data.person;
      signInData.staffList = result.data.staffList;

      common.setCookie(common.baseInfo.cookieName, result.data.person.ticket, 1);
      localStorage[common.userInfo] = JSON.stringify(result.data);
      localStorage[common.site] = siteType;

      if (result.data.staffList.length == 1) {
        signInData.currentStaff = result.data.staffList[0];
        localStorage[common.currentStaff] = JSON.stringify(signInData.currentStaff);
        common.user.currentStaff = signInData.currentStaff;
      }
    }

    signinState.person = signInData.person;
    signinState.staffList = signInData.staffList;
    signinState.currentStaff = signInData.currentStaff;

    this.trigger(signinState);
  },

  onSigninFailed() {
    signinState.person = false;
    signinState.staffList = false;
    signinState.currentStaff = false;
    this.trigger(signinState);
  },
  onSuperSigninCompleted(result) {
    const signInData = {
      person: false,
      staffList: false,
      currentStaff: false,

    };

    if (result.errorCode == 0 && result.data) {
      signInData.person = result.data.person;
      signInData.staffList = result.data.staffList;

      common.setCookie(common.baseInfo.cookieName, result.data.person.ticket, 1);
      localStorage[common.userInfo] = JSON.stringify(result.data);
      localStorage[common.site] = siteType;

      if (result.data.staffList.length == 1) {
        signInData.currentStaff = result.data.staffList[0];
        localStorage[common.currentStaff] = JSON.stringify(signInData.currentStaff);
        common.user.currentStaff = signInData.currentStaff;
      }
    }

    signinState.person = signInData.person;
    signinState.staffList = signInData.staffList;
    signinState.currentStaff = signInData.currentStaff;

    this.trigger(signinState);
  },

  onSuperSigninFailed() {
    signinState.person = false;
    signinState.staffList = false;
    signinState.currentStaff = false;
    this.trigger(signinState);
  },
  getDefaultState() {
    if (common.getRequestParam('auth') == -1) {
      signinState.warning.show = true;
      signinState.signinbox.show = true;
    }
    return signinState;
  },
  onUpdatePersonInfoCompleted(result) {
    if (!result.errorCode) {
      signinState.person = result.data;

      this.trigger({...signinState})
    }
  },
  onUpdatePersonInfoFailed(result) {
    signinState = this.getUserInfo();

    this.trigger({...signinState})
  },
  onUpdatePersonInfoSync(updatePerson) {
    const localUserInfoStr = localStorage[common.userInfo];
    let localUser = {};

    if (localUserInfoStr) {
      localUser = JSON.parse(localUserInfoStr);
    }

    const person = objectAssign({}, localUser.person, updatePerson);

    signinState.person = person;

    localUser.person = person;

    localStorage[common.userInfo] = JSON.stringify(localUser);

    this.trigger({...signinState})
  },

  onGetRoleMenuCompleted(result) {
    let menu = [];

    if (result.errorCode == 0 && result.data) {
      menu = result.data;
    }

    signinState.menuData = menu;

    this.trigger(signinState);
  },

  onGetRoleMenuFailed() {
    signinState.menuData = [];
    this.trigger(signinState);
  },
  onUpdateSelectedMenu(keys) {
    signinState.menuSelectedKeys = keys;

    sessionStorage.setItem('selectedKeys', JSON.stringify(keys));

    this.trigger(signinState);
  }
});

export default SigninStore;
