import React from 'react';
import Modal from 'react-modal';
import createClass from 'create-react-class';
import './style.css';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    outline: 'none',
    padding: '0px',
    minWidth: '700px',
    borderRadius: '0',
    transform: 'translate(-50%,-50%)',
    background: 'none'
  },
  contentLimit: {},
  overlay: {
    zIndex: '9999'
  }
};

// todo cc
export default createClass({
  getInitialState() {
    return {
      isOpen: false,
      version: '',
      date: '',
      deployment: {}
    };
  },
  componentWillReceiveProps(nextProps) {
    this.setState({
      date: nextProps.deployment.createTime ? nextProps.deployment.createTime.replace(/-/g, '') : '',
      deployment: nextProps.deployment.infolist || {}
    });
  },
  open() {
    this.setState({isOpen: true});
  },
  onClose() {
    this.setState({isOpen: false}, () => {
      this.props.onClose && this.props.onClose();
    });
  },
  renderUpdateList() {
    const {deployment} = this.state;
    if (deployment && deployment.length) {
      const listData = [{title: '新增', contents: []}, {title: '修复', contents: []}, {title: '优化', contents: []}];
      deployment.map((item, key) => {
        if (item.type == 2) {
          listData[0].contents.push(item.remark);
        }
        if (item.type == 4 || item.type == 3) {
          listData[2].contents.push(item.remark);
        }
      });
      return listData.map((update, key) => {
        const {title, contents = []} = update;
        if (contents.length > 0) {
          return (
            <div key={key} className="deployInfo-content-section">
              <div className="deployInfo-content-section-title">
                {update.title}
              </div>
              <div className="deployInfo-content-section-list">
                <ol>
                  {
                    contents.map((content, index) => <li key={index}>{` ${content}`}</li>)
                  }
                </ol>
              </div>
            </div>
          )
        }
      });
    } else {
      return <div style={{marginTop: '100px', textAlign: 'center'}}>未发现更新内容！</div>;
    }
  },
  render() {
    return (
      <Modal contentLabel="" isOpen={this.state.isOpen} style={modalStyles} shouldCloseOnOverlayClick onRequestClose={this.onClose}>
        <div className="deployInfo-container">
          <div className="deployInfo-top-container">
            <div className="deployInfo-logo">功能更新</div>
            <div className="deployInfo-close" onClick={this.onClose} />
          </div>
          <div className="deployInfo-body-container">
            {this.renderUpdateList()}
          </div>
          <div className="deployInfo-bottom">
            <div style={{backgroundColor: '#e73117'}} />
            <div style={{backgroundColor: '#e17a37'}} />
            <div style={{backgroundColor: '#e2b025'}} />
            <div style={{backgroundColor: '#a5a83c'}} />
            <div style={{backgroundColor: '#1564a2'}} />
          </div>
        </div>
      </Modal>
    );
  }
})
