/* eslint-disable no-underscore-dangle */
/**
 * Created by neo on 2017/5/16.
 */
import React from 'react';
import importedComponent from 'react-imported-component';
import {Spin} from '@unovo/antd';

import Actions from '../../actions/actions';

import {siteType} from '../../config/config';

//自动跳转到/list名单
const listDict = [
  '/config/product/', '/config/',
  '/organization/employee/',
  '/apartment/centeralization/',
  '/apartment/resources/',
  '/property/homeOwner/',
  '/property/manage/',
  '/resource-operation/elecList/',
  '/memberManage/members/',
  '/memberManage/bmembers/',
  '/housekeeper/roomStatus/'
];

function Loading(props) {
  return (
    <div className="content-loading" style={{height: '100%', minHeight: '100vh', padding: '10% 50%'}}>
      <Spin size="large"/>
    </div>
  )
}

function checkSite() {
  const site = localStorage[common.site];

  if (site && site === siteType) {
    return true;
  } else {
    return false;
  }
}

function renderFuncDefault(names) {
  return names.join('-');
}

export const lazyModule = (loader, exportName = 'default') => (nextState, cb) => loader(module => {
  cb(null, module[exportName])
});

export const layComponent = (loader) => {
  return importedComponent(loader, {
    LoadingComponent: Loading,
  })
}

export const getNameByQueryString = qName => () => {
  if (common.__location && ('dpn' in common.__location.query)) {
    return common.__location.query[qName] || '无'
  }
  return ''
};
export const getNamesByQueryString = (qNames, renderFunc = renderFuncDefault) => () => {
  const arr = [];

  if (common.__location) {
    qNames.forEach(item => {
      if (common.__location.query[item]) {
        arr.push(common.__location.query[item]);
      }
    });

    return renderFunc(arr)
  }
  return ''
};

export const getPropertyNavName = (defaultMsg = '') => () => {
  let navName = defaultMsg;

  if (common.__location && ('name' in common.__location.query) && common.__location.query.id !== '-1') {
    navName = common.__location.query.name;
  } else {
    navName = '';
  }

  return navName;
}


// const modelNotExisted = (app, model) => {
//   return !app._models.some(({namespace}) => {
//     return namespace === model.substring(model.lastIndexOf('/') + 1);
//   });
// };

// export const dynamicWrapper = (app, models, component) => {
//   return dynamic({
//     app,
//     models: () => {
//       return models.filter(model => modelNotExisted(app, model))
//         .map(m => import('../models/' + m + '.js'))
//     },
//     component: () => {
//       return component();
//     },
//   });
// }

export default {
  requireAuth(nextState, replace) {
    const isSignin = common.checkSignin(common.baseInfo.cookieName);

    const currentStaff = common.getStaff(false);

    if (isSignin === false || currentStaff === false || checkSite() === false) {
      replace('/signin');
    } else {
      //pass

      // eslint-disable-next-line no-lonely-if
      if ($.inArray(nextState.location.pathname, listDict) >= 0) {
        replace(`${nextState.location.pathname}list`);
      }
    }
  },
  onHomeEnter(nextState, replace) {
    const isSignin = common.checkSignin(common.baseInfo.cookieName);

    const currentStaff = common.getStaff(false);

    Actions.updateSelectedMenu(['home']);

    if (isSignin === false || currentStaff === false || checkSite() === false) {
      replace('/signin');
    } else {
      //pass

      // eslint-disable-next-line no-lonely-if
      if ($.inArray(nextState.location.pathname, listDict) >= 0) {
        replace(`${nextState.location.pathname}list`);
      }
    }
  },
  onLeave(nextState, replace) {
    Actions.updateSelectedMenu([]);
  },
  getDynamicName() {
    return this.component.displayName;
  }
}
