//员工
export default {
  109: {
    name: '员工',
    path: '/organization',
    icon: 'employee',
  },
  109001: {
    name: '部门管理',
    path: '/organization/department',
    leaf: true,
  },
  109002: {
    name: '岗位管理',
    path: '/organization/post',
    leaf: true,
  },
  109003: {
    name: '雇员管理',
    path: '/organization/employee/list',
    leaf: true,
  },
  109001001: {
    name: '部门管理',
    path: ''
  },
  109002001: {
    name: '岗位管理',
    path: ''
  },
  109003001: {
    name: '雇员管理',
    path: ''
  }
}
