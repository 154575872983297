/**
 * Created by neo on 16/4/26.
 */

import config from './config';
import environment from './environment';

const AppConfig = function () {
  const { UA, baseUrl, baseDir, versionId } = environment;

  const commonPath = `/saas20/api/${config.date}/${UA}`;
  const apiBaseUrl = baseUrl + commonPath;

  return {
    baseInfo: {
      UA: UA,
      host: baseUrl,
      baseDir: baseDir,
      versionId: versionId,
      login: baseDir + '/#/signin',
      apiBaseUrl: apiBaseUrl,
      versionInfo: apiBaseUrl + '/free/app/version/check',
      sendSignupCode: apiBaseUrl + '/optimize/message/sendcode',
      signup: apiBaseUrl + '/sysLogin/customer/register',
      authMobile: apiBaseUrl + '/customer/authen/mobile',
      resetpwd: apiBaseUrl + '/sysLogin/customer/resetpwd/mobile',
      cookieName: config.cookieName,
      PAGE_SIZE: config.pageSize,
      PAGE_SIZE_25: config.pageSize_25,
      PAGE_SIZE_50: config.pageSize_50,
    },
    api: {
      checkSigninUrl: './data/checkSignin.json',
      getTicket: apiBaseUrl + '/customer/getTicket',
      signinUrl: apiBaseUrl + '/sysLogin/staff/authenticate',
      superSigninUrl: apiBaseUrl + '/free/sm/login',
      signoutUrl: apiBaseUrl + '/customer/signout',
      chooseOperation: apiBaseUrl + '/customer/setCurrentStaff',
      managerUrl: './data/room.json',
      loadAreaTree: './data/loadAreaTree.json', //apartment/bill/loadAreaTree
      getAreaList: './data/getAreaList.json',
      getHomeDataUrl: apiBaseUrl + '/analysis/summary/org',
      getHistoryDetail: apiBaseUrl + '/app/version/detail',
      basic: {
        getCountyList: apiBaseUrl + '/free/basicdata/county',
        getSystemList: apiBaseUrl + '/paramContent/search',
        getOrgCityList: apiBaseUrl + '/basic/orgcity/query', //运营商城市
        getOpenCityList: apiBaseUrl + '/basic/opencity/query', //所有开通城市
        getDictList: apiBaseUrl + '/paramContent/search',
        getBillList: apiBaseUrl + '/paramcontent/combox/bill',
        getManagerCenter: apiBaseUrl + '/product/managercenter/list/dic',
        getStaffManagerCenter: apiBaseUrl + '/product/managercenter/staff/query/app',
        getPaymentChannel: apiBaseUrl + '/financial/manualpaymentchannels/get',
        getBillAccount: apiBaseUrl + '/bill/info/customer/accounts',
        getMenuByStaffId: apiBaseUrl + '/roleInfo/getFunctionsByStaffId',
        getDownloadRecord: apiBaseUrl + '/exportTask/query/page',
      },
      history: {
        getHistory: apiBaseUrl + '/free/app/version/query',
      },
      Housekeeper: {
        getDistributedRoomStatus: apiBaseUrl + '/room/roomregister/queryroomstatus',
        getConcentratedRoomStatus: apiBaseUrl + '',
        getUserManagerList: apiBaseUrl + '/product/managercenter/staff/query?',
        searchRoomByNum: apiBaseUrl + '/room/roomregister/searchroomstatus',
        getDoorlockOperateLogList: apiBaseUrl + '/devicefacade/doorlock/list/operaLog',
        getRoomRenterInfo: apiBaseUrl + '/roomrent/member/detail/tenantHolder/roomId/',
        getRoomOtherRenterList: apiBaseUrl + '/roomrent/member/list/cotenantMembers/roomId/',
        getComplaintAndSuggestionList: apiBaseUrl + '/advice/orgstaff/query',
        getBookingManagementList: apiBaseUrl + '/roomrent/query/bookManage',
        getVacantRoomList: apiBaseUrl + '/roomrent/query/empty',
        getRecentCheckin: apiBaseUrl + '/roomrent/query/recent',
        getRecentCheckout: apiBaseUrl + '/roomrent/query/checkout',
        getDoorLockPrivilegeListUrl: apiBaseUrl + '/devicefacade/doorlock/list/privilegevo/userGroup/room/',
        getOperaLogListUrl: apiBaseUrl + '/devicefacade/doorlock/list/operaLog',
        getPrivilegeLogListUrl: apiBaseUrl + '/devicefacade/doorlock/list/privilegeLog',
        getRoomDetailInfo: apiBaseUrl + '/room/roomregister/id/%roomId/personId/-999',
        getRoomrentRoomStatusUrl: apiBaseUrl + '/roomrent/query/roomStatus',
        getRoomHistoryPage: apiBaseUrl + '/customer/tenant/history/queryPage',
        getRoomInformationDownload: apiBaseUrl + '/free/analysis/stat/rooms/download',
        getRoomOverdueList: apiBaseUrl + '/roomrent/query/overdue',
        getShortRentByRoomId: apiBaseUrl + '/roomrent/shortRent/getPage',
        getCheckIn: apiBaseUrl + '/roomrent/shortRent/checkIn',
        getCheckOut: apiBaseUrl + '/roomrent/shortRent/checkOut',
      },
      Zone: {
        getPersonalInformationUrl: apiBaseUrl + '/customer/regdetail/customerId/',
        updateHeadImage: apiBaseUrl + '/customer/detail/update',
        changePwd: apiBaseUrl + '/customer/changepwd',
      },
      Service: {
        getManagerCenterInfoUrl: apiBaseUrl + '/product/managercenter/list/dic',
        getCurrentDynamicUrl: apiBaseUrl + '/flow/staff/service/webtotal',
        getUpdateServiceUrl: apiBaseUrl + '/flow/staff/service/webquery',
        getManagementOnDetailUrl: apiBaseUrl + '/flow/detail/flowId/',
        getCompAndSuggListUrl: apiBaseUrl + '/advice/orgstaff/query',
        submitReplyInfoUrl: apiBaseUrl + '/advice/reply',
        getEvaluationUrl: './data/GetTestData.json',
        // getServiceDetailsUrl: apiBaseUrl + '/free/flow/query/page',
        getServiceDetailsUrl: apiBaseUrl + '/flow/detail/service',
        getManagementOnDetailNewUrl: apiBaseUrl + '/flow/detail/flowInstanceId/',
      },
      Apartment: {
        addAreaItem: './data/apartment_add_item.json',
        deleteAreaItem: './data/apartment_add_item.json',
        getApartmentManagerList: apiBaseUrl + '/product/managercenter/query?',
        getCenteralizationDetail: apiBaseUrl + '/product/managercenter/detail?',
        updateCenteralizationDetail: apiBaseUrl + '/product/managercenter/save?',
        deleteCenteralizationDetail: apiBaseUrl + '/product/managercenter/delete?',
        getDecentralizedDetail: './data/apartment_decentralized_detail.json',
        getCenterCityList: './data/apartment_get_city.json',
        getCenterCommunityByCity: apiBaseUrl + '/basicdata/community',
        addCenterCommunity: apiBaseUrl + '/basicdata/community/post',
        getCenterRoomResource: apiBaseUrl + '/house/houseregister/',
        getCenterFloor: './data/apartment_floor.json',
        getApartmentRoomPart: './data/apartment_center_roompart.json',
        getProductDict: apiBaseUrl + '/product/combox/query?',
        getApartmentPriceScheme: apiBaseUrl + '/aptproduct/resourceprice/querypricescheme/',
        getCountyList: apiBaseUrl + '/free/basicdata/county',
        getCityList: apiBaseUrl + '/basicdata/city/all',
        getOpenCityList: apiBaseUrl + '/basic/opencity/query',
        getBuildingList: apiBaseUrl + '/room/building/',
        getBuildingFloorList: apiBaseUrl + '/room/floor/',
        getFloorRoomList: apiBaseUrl + '/room/roomregisterfloorgroup/',
        getPriceSchemeList: apiBaseUrl + '/aptproduct/resourceprice/getresourceprice/scheme/',
        getApartmentTag: apiBaseUrl + '/free/room/roomtags/',
        getRoomInfo: apiBaseUrl + '/room/roomregister/id/',
        getRoomFeeItem: apiBaseUrl + '/room/roomregister/id/{id}/getRoomFeeConfig',
        getFloorRoomBase: apiBaseUrl + '/room/roomregister/',
        modifyApartmentCentralizationHousingRoomStatus: apiBaseUrl + '/room/release',

        getApartmentDecentralized: apiBaseUrl + '/product/managercenter/query',
        getApartmentDecentralizedManagement: apiBaseUrl + '/product/managercenter/detail',
        editApartmentDecentralizedManagement: apiBaseUrl + '/product/managercenter/save?',
        deleteApartmentDecentralizedManagement: apiBaseUrl + '/product/managercenter/delete?',
        getApartmentDecentralizedConfiguration: apiBaseUrl + '/room/roomregistercommunitygroup',
        getApartmentDecentralizedHousingFacilities: apiBaseUrl + '/product/support/query',
        getApartmentDecentralizedHousingFeatures: apiBaseUrl + '/free/room/roomtags/all',
        getApartmentDecentralizedHousingPrices: apiBaseUrl + '/aptproduct/resourceprice/getresourceprice/scheme/',
        getApartmentDecentralizedHousingAparts: apiBaseUrl + '/room/roomregister/relationrooms',
        getApartmentDecentralizedHousingCommunities: apiBaseUrl + '/basicdata/community',
        addApartmentDecentralizedHousingCommunity: apiBaseUrl + '/basicdata/community/post',
        getApartmentDecentralizedHousingSource: apiBaseUrl + '/house/houseregister',
        addApartmentDecentralizedHousingSource: apiBaseUrl + '/house/houseregister/post',
        getApartmentDecentralizedHousingTotal: apiBaseUrl + '/room/roomregister/id/',
        addApartmentDecentralizedHousingTotal: apiBaseUrl + '/room/roomregister/post',
        editApartmentDecentralizedHousingTotal: apiBaseUrl + '/room/roomregister/put',
        deleteApartmentDecentralizedHousingTotal: apiBaseUrl + '/room/roomregister/delete/id/',
        getApartmentDecentralizedHousingApart: apiBaseUrl + '/room/roomregister/id/',
        addApartmentDecentralizedHousingApart: apiBaseUrl + '/room/roomregister/post',
        editApartmentDecentralizedHousingApart: apiBaseUrl + '/room/roomregister/put',
        deleteApartmentDecentralizedHousingApart: apiBaseUrl + '/room/roomregister/delete/id/',
        getApartmentHousing: apiBaseUrl + '/house/houseregister',
        getApartmentHousingDetail: apiBaseUrl + '/house/houseregister',
        getRoomFacilities: apiBaseUrl + '/product/support/query?',
        modifyApartmentDecentralizedHousingRoomStatus: apiBaseUrl + '/room/release',
        getRoomOptionalStatus: apiBaseUrl + '/room/optionalstatus',
        roomPictureUpload: apiBaseUrl + '/room/roomregister/pictures/uploadonepic', //客房照片单图上传
        getPictureByPack: apiBaseUrl + '/room/roomregister/pictures/getpicbypack', //根据packId获取图片
        delRoomPicture: apiBaseUrl + '/room/roomregister/pictures/delete', //删除图片
        changeRoomPicture: apiBaseUrl + '/room/roomregister/pictures/changepic', //修改图片属性
        getRoomPictureClassify: apiBaseUrl + '/room/roomregister/pictures/getpictype', //获取图片分类list

        getOpenCityInfoUrl: apiBaseUrl + '/basic/opencity/query',
        getCountyInfoUrl: apiBaseUrl + '/free/basicdata/county',
        getProductInfoUrl: apiBaseUrl + '/product/combox/query',
        getPriceCaseInfoUrl: apiBaseUrl + '/aptproduct/resourceprice/querypricescheme/orgid/',
        getFacilityInfoUrl: apiBaseUrl + '/product/support/query',
        getFeatureInfoUrl: apiBaseUrl + '/free/room/roomtags/all',

        getDistributedUrl: apiBaseUrl + '/product/managercenter/query',
        deleteDistributedManagementUrl: apiBaseUrl + '/product/managercenter/delete',
        getDistributedManagementUrl: apiBaseUrl + '/product/managercenter/detail',
        editDistributedManagementUrl: apiBaseUrl + '/product/managercenter/save',

        getDistributedConfigurationUrl: apiBaseUrl + '/room/roomregistercommunitygroup',

        getDistributedHousingApartsUrl: apiBaseUrl + '/room/roomregister/relationrooms',

        getDistributedHousingRoomUrl: apiBaseUrl + '/room/roomregister/id/',
        getDistributedHousingRoomOnStatusListUrl: apiBaseUrl + '/room/optionalstatus',
        getDistributedHousingRoomOnConfigureStatusUrl: apiBaseUrl + '/room/release',
        editDistributedHousingRoomUrl: apiBaseUrl + '/room/roomregister/put',
        addDistributedHousingRoomUrl: apiBaseUrl + '/room/roomregister/post',
        deleteDistributedHousingRoomUrl: apiBaseUrl + '/room/roomregister/delete/id/',
        getDistributedHousingRoomOnPriceList: apiBaseUrl + '/aptproduct/resourceprice/getresourceprice/scheme/',
        getRoomWhiteList: apiBaseUrl + '/room/whitelist/page',
        reomveFromRoomWhiteList: apiBaseUrl + '/room/whitelist/remove',
        addToRoomWhiteList: apiBaseUrl + '/room/whitelist/add',
        getSysRentPayAck: apiBaseUrl + '/sysRentPayAck/queryAll',
        getRentPayAckList: apiBaseUrl + '/product/managercenter/rentPayAck/page',
        removeMgrRentPayAck: apiBaseUrl + '/product/managercenter/rentPayAck/remove',
        addOneMgrRentPayAck: apiBaseUrl + '/product/managercenter/rentPayAck/add',
        updOneMgrRentPayAck: apiBaseUrl + '/product/managercenter/rentPayAck/update',
        getManagementCenterParametersListUrl: apiBaseUrl + '/aptproduct/centralizedParam/query',
        setParametersAddOrUpdateUrl: apiBaseUrl + '/aptproduct/centralizedParam/save',
        deleteParameterUrl: apiBaseUrl + '/aptproduct/centralizedParam/delete',
        searchRoomUrl: apiBaseUrl + '/room/listbyroomno',
        makeProperty: apiBaseUrl + '/room/generateProperty/generatePropertyFromRoom',
        syncRoom: apiBaseUrl + '/apartment/sync/room',
        getCommunityByCenterId: apiBaseUrl + '/basicdata/community/queryCommunityByCenterId',
        customCotenantEmpowers: apiBaseUrl + '/customCotenantEmpowers/batchgrant?',
        getArrears: apiBaseUrl + '/aptproduct/centralizedSubjectParamCfg/query/page',
        saveArrears: apiBaseUrl + '/aptproduct/centralizedSubjectParamCfg/saveList',
        publicArea: {
          list: apiBaseUrl + '/room/communalSpace/query/list',
          add: apiBaseUrl + '/room/communalSpace/add',
          update: apiBaseUrl + '/room/communalSpace/update',
          delete: apiBaseUrl + '/room/communalSpace/delete/bId/{bId}',
          areaList: apiBaseUrl + '/room/communalSpace/query/allRooms/{svcCenterId}',
          detail: apiBaseUrl + '/room/communalSpace/query/bId/{bId}',
          getConfigRoom: apiBaseUrl + '/room/communalSpace/query/shareRooms/{bId}/{subSubject}',
        },
      },
      Config: {
        getProductList: apiBaseUrl + '/product/list/query',
        //getProductList: './data/config_product.json',
        getProductDetail: apiBaseUrl + '/product/detail/query',
        deleteProduct: apiBaseUrl + '/product/detail/delete?',
        productEdit: apiBaseUrl + '/product/detail/save?',
        productClone: apiBaseUrl + '/product/detail/clone?',
        service: apiBaseUrl + '/product/service/query',
        updateService: apiBaseUrl + '/product/service/save?',
        getProductParams: apiBaseUrl + '/product/paramcfg/query',
        updateProductParams: apiBaseUrl + '/product/paramcfg/save?',
        getProductAdsList: apiBaseUrl + '/product/advertisement/query?',
        updateProductAd: apiBaseUrl + '/product/advertisement/save?',
        deleteProductAd: apiBaseUrl + '/product/advertisement/delete?',
        getProductFacilities: apiBaseUrl + '/free/room/roomfacilities/all/',
        getSelectAppliances: apiBaseUrl + '/product/support/query?',
        updateRoomFacilities: apiBaseUrl + '/product/support/save?',
        getLeasePolicy: apiBaseUrl + '/aptproduct/leasestrategy',
        updateLeasePolicy: apiBaseUrl + '/aptproduct/leasestrategy/',
        getContractTemplateList: apiBaseUrl + '/aptproduct/template/list',
        updateContractTemplate: apiBaseUrl + '/aptproduct/template/update',
        addContractTemplate: apiBaseUrl + '/aptproduct/template/add',
        getContractTemplateContent: apiBaseUrl + '/pub-interface/contract/templet/getEditorUrl?',
        getAllSubjectList: apiBaseUrl + '/systempara/allsubbillsubject',
        getSubSubjectList: apiBaseUrl + '/bill/prdbillsubjects',
        getProductSubject: apiBaseUrl + '/product/subject/query?',
        saveProductSubject: apiBaseUrl + '/product/subject/save?',
        getBargainList: apiBaseUrl + '/room/roompricespecial',

        getResourcePriceList: apiBaseUrl + '/aptproduct/resourceprice/querypricescheme/orgid/',
        getResourcePriceView: apiBaseUrl + '/aptproduct/resourceprice/queryschemetotal/schemeid/',
        getResourcePriceConfig: apiBaseUrl + '/aptproduct/resourceprice/initconfig',
        updateResourcePriceScheme: apiBaseUrl + '/aptproduct/resourceprice/updatepricescheme',
        addResourcePriceScheme: apiBaseUrl + '/aptproduct/resourceprice/addpricescheme',
        updateResourcePricePrices: apiBaseUrl + '/aptproduct/resourceprice/addprice/schemeid/{id}/prices',
        getRoomResourcePriceUnit: apiBaseUrl + '/room/getPriceUnit',

        createPriceLadders: apiBaseUrl + '/priceschemeLadders/create',
        updatePriceLadders: apiBaseUrl + '/priceschemeLadders/update',
        deletePriceLadders: apiBaseUrl + '/priceschemeLadders/delete',
        quotePriceLadders: apiBaseUrl + '/priceschemeLadders/quote',
        getPriceLadders: apiBaseUrl + '/priceschemeLadders/get',
        queryPriceLadders: apiBaseUrl + '/priceschemeLadders/query',
        queryPagePriceLadders: apiBaseUrl + '/priceschemeLadders/queryPage',
      },
      Financial: {
        getOrgCityInfoUrl: apiBaseUrl + '/basic/orgcity/query',
        getManageCenterInfoUrl: apiBaseUrl + '/product/managercenter/list/dic',
        getHouseKeeperInfoUrl: apiBaseUrl + '/staff/managercenter/query',
        getBookSubjectInfoUrl: apiBaseUrl + '/bill/listbillsubjects',
        getBillSubjectInfoUrl: apiBaseUrl + '/systempara/allbillsubject',
        getFinancialOfficerInfoUrl: apiBaseUrl + '/staff/managercenter/query',

        //账本流水模块
        getBookJournalUrl: apiBaseUrl + '/book/newBookflows', //账本流水
        getBookJournalStatistics: apiBaseUrl + '/book/listBookStatistics', // 账本流水列表统计
        getBookJournalDetail: apiBaseUrl + '/book/bookDetail', //账本流水详情
        checkFinance: apiBaseUrl + '/book/reconciliation', //账务对账
        getPayChannel: apiBaseUrl + '/book/lineType', //付款渠道
        getBookJournalOnDownloadUrl: apiBaseUrl + '/free/books/bookflows/download',
        getBookJournalOnNormalSettlementUrl: apiBaseUrl + '/financial/unovoSettlement2OrgInfosByBook',
        getBookJournalOnHandSettlementUrl: apiBaseUrl + '/financial/manualpaymentinfosByPamentId',
        getOperationContractList: apiBaseUrl + '/book/contract', //运营合同账本列表
        reconciliationBook: apiBaseUrl + '/book/reconciliation',
        getBillManagementUrl: apiBaseUrl + '/financial/platform/listbills/org',
        getBillManagementOnDownloadUrl: apiBaseUrl + '/free/financial/platform/bills/org/download',
        getBillManagementOnDetailRecordUrl: apiBaseUrl + '/financial/listbilldetails/billid/',
        getBillManagementOnPaymentRecordUrl: apiBaseUrl + '/financial/paymentinfo/billid/',
        getBillManagementOnEditStatusUrl: apiBaseUrl + '/financial/changebillstype/billids/',

        getPlansReceivableUrl: apiBaseUrl + '/contract/rentPayPlan/list',
        getPlansReceivableOnPlanOptionUrl: apiBaseUrl + '/contract/rentPayPlan/list/planItem/planId/',

        getManualCheckinUrl: apiBaseUrl + '/financial/manualpaymentinfos',
        getManualCheckinOnPaymentBillUrl: apiBaseUrl + '/financial/getbills/paymentId/',

        getPlatformCheckoutUrl: apiBaseUrl + '/financial/unovoSettlement2OrgInfos',
        getPlatformCheckoutOnBookRecordUrl: apiBaseUrl + '/financial/getbook/applicationId/',
        getPlatformCheckoutOnLoanRecordUrl: apiBaseUrl + '/financial/unovoTransferOut2OrgInfos/',
        getPlatformCheckoutOnDownloadRecordUrl: apiBaseUrl + '/free/financial/getbook/download/',

        getHousekeeperCheckoutInitUrl: apiBaseUrl + '/financial/manualpaymentbills',
        getHousekeeperCheckoutInfoUrl: apiBaseUrl + '/financial/manualpaymentbill/details/settlementId/',
        getHousekeeperCheckoutInfoOnSettleBillUrl: apiBaseUrl + '/financial/manualpayment/settlement/billoff',
        getHousekeeperCheckoutInfoOnProcessErrorsUrl: apiBaseUrl + '/financial/manualpaymentbill/processerrors',
        getHousekeeperCheckoutInfoOnErrorsDetailUrl: apiBaseUrl + '/financial/manualpaymentbill/errorRevised/',
        getHousekeeperCheckoutInfoOnErrorsHistoryUrl: apiBaseUrl + '/financial/manualpaymentbill/queryerrors',

        getRenterRefundUrl: apiBaseUrl + '/flow/refund/request/query',
        getRenterRefundOnCheckRightUrl: apiBaseUrl + '/flow/claim',
        getRenterRefundOnStartApprovalUrl: apiBaseUrl + '/flow/refund/check',
        getRenterRefundOnCloseApprovalUrl: apiBaseUrl + '/flow/close',

        getCompanyStamp: apiBaseUrl + '/org/companySeal/query/orgId',
        updateCompanyStamp: apiBaseUrl + '/org/companySeal/upload',
        enableCompanyStamp: apiBaseUrl + '/org/companySeal/enable',

        //getPredepositJournalUrl: apiBaseUrl + '/financial/upms/preDeposit/records',  // 预付费流水
        getRoomBalanceUrl: apiBaseUrl + '/advice/orgstaff/query',
        getRoomPreDepositDataUrl: apiBaseUrl + '/customer/room/balance/query?',
        setPreDepositDataUrl: apiBaseUrl + '/financial/recharge/predeposit/transfer2Balance',
        chargeMoney: apiBaseUrl + '/pay/recharge/predeposit/offline',
        getRechargeAudit: apiBaseUrl + '/financial/prerecharge/queryprerechargebycashpage',
        auditRecharge: apiBaseUrl + '/financial/prerecharge/settlement',

        getSettlementApprovalUrl: apiBaseUrl + '/flow/approval/query',
        getSettlementApprovalOnDetailUrl: apiBaseUrl + '/flow/approval/detail',
        getSettlementApprovalOnApprovalUrl: apiBaseUrl + '/flow/approval/check',
        getPrepaidFlowDownload: apiBaseUrl + '/free/book/prepaidflow/download',
        getBillDownload: apiBaseUrl + '/free/financial/bills/download',
        getPlatformDownload: apiBaseUrl + '/financial/unovoSettlement2OrgInfos/upms/export',
        getPrepaidFlowDownloadList: apiBaseUrl + '/book/prepaidWater/export',
        getBookJournalList: apiBaseUrl + '/book/bookWater/newUpmsExport',
        checkoutAnalysis: apiBaseUrl + '/financial/unovoSettlement2OrgInfos/analysis',

        getWithDrawAccountUrl: apiBaseUrl + '/customer/accountnum/queryAll',
        getAccountInfoUrl: apiBaseUrl + '/financial/getAccountBalance',
        getSubjectsCycleUrl: apiBaseUrl + '/settlementConfig/getAllOrgConfig',
        getAccountWithdrawUrl: apiBaseUrl + '/financial/cashForOrg/add',
        getWithdrawListUrl: apiBaseUrl + '/financial/cashWithdrawalApplications/platform/org/page',
        getOrgBudgetRecord: apiBaseUrl + '/financial/query/orgBudgetRecord',
        orgbudgeAnalysis: apiBaseUrl + '/financial/orgbudge/analysis',
        budgeExport: apiBaseUrl + '/financial/orgBudgetRecord/upms/export',
        getOrgBudgeDetail: apiBaseUrl + '/financial/orgbudge/detail',
        getExampleListUrl: apiBaseUrl + '/free/app/version/query',
        getPlatformAccountLoanUrl: apiBaseUrl + '/financial/lendings/query',
        getLoanRecordUrl: apiBaseUrl + '/financial/platform/tranferOuts/getList',
        getPaymentManagement: apiBaseUrl + '/financial/bills/export',
        getCashWithdrawAnalysis: apiBaseUrl + '/financial/cashWithdrawalApplications/platform/org/page/analysis',

      },
      Finance: {
        getOperatorInfoUrl: apiBaseUrl + '/organization/query/page',
        getOpenCityInfoUrl: apiBaseUrl + '/basic/opencity/query',
        getSubjectInfoUrl: apiBaseUrl + '/payingconfig/items',
        getCustomerInfoByPhoneUrl: apiBaseUrl + '/customer/register/query/mobile',
        getOrgCityInfoUrl: apiBaseUrl + '/basic/orgcity/query',
        getManageCenterInfoUrl: apiBaseUrl + '/product/managercenter/list/dic',
        getHouseKeeperInfoUrl: apiBaseUrl + '/staff/managercenter/query',
        getBookSubjectInfoUrl: apiBaseUrl + '/bill/listbillsubjects',
        getBillSubjectInfoUrl: apiBaseUrl + '/systempara/allbillsubject',
        getFinancialOfficerInfoUrl: apiBaseUrl + '/staff/managercenter/query',
        getStampUrl: apiBaseUrl + '/org/companySeal/query/orgId',
        setEnableUrl: apiBaseUrl + '/org/companySeal/enable',
        saveStampUrl: apiBaseUrl + '/org/companySeal/upload',
        getCurrentLeaselistUrl: apiBaseUrl + '/property/accountsrecord/query',
        accountsRecordSaveUrl: apiBaseUrl + '/property/accountsrecord/save',
        getSubjectDataUrl: apiBaseUrl + '/property/accountsrecord/querylist',
        getPropertyDetailUrl: apiBaseUrl + '/house/financial/property/',
        propertyRentalsListUrl: apiBaseUrl + '/financial/platform/getbooks/org',

        Todo: {
          getTodoListURL: apiBaseUrl + '/flow/query/staff/taskUpms',
          getTodoDetailURL: apiBaseUrl + '/flow/query/task/detail',
          getTodoHistoryListURL: apiBaseUrl + '/flow/query/task/his',
          approveBillURL: apiBaseUrl + '/flow/task/approval',
          approveBillsURL: apiBaseUrl + '/flow/task/approval/batch',
        },
        Contract: {
          getContractPropertyListURL: apiBaseUrl + '/house/contract/queryPage',
          getContractPropertyDetailURL: apiBaseUrl + '/house/propertycontract/',
          getContractPropertyOperationURL: apiBaseUrl + '/house/contract/{contract}/oper',
        },
        Bill: {
          //getBillPropertyListURL: apiBaseUrl + '/financial/listbills',老接口暂废弃
          getBillPropertyListURL: apiBaseUrl + '/bill/billList',
          getMonthBillListURL: apiBaseUrl + '/bill/billreport',
          getBillPropertyDetailURL: apiBaseUrl + '/bill/info/query',
          getBillOperationURL: apiBaseUrl + '/bill/info/operate',
          getReceiptBillsUrl: apiBaseUrl + '/financial/bills/manualentrybills',
          getReceiptTypeUrl: apiBaseUrl + '/financial/manualpaymentchannels/get',
          querycustomers: apiBaseUrl + '/customer/querycustomers',
          listbillStatistics: apiBaseUrl + '/bill/listbillStatistics',
          newUpmsExport: apiBaseUrl + '/bill/newUpmsExport',
          monthUpmsExport: apiBaseUrl + '/bill/billreport/export',
          getInvoiceViewUrl: apiBaseUrl + '/bill/billing/view',
          markBill: apiBaseUrl + '/bill/billing/manually-mark',
          invoiceRecordExport: apiBaseUrl + '/bill/exportInvoiceRecord',
          supplementaryInvoice: apiBaseUrl + '/bill/invoice',
        },
      },
      PaymentSetting: {
        getManagementPayConfig: apiBaseUrl + '/center/payingconfig/',
        getOrgPaymentSubjectList: apiBaseUrl + '/org/{orgId}/payingconfig',
        getPropertyPayConfig: apiBaseUrl + '/property/payingconfig/',
      },
      Organization: {
        getDepartmentList: apiBaseUrl + '/department/query?',
        saveDepartmentInfo: apiBaseUrl + '/department/save?',
        deleteDepartmentInfo: apiBaseUrl + '/department/delete?',
        getStaffList: apiBaseUrl + '/staff/query/page?',
        getRoleList: apiBaseUrl + '/role/combox/load?',
        getRolesWorkClassList: apiBaseUrl + '/workschedule/query?',
        updateRolesWorkClass: apiBaseUrl + '/workschedules/save?',
        deleteWorkClass: apiBaseUrl + '/workschedule/delete?',
        getWorkPlanList: apiBaseUrl + '/workplan/query?',
        getRolesDutyList: apiBaseUrl + '/duty/query/combox?',
        getRoleManagerCenter: apiBaseUrl + '/staff/managercenter/tree?',
        getManagerCenterRoom: apiBaseUrl + '/staff/center/roleduty/tree?',
        getAttendance: apiBaseUrl + '/attendance/getAttendList?',
        getStaff: apiBaseUrl + '/staff/query/page?',
        getCustomerInfo: apiBaseUrl + '/customer/register/query?',
        updateStaff: apiBaseUrl + '/staff/save?',
        getStaffDetail: apiBaseUrl + '/staff/detail/staffId/',
        deleteStaff: apiBaseUrl + '/staff/delete?',
        getDutyList: apiBaseUrl + '/staff/query/onwork?',
        getStaffTask: apiBaseUrl + '/flow/query/staff?',
        upLoadWrokSechdule: apiBaseUrl + '/workplan/schedule/checkin',
        downLoadWrokSechdule: apiBaseUrl + '/workplan/checkout',
        getCustomerInfoByMobile: apiBaseUrl + '/customer/register/query/mobile', //根据手机号查询注册号信息,
        getBankAccount: apiBaseUrl + '/org/offlineaccount/',
        getDepartment: apiBaseUrl + '/orgDepartment/getDeptTree?',
        createDepartment: apiBaseUrl + '/orgDepartment/save',
        getDetailDepartment: apiBaseUrl + '/orgDepartment/getById',
        deleteDepartment: apiBaseUrl + '/orgDepartment/delete',
        getCenterIdList: apiBaseUrl + '/orgDepartment/getDeptCenter',
        getCenterIdListAll: apiBaseUrl + '/product/managercenter/list/dic',
        saveCenterIdList: apiBaseUrl + '/orgDepartment/grantDeptCenter',
        getDutyInfo: apiBaseUrl + '/dutyInfo/searchList',
        getRoleInfo: apiBaseUrl + '/roleInfo/getById',
        saveRoleInfo: apiBaseUrl + '/roleInfo/save',
        getRoleListInfo: apiBaseUrl + '/roleInfo/queryPage',
        delRoleInfo: apiBaseUrl + '/roleInfo/delete',
        getDeptStaff: apiBaseUrl + '/orgDepartment/getDeptStaff',
        deleteDeptStaff: apiBaseUrl + '/orgDepartment/removeStaffFromDept',
        updateStaffNew: apiBaseUrl + '/orgStaff/save',
        getStaffListNew: apiBaseUrl + '/orgStaff/getStaffPage',
        deleteStaffNew: apiBaseUrl + '/orgStaff/delete',
        getStaffDetailNew: apiBaseUrl + '/orgStaff/getById',
      },
      PriceScheme: {
        getConfigPriceScheme: apiBaseUrl + '/aptproduct/resourceprice/querypricescheme/orgid/',
        getConfigPriceSchemeInit: apiBaseUrl + '/aptproduct/resourceprice/initconfig',
        getConfigPriceSchemeShow: apiBaseUrl + '/aptproduct/resourceprice/queryschemetotal/schemeid/',
        deleteConfigPriceScheme: apiBaseUrl + '/aptproduct/resourceprice/deletepricescheme/schemeid/',
        editConfigPriceScheme: apiBaseUrl, //using
        getConfigPriceSchemeV2: apiBaseUrl + '/aptproduct/resourceprice/query/scheme/{scheme}/subject/{subject}',
      },
      MemberManage: {
        getMemberList: apiBaseUrl + '/crm/orgVip/list/vipInfo',
        getBlackMemberList: apiBaseUrl + '/crm/orgVip/list/blackVips',
        getMemberBasicInfo: apiBaseUrl + '/customer/perdetail/orgId/',
        getBlackListHistoryList: apiBaseUrl + '/crm/orgVip/list/blackHistory',
        setBlackListHistoryList: apiBaseUrl + '/crm/orgVip/blackStatus/set',
        getReasonData: apiBaseUrl + '/paramContent/search',
        getCompAndSuggList: apiBaseUrl + '/advice/query',
        getRentRecordList: apiBaseUrl + '/crm/orgVip/list/rentHistories',

        getContractUrl: apiBaseUrl + '/rent/contract/query/items',
        getContractOnDetailRecordUrl: apiBaseUrl + '/rent/contract/query/',
        getContractOnAddContractUrl: apiBaseUrl + '/roomrent/checkinOffline',
        getContractOnAddAttachmentUrl: apiBaseUrl + '/rent/contract/attachment/upload',
        getContractOnDownloadContractUrl: apiBaseUrl + '/rent/contract/download',
        getContractOnUploadContractUrl: apiBaseUrl + '/roomrent/import/checkinfo',
        getContractOnSoftCopyContractUrl: apiBaseUrl + '/contract/view/',
        getContractOnCheckoutUrl: apiBaseUrl + '/roomrent/cancelCheckin',
        getContractOnPerCancelCheckin: apiBaseUrl + '/roomrent/perCancelCheckin',
        getContractOnPayModeUrl: apiBaseUrl + '/contract/modify',
        contractProgressSearchUrl: apiBaseUrl + '/batchTask/query',
        contractProgressDetail: apiBaseUrl + '/batchTaskDetail/query',
        payClick: apiBaseUrl + '/order/payFromByRoomId',
        innjiaPayment: apiBaseUrl + '/injia/addMoreInfo',
        getRoomResources: apiBaseUrl + '/room/listRoomSubjectFee',
        getOfflineDevice: apiBaseUrl + '/devicefacade/device/onoffline/list2?',
        getCustomersInfo: apiBaseUrl + '/customer/querycustomers',
        getUserDuty: apiBaseUrl + '/roleInfo/getStaffRoleDutys',
        getInvoiceParameters: apiBaseUrl + '/rent/contract/invoice-parameters',
        updateInvoiceParameters: apiBaseUrl + '/rent/contract/invoice-parameters',
      },
      Lock: {
        getLockControlLog: apiBaseUrl + '/devicefacade/doorlock/list/operaLog',
        getLockList: apiBaseUrl + '/devicefacade/doorlock/list/doorlocks',
        getLockAuthPageList: apiBaseUrl + '/devicefacade/doorlock/list/privilegePageVos',
        addLockAuth: apiBaseUrl + '/doorlock/batchAdd',
        installLock: apiBaseUrl + '/devicefacade/doorlock/add/grantByLockadd',
        isAuthorized: apiBaseUrl + '/judge/doorlock/auth', //判断是否可以进行临时授权
        isAuthCanDelete: apiBaseUrl + '/doorlock/tempPrivilege/del',
      },
      Lora: {
        getCentralizedLock: apiBaseUrl + '/lorawater/centergroup',
        getDistributedLock: apiBaseUrl + '/lorawater/dispersedgroup',
        getWaterList: apiBaseUrl + '/list/water/area',
        deleteMeter: apiBaseUrl + '/del/lorawater',
        addMeter: apiBaseUrl + '/add/lorawater',
        getManagerList: apiBaseUrl + '/get/lorawater/manager',
        getListCenterCode: apiBaseUrl + '/list/centercode',
        realTimeMeterReading: apiBaseUrl + '/get/watermeter/realtime',
        refreshWatercenter: apiBaseUrl + '/refresh/watercenter',
      },
      Message: {
        getMessageList: apiBaseUrl + '/messsage/app/query/page',
        getMessageDetail: apiBaseUrl + '/message/detail/messageId/',
        getCityMgrCenterList: apiBaseUrl + '/product/managercenter/staff/query/citygroup',
        sendMsgToMgrCenterRenters: apiBaseUrl + '/messsage/send/area',
        getMessageConfig: apiBaseUrl + '/message/messageOrgPermission/isEnable', // 获取短信收费配置
        getMessageCount: apiBaseUrl + '/message/messageOrgPermission/caculateMessage', //计算短信个数
      },
      Customer: {
        getCustomerInfoByMobile: apiBaseUrl + '/customer/register/query/mobile',
      },
      Report: {
        getGuestRoomSummary: apiBaseUrl + '/analysis/count/room',
        getGuestRoomRental: apiBaseUrl + '/analysis/count/historyRentRate',
        getGuestRoomSum: apiBaseUrl + '/analysis/count/historyRoom',
        getGuestRoomIncrease: apiBaseUrl + '/analysis/count/historyGrowthRoom',
        getEnergyRankings: apiBaseUrl + '/analysis/count/historyGrowthRoom',
        getStatisticsListUrl: apiBaseUrl + '/analysis/energy/center/',
        getExcelUrl: apiBaseUrl + '/analysis/energy/roomSummary/download',
        getChartDataListUrl: './data/report_energy_chartData.json',
        getSummaryDataUrl: './data/report_energy_summary.json',
        getCustomerDataUrl: apiBaseUrl + '/analysis/stat/room/customer',
        getRoomInfoUrl: apiBaseUrl + '/analysis/stat/room/info',
        getRepairRecordUrl: apiBaseUrl + '/analysis/stat/room/repairrecord',
        getRoomFacilitiesUrl: apiBaseUrl + '/analysis/stat/room/facilities',
        getEmptyRoomUrl: apiBaseUrl + '/analysis/stat/room/empty',
        Energy: {
          //overview
          getCurrentPower: apiBaseUrl + '/analysis/energy/center/{centerId}/curPower',
          getTodayUsed: apiBaseUrl + '/analysis/energy/center/{centerId}/energy/today',
          getThisMonthUsed: apiBaseUrl + '/analysis/energy/center/{centerId}/energy/thisMonth',
          getLastMonthUsed: apiBaseUrl + '/analysis/energy/center/{centerId}/energy/lastMonth',
          getTodayConsumption: apiBaseUrl + '/analysis/energy/center/{centerId}/energyPerHour/today',
          getYesterdayConsumption: apiBaseUrl + '/analysis/energy/center/{centerId}/energyPerHour/yesterday',
          //groups
          Groups: {
            day: apiBaseUrl + '/analysis/energy/center/%center/itemEnergyPerHour/all/day/%date',
            month: apiBaseUrl + '/analysis/energy/center/%center/itemEnergyPerDay/all/month/%date',
            year: apiBaseUrl + '/analysis/energy/center/%center/itemEnergyPerMonth/all/year/%date',
          },
          //ranking
          Ranking: {
            day: apiBaseUrl + '/analysis/energy/center/%center/roomEnergy/all/day/%date',
            month: apiBaseUrl + '/analysis/energy/center/%center/roomEnergy/all/month/%date',
            year: apiBaseUrl + '/analysis/energy/center/%center/roomEnergy/all/year/%date',
          },
          //trending
          Trending: {
            day: apiBaseUrl + '/analysis/energy/center/%center/energyPerHour/day/%date',
            month: apiBaseUrl + '/analysis/energy/center/%center/energyPerDay/month/%date',
            year: apiBaseUrl + '/analysis/energy/center/%center/energyPerMonth/year/%date',
          },
          TimingDiagram: apiBaseUrl + '/analysis/que/svcValSeq/td/{deviceId}/start/{start}/end/{end}',
          getPropertyList: apiBaseUrl + '/analysis/que/property/center/{centerId}/type/{centerType}', //todo Deprecated
          getHousePropertyList: apiBaseUrl + '/house/center/{centerId}/properties',
          getConcentrationRoomList: apiBaseUrl + '/analysis/que/property/building/{buildingId}', //todo Deprecated
          getHouseConcentrationRoomList: apiBaseUrl + '/house/property/{property}/rooms',
          getPropertyDataListNew: apiBaseUrl + '/analysis/energy/property/qd/{qd}/type/{areaType}/start/{startTime}/end/{endTime}/{propertyId}/1',
          getPropertyDataList: apiBaseUrl + '/analysis/energy/property/{propertyId}/type/{areaType}/start/{startTime}/end/{endTime}',
        },
        getColdWaterList: apiBaseUrl + '/analysis/energy/property/qd/{qd}/type/{areaType}/start/{startTime}/end/{endTime}/{propertyId}/3',
        getHotWaterList: apiBaseUrl + '/analysis/energy/property/qd/{qd}/type/{areaType}/start/{startTime}/end/{endTime}/{propertyId}/2',
        getMemberAndTenantUrl: apiBaseUrl + '/anls/member/today/org/',
        getMemberHistoryUrl: apiBaseUrl + '/anls/member/vip/history',
        Finance: {
          getIOCompareUrl: apiBaseUrl + '/anls/fi/history/all',
          getIncomeUrl: apiBaseUrl + '/anls/fi/history/income',
          getExpenditureUrl: apiBaseUrl + '/anls/fi/history/expenditure',
          getUnpaidAmtUrl: apiBaseUrl + '/anls/fi/history/notpay/money',
          getUnpaidCntUrl: apiBaseUrl + '/anls/fi/history/notpay/count',
          getExpiredAmtUrl: apiBaseUrl + '/anls/fi/history/overdue/money',
          getExpiredCntUrl: apiBaseUrl + '/anls/fi/history/overdue/count',
          getCurrentTargetsUrl: apiBaseUrl + '/anls/fi/target/org/%org',
        },
      },
      // 报表/电表监控模块
      ReportMonitoring: {
        getAreaData: apiBaseUrl + '/room/listRoomBuilding',
        getTotalData: apiBaseUrl + '/report/powerMonitoring/getRoomPowerUseStatSum',
        getElectricData: apiBaseUrl + '/report/powerMonitoring/queryRoomPowerUseStatPage',
        exportElectricData: apiBaseUrl + '/report/powerMonitoring/roomPowerUseStat/free/exportExcel',
        exportElectricMonitoring: apiBaseUrl + '/report/electricMonitoring/free/exportExcel',
        exportElectricHistoryMonitoring: apiBaseUrl + '/report/electricMonitoring/exportExcel/task/export',
        getTableData: apiBaseUrl + '/report/electricMonitoring/queryElectricMeterPage',
        getEchartData: apiBaseUrl + '/equipment/business/timing/info',
      },
      // 租期开门设置接口
      RentDoor: {
        getDoorData: apiBaseUrl + '/house/proprietor/getPageList',
        getDoorListData: apiBaseUrl + '/house/proprietor/open/setList',
        handleRentDoor: apiBaseUrl + '/door/lock/lease/control/batch/save',
      },
      Maintain: {
        getDeviceStatus: apiBaseUrl + '/devicefacade/device/query/status',
        getCentralizedLock: apiBaseUrl + '/doorlock/centergroup',
        getDistributedLock: apiBaseUrl + '/doorlock/dispersedgroup',
        addLock: apiBaseUrl + '/doorlock/post',
        deleteLock: apiBaseUrl + '/doorlock/delete/id/',
        updateLock: apiBaseUrl + '/doorlock/put',
        getNodeLockList: apiBaseUrl + '/doorlock',
        getDeviceConfigList: {
          center: apiBaseUrl + '/saasbossrelation/center',
          distributed: apiBaseUrl + '/saasbossrelation/dispersed',
        },
        submitDeviceConfigList: apiBaseUrl + '/saasbossrelation/relation',
        getDevices: apiBaseUrl + '/devicefacade/device/list/baid/{baid}',
        getSystemParameterListUrl: apiBaseUrl + '/org/param/query',
        deleteSystemParameterUrl: apiBaseUrl + '/org/param/delete',
        saveSystemParameterUrl: apiBaseUrl + '/org/param/save',
        // 门锁申请
        lockApplyFor: {
          applyForList: apiBaseUrl + '/doorlockauth/doorlocksend/apply/get/page',
          checkApplyForList: apiBaseUrl + '/doorlockauth/doorlocksend/property/list/get',
          linkApplyForList: apiBaseUrl + '/cloudOwner/doorlocksend/bill/list',
          closeApplyForList: apiBaseUrl + '/cloudOwner/doorlocksend/apply/close',
          editApplyForList: apiBaseUrl + '/doorlockauth/doorlocksend/modify',
          getCityList: apiBaseUrl + '/doorlockauth/doorlocksend/getcity',
        },
      },
      HouseResource: {
        getHouseList: apiBaseUrl + '/house/property/pageview',
        backAuditActionUrl: apiBaseUrl + '/house/property/%propertyId/back',
        getHomeOwnerListUrl: apiBaseUrl + '/house/proprietor/pageview',
        getOwnerStatusListUrl: apiBaseUrl + '/paramContent/search',
        getOwnerUrl: apiBaseUrl + '/house/proprietor/',
        editOwnerInfoUrl: apiBaseUrl + '/house/',
        getHouseConfigList: apiBaseUrl + '/house/facilities/query/%id',
        distributedSubmit: apiBaseUrl + '/house/houseRegister/saveOrUpdate',
        getHouseResourceDetail: apiBaseUrl + '/house/houseRegister/query/%id',
        getHouseResourceDetailView: apiBaseUrl + '/house/property/%id/detailsview',
        getHouseResourceDetailEditInit: apiBaseUrl + '/house/property/%id',
        doSave: apiBaseUrl + '/house/property',
        getConfigurationCostUrl: apiBaseUrl + '/house/houseFee/query/',
        setConfigCostUpdateUrl: apiBaseUrl + '/house/houseFee/add/batch',
        getPaymentList: apiBaseUrl + '/house/payPlan/query/record/house/%id',
        getRoomFacilitiesUrl: apiBaseUrl + '/free/room/roomfacilities/all/',
        getBankListUrl: apiBaseUrl + '/basicdata/bank/query',

        getContractUrl: apiBaseUrl + '/property/contract/query',
        getContractHistoryUrl: apiBaseUrl + '/property/{propertyId}/contract/history',
        editContractUrl: apiBaseUrl + '/property/contract/save',
        getContractPartyAUrl: apiBaseUrl + '/house/proprietor/{proprietorId}/updateview',
        editContractPartyAUrl: apiBaseUrl + '/house/{propertyId}/proprietor',
        getPropertyDelivery: apiBaseUrl + '/property/delivery/query',
        getAllMyFacility: apiBaseUrl + '/property/%id/facility',
        getLifepayCompanyByType: apiBaseUrl + '/house/lifepayCompanies',
        addLifepayCompany: apiBaseUrl + '/house/lifepayCompany',
        propertyDeliverySave: apiBaseUrl + '/property/delivery/save',
        propertyDetailFileUpload: apiBaseUrl + '/house/property/batch',
        propertyProprietorFileUpload: apiBaseUrl + '/house/proprietor/batch',
        propertyContractFileUpload: apiBaseUrl + '/house/contract/batch',
        propertyDeliveryFileUpload: apiBaseUrl + '/house/delivery/batch',
        getPropertyMountedHouses: apiBaseUrl + '/house/propertyroomreflistview',
        getUnmountedPropertyHouses: apiBaseUrl + '/house/property/%propertyId/roomview',
        mountedPropertyHouses: apiBaseUrl + '/house/propertyroomref',
        unmountedPropertyHouses: apiBaseUrl + '/house/propertyroomref/del',
        queryRecord: apiBaseUrl + '/property/consumerecord/history', //获取历史记录
        getPrepaidRechargeListUrl: apiBaseUrl + '/property/consumerecord/electricity/prepay/page',
        getCommunityListUrl: apiBaseUrl + '/basicdata/community/queryCommunityByCenterId',
        getLifepayCompaniesUrl: apiBaseUrl + '/house/lifepayCompanies',
        savePrepaidRechargeRecordUrl: apiBaseUrl + '/property/consumerecord/prepay/batchsave',
        queryRechargeHistoryUrl: apiBaseUrl + '/property/consumerecord/history',
        uploadElectricityChargeRecordUrl: apiBaseUrl + '/property/import/{type}/withoutbilling',
        houseResourceOperation: apiBaseUrl + '/house/contract/{contractId}/oper',
      },
      OperationDaily: {
        getSummaryList: apiBaseUrl + '/analysis/summary/business/subject',
        getSummary: apiBaseUrl + '/analysis/summary/business',
        getVacantRoomList: apiBaseUrl + '/roomrent/query/empty',
      },
      DataHandler: {
        importData: apiBaseUrl + '/roomrent/import/common',
        batchTaskUrl: apiBaseUrl + '/batchTask/query',
        batchTaskDetailUrl: apiBaseUrl + '/batchTaskDetail/query',
        getOperatorsUrl: apiBaseUrl + '/organization/query/page',
        getManagerCenterListUrl: apiBaseUrl + '/product/managercenter/query',
        uploadContractFileUrl: apiBaseUrl + '/roomrent/import/checkinfo',
        uploadGuestRoomFileUrl: apiBaseUrl + '/room/import',
        uploadFeeFileUrl: apiBaseUrl + '/room/resource/import',
        contractProgressSearchUrl: apiBaseUrl + '/batchTask/query',
      },
      ShareResources: {
        getResourcesInfo: apiBaseUrl + '/shareRes/config/query/key',
        getResourcesInfoDetail: apiBaseUrl + '/shareRes/config/query/id/',
        getDevicesType: apiBaseUrl + '/shareResType/que/all',
        getTargetDeviceList: apiBaseUrl + '/devicefacade/device/list/baid/{baid}',
        updateShareResource: apiBaseUrl + '/shareRes/config/update',
        addShareResource: apiBaseUrl + '/shareRes/config/add',
        deleteShareResource: apiBaseUrl + '/shareRes/config/delete/{id}',
      },
      AssetOperation: {
        getElectricContractList: apiBaseUrl + '/assetbusiness/contractOperating/query/assetorg',
        getPropertyList: apiBaseUrl + '/house/pagePropertyVo',
        addRelationProperty: apiBaseUrl + '/assetbusiness/contractProperty/save',
        relieveRelationProperty: apiBaseUrl + '/assetbusiness/contractProperty/disAssociate',
        deliverProperty: apiBaseUrl + '/assetbusiness/contractproperty/delivery/save',
        getOrganization: apiBaseUrl + '/organization/query/list',
        getContractOrg: apiBaseUrl + '/property/consumerecord/statistics/org',
        saveContract: apiBaseUrl + '/assetbusiness/contractOperating/save',
        getRechargeRecordListUrl: apiBaseUrl + '/property/consumerecord/statistics/page',
        getCityListUrl: apiBaseUrl + '/property/consumerecord/statistics/city',
        getOperatorListUrl: apiBaseUrl + '/property/consumerecord/statistics/org',
        getMeterReaderListUrl: apiBaseUrl + '/property/consumerecord/statistics/assetmeterreader',
        getAmountMonitoringListUrl: apiBaseUrl + '/property/consumerecord/amount/statistics/page',
        // getAmountMonitoringListUrl: './data/meterReadingStatistics.json',
        getProfitAccountings: apiBaseUrl + '/profit/accountings',
        getProfitDistributions: apiBaseUrl + '/profit/distributions',
        getConnOrUnconnList: apiBaseUrl + '/estate/contract/query/page',
      },
      Others: {
        test: apiBaseUrl + '/estate/estateCategory/list',
      },
      AssetManage: {
        getAssetCategory: apiBaseUrl + '/estate/estateCategory/getList',
        getAllAssetCategory: apiBaseUrl + '/estate/estateCategory/getAllList',
        getAssetBrand: apiBaseUrl + '/estate/estateCategoryBrand/getList',
        getAssetStandard: apiBaseUrl + '/estate/estateSpec/getList',
        getAssetModel: apiBaseUrl + '/estate/estateModel/getList',
        getAssetOwner: apiBaseUrl + '/estate/estateOwnerModel/getEstateOwnerListOfContract',
        getAssetPublishListUrl: apiBaseUrl + '/estate/estateLeaseStrategy/getEstateLeaseStrategyPage',
        getLeaseStrategyDetailUrl: apiBaseUrl + '/estate/estateLeaseStrategy/getLeaseStrategyDetail',
        getManagerCenterListUrl: apiBaseUrl + '/estate/estateLeaseStrategy/getManagerCenterList',
        saveOrUpdateLeaseStrategyUrl: apiBaseUrl + '/estate/estateLeaseStrategy/',
        getNewAssetCategory: apiBaseUrl + '/estate/estateCategory/findMaxListForContract',
        batchUpdateLeaseStrategyUrl: apiBaseUrl + '/estate/estateLeaseStrategy/batchUpdateLeaseStrategy',
        searchModel: apiBaseUrl + '/estate/estateCategory/findCategorySpecBrandModelVoForContractBySearch',
        getAllAssetInfoUrl: apiBaseUrl + '/estate/estateCategory/findCategorySpecBrandModelVoForContractBySearch',
        getAssetContract: apiBaseUrl + '/estate/contract/estatemodel',
        AssetEdit: {
          addApartmentAsset: apiBaseUrl + '/estate/estate/addFlat', //公寓运营商
          addAsset: apiBaseUrl + '/estate/estate/addAsset', //资产运营商
          updateAsset: apiBaseUrl + '/estate/estate/update', //更新
          getAssetList: apiBaseUrl + '/estate/estate/getPage', //资产列表
          getAssetDetail: apiBaseUrl + '/estate/estate/getInfo', //资产详情
        },
        getAdminCenterListByModelIdUrl: apiBaseUrl + '/estate/estateLeaseStrategy/getAdminCenterListByModelId',
      },
      AssetOwner: {
        // 资产运营商资产管理
        getMaintenanceList: apiBaseUrl + '/estate/estateRepairPlan/getPage',
        getAssetPublishListUrl: apiBaseUrl + '/estate/estateOwnerModel/getEstateModlePriceOfOwnerPage',
        saveOrUpdateEstateOwnerModelUrl: apiBaseUrl + '/estate/estateOwnerModel/',
        getLeaseStrategyDetailUrl: apiBaseUrl + '/estate/estateOwnerModel/getEstateModlePriceOfOwnerDetail',
        batchUpdateLeaseStrategyUrl: apiBaseUrl + '/estate/estateOwnerModel/batchUpdateModelOwnerRecord',
        addMaintenance: apiBaseUrl + '/estate/estateRepairPlan/add',
        updateMaintenance: apiBaseUrl + '/estate/estateRepairPlan/update',
        batchUpdMaintenance: apiBaseUrl + '/estate/estateRepairPlan/batchUpdate',
      },
      RoomHub: {
        getChannelListUrl: apiBaseUrl + '/hubChannels/query',
        hubOrgAuths: apiBaseUrl + '/hubOrgAuths/query',
        getDistributeListUrl: apiBaseUrl + '/hubHousestatusSyncConfigs/query',
        addDistributeUrl: apiBaseUrl + '/hubHousestatusSyncConfigs/add',
        updateStatusUrl: apiBaseUrl + '/hubHousestatusSyncConfigs/status/update',
        deleteDistributeUrl: apiBaseUrl + '/hubHousestatusSyncConfigs/delete',
        judgmentAuthorityUrl: apiBaseUrl + '/hubOrgAuths/select',
        getHotLineListUrl: apiBaseUrl + '/hubHousestatusContacts/query',
        getHotLineByIdUrl: apiBaseUrl + '/hubHousestatusContacts/find',
        updateHotLineUrl: apiBaseUrl + '/hubHousestatusContacts/update',
        deleteHotLineUrl: apiBaseUrl + '/hubHousestatusContacts/delete',
        addHotLineUrl: apiBaseUrl + '/hubHousestatusContacts/add',
        getChanneApprovallUrl: apiBaseUrl + '/getApprovalForUpms/query',
      },
      RiskControl: {
        //账单科目tree
        getBillSubjectsUrl: apiBaseUrl + '/riskmanagement/getBillSubjects',
        //风控中心
        getRiskCenterListUrl: apiBaseUrl + '/riskmanagement/control/getPage',
        getManualControlUrl: apiBaseUrl + '/riskmanagement/control/manualControl',
        getCancelSystemControlUrl: apiBaseUrl + '/riskmanagement/control/cancelSystemControl',
        getRemoveControlUrl: apiBaseUrl + '/riskmanagement/control/cancelManualControl',
        getControlRecordListUrl: apiBaseUrl + '/riskmanagement/control/room',
        getNoEffectReason: apiBaseUrl + '/riskmanagement/control/noEffect',
        getControlBillListUrl: apiBaseUrl + '/riskmanagement/control/bill',
        getDeviceLogListUrl: apiBaseUrl + '/riskmanagement/control/execLog',

        getBillDelayUrl: apiBaseUrl + '/riskmanagement/create',
        getBillDelayRecordUrl: apiBaseUrl + '/riskmanagement/query',

        //策略
        getStrategyListUrl: apiBaseUrl + '/riskmanagement/rule/getPage',
        getSaveStrategyUrl: apiBaseUrl + '/riskmanagement/rule/save',
        getStrategyDetailUrl: apiBaseUrl + '/riskmanagement/rule/getById',
        getUpdateStrategyUrl: apiBaseUrl + '/riskmanagement/rule/upStatus',
        //策略应用
        getAddStrategyApplyUrl: apiBaseUrl + '/riskmanagement/ruleuse/add',
        getStrategyApplyUrl: apiBaseUrl + '/riskmanagement/rule/getRuleUse',
        getEditStrategyApplyUrl: apiBaseUrl + '/riskmanagement/ruleuse/edit',
        getUpdateStrategyApplyUrl: apiBaseUrl + '/riskmanagement/ruleuse/upStatus',
        getUnusedStaffManagerCenter: apiBaseUrl + '/riskmanagement/rule/queryManagerCenter',
        //白名单
        getWhiteListUrl: apiBaseUrl + '/riskmanagement/whitelist/getPage',
        getSaveWhiteListUrl: apiBaseUrl + '/riskmanagement/whitelist/save',
        getUpdateWhiteListUrl: apiBaseUrl + '/riskmanagement/whitelist/upStatus',
        getSubjectsDataUrl: apiBaseUrl + '/bill/getPrdSubjectList',
        getRoomListUrl: apiBaseUrl + '/riskmanagement/queryRoomList',
        getCurrentContractUrl: apiBaseUrl + '/riskmanagement/queryCurrentContract',
      },
    },
  };
};

export default AppConfig();
