/**
 * Created by neo on 2017/8/16.
 */

import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';

import util, {layComponent} from '../util';

const create = PanelFactory.create;

export default function report(app) {
  return (
    <Route
      name="报表"
      path="/report"
      component={create()}
      onEnter={util.requireAuth}
      menuKey={111}
    >
      <IndexRoute onEnter={(nextState, replace) => replace('/report/staticReport')}/>
      <Route
        name="统计报表"
        path="/report/staticReport"
        component={create()}
        onEnter={util.requireAuth}
        menuKey={111001}
      >
        <IndexRoute onEnter={(nextState, replace) => replace('/report/staticReport/summary')}/>
        <Route
          name="客房统计"
          path="/report/staticReport/guestRoom"
          component={layComponent(() => import('../../Report/GuestRoom'))}
          onEnter={util.requireAuth}
          menuKey={111001002}
        />
        <Route
          name="会员统计"
          path="/report/staticReport/member"
          component={layComponent(() => import('../../Report/Member/Member'))}
          onEnter={util.requireAuth}
          menuKey={111001004}
        />
        <Route
          name="能耗统计"
          path="/report/staticReport/energy"
          component={layComponent(() => import('../../Report/Energy'))}
          onEnter={util.requireAuth}
          menuKey={111001005}
        />
        <Route
          name="热水统计"
          path="/report/staticReport/hot"
          component={layComponent(() => import('../../Report/HotWater'))}
          onEnter={util.requireAuth}
          menuKey={111001006}
        />
        <Route
          name="冷水统计"
          path="/report/staticReport/cold"
          component={layComponent(() => import('../../Report/ColdWater'))}
          onEnter={util.requireAuth}
          menuKey={111001007}
        />
      </Route>
      <Route
        name="经营日报"
        path="/report/operationDaily"
        component={create()}
        onEnter={util.requireAuth}
        menuKey={111002}
      >
        <IndexRoute onEnter={(nextState, replace) => replace('/report/operationDaily/vacantRoom')}/>
        <Route
          name="空置客房"
          path="/report/operationDaily/vacantRoom"
          component={layComponent(() => import('../../Report/OperationDaily/VacantRoom'))}
          onEnter={util.requireAuth}
          menuKey={111002005}
        />
        <Route
          name="服务详情"
          path="/report/operationDaily/serviceDetails"
          component={layComponent(() => import('../../Report/OperationDaily/ServiceDetails/ServiceDetails'))}
          onEnter={util.requireAuth}
          menuKey={111002006}
        />
        <Route
          name="投诉建议"
          path="/report/operationDaily/suggestion"
          component={layComponent(() => import('../../Report/OperationDaily/Suggestion'))}
          onEnter={util.requireAuth}
          menuKey={111002007}
        />
      </Route>

      <Route
        name="电表监控"
        path="/report/ReportMonitoring"
        component={layComponent(() => import('../../Report/ReportMonitoring'))}
        onEnter={util.requireAuth}
        menuKey={111003}
      />

    </Route>
  )
}
