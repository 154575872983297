//风控
export default {
  113: {
    name: '风控',
    path: '/risk',
    icon: 'risk',
  },
  113001: {
    name: '风控中心',
    path: '/risk/controlCenter/center',
    leaf: true
  },
  113002: {
    name: '欠费管控',
    path: '/risk/arrears/policy/policyConfig'
  },
  113002001: {
    name: '管控策略',
    path: '/risk/arrears/policy/policyConfig'
  },
  113002002: {
    name: '白名单',
    path: '/risk/arrears/whiteList'
  },
}
