import React from 'react';
import { PanelModal, MessageNew, ValidatorHelp } from 'component';
import { Form, Checkbox, Popover, Icon } from 'antd';

import AgreementTxt from './agreementTxt';
import { Signup as Actions, common as CommonActions } from '../../../actions/baseInfo';

const FormItem = Form.Item;
const createForm = Form.create;

const closeAgreementModalStyles = {
  overlay: {
    zIndex: 104,
  },
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -20%)',
    border: 'none',
    padding: 0,
    overflowY: 'hidden',
    fontSize: '9pt',
    lineHeight: '1.5rem',
    width: '80%',
    maxWidth: '62.5rem',
  },
  contentLimit: {
    maxHeight: '500px',
    overflowY: 'auto',
  },
};

class RegistrationForm extends React.Component {
  static displayName = '注册';

  static defaultProps = {
    tickStart: 60,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isDisabled: false,
      submitDisabled: false,
      showAgreementModal: false,
      visibility: 'hidden',
      isChecked: false,
      tick: 61,
      help: '',
    };
  }

  renderLoading = () => {
    if (this.state.loading) {
      return <Icon type="loading-3-quarters" spin style={{ marginRight: '10px' }} />;
    }

    return null;
  };

  submit = e => {
    e.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        this.setState({ submitDisabled: true });
        this.props.form.validateFieldsAndScroll({ force: true }, (errors, values) => {
          if (errors) {
            this.setState({ loading: false });
            this.setState({ submitDisabled: false });
            if (!this.state.isChecked) {
              this.setState({ visibility: 'visible' });
              this.setState({ loading: false });
              this.setState({ submitDisabled: false });
              return;
            }
            return;
          }
          if (!this.state.isChecked) {
            this.setState({ visibility: 'visible' });
            this.setState({ loading: false });
            this.setState({ submitDisabled: false });
            return;
          }
          const { mobile, verifycode, password } = values;
          const params = {
            checkCode: verifycode,
            mobile,
            password,
          };
          Actions.submit(params).then(
            result => {
              this.setState({ loading: false });
              this.setState({ submitDisabled: false });
              if (result.errorCode == 0) {
                MessageNew.success(
                  {
                    title: '注册成功！',
                    content: '',
                  },
                  () => {
                    this.props.changeType({ inputType: 'login', indexTable: '0' });
                  }
                );
              } else {
                MessageNew.error({
                  title: '注册失败',
                  content: result.message,
                });
              }
            },
            err => {
              this.setState({ loading: false });
              this.setState({ submitDisabled: false });
              MessageNew.error(Dict.Messages.Error);
            }
          );
        });
      }
    );
  };

  onCheckBoxChange = e => {
    if (e.target.checked) {
      this.setState({ visibility: 'hidden', isChecked: true });
    } else {
      this.setState({ isChecked: false });
    }
  };

  onAgreementClick = e => {
    e.preventDefault();
    this.setState({ showAgreementModal: true });
  };

  onAgreementModalClose = () => {
    this.setState({ showAgreementModal: false });
  };

  getVerifyCodeBtnText = () => {
    const { tick } = this.state;
    let txt = '获取手机验证码';
    if (tick < 1) {
      txt = ' 重新获取 ';
    } else if (tick < 61) {
      txt = ` ${tick}秒后重新获取 `;
    }
    return txt;
  };

  onGetVerifyCodeClick = e => {
    e.preventDefault();
    // 手机校验
    this.props.form.validateFields(['mobile'], { force: true }, (errors, values) => {
      if (errors) {
        this.setState({ isDisabled: false });
        return;
      }
      this.setState(
        {
          isDisabled: true,
          tick: this.props.tickStart,
        },
        () => {
          CommonActions.sendCode({
            mobile: values.mobile,
            type: '00',
          }).then(
            result => {
              if (result.errorCode) {
                MessageNew.error({
                  title: '发送失败',
                  content: result.message,
                });
                this.setState({
                  tick: 1,
                });
              }
            },
            error => {
              MessageNew.error(Dict.Messages.Error);
              this.setState({
                tick: 1,
              });
            }
          );
          const interval = setInterval(() => {
            let { tick } = this.state;
            if (--tick == 0) {
              this.setState({
                tick,
                isDisabled: false,
              });
              clearInterval(interval);
            } else {
              this.setState({ tick: tick });
            }
          }, 1000);
        }
      );
    });
  };

  mobileValidator = (rule, value, callback) => {
    if (!value) {
      callback();
      return;
    }
    const test = ValidatorHelp.regex.isMobileNumber.test(value);
    if (test) {
      callback();
    } else {
      callback('请填写正确的手机号码');
    }
  };

  passwordValidator = (rule, value, callback) => {
    const { visible } = this.state;

    if (!value) {
      this.setState({
        help: '',
        visible: true,
      });
      callback('error');
      return;
    }
    this.setState({
      help: '',
    });

    if (!visible) {
      this.setState({
        visible: !!value,
      });
    }

    if (!this.isValidPassword(value)) {
      callback('error');
    } else {
      this.setState({
        visible: false,
      });
      callback();
    }
    // 如果 确认密码不为空，继续校验确认密码
    const rePassword = this.props.form.getFieldValue('rePassword');
    if (rePassword) {
      this.props.form.validateFields(['rePassword'], { force: true });
    }
  };

  rePasswordValidator = (rule, value, callback) => {
    if (!value) {
      callback();
      return;
    }
    const password = this.props.form.getFieldValue('password');
    if (value !== password) {
      callback('两次密码不一样！');
    } else {
      callback();
    }
  };

  verifycodeValidator = (rule, value, callback) => {
    if (!value) {
      callback();
      return;
    }
    const test = ValidatorHelp.regex.verifyCode.test(value);
    if (test) {
      callback();
    } else {
      callback('请输入6位数字校验码');
    }
  };

  isValidPassword(value) {
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;

    const emptyReg = /^.*[\s\n\r\t]+.*$/;

    return reg.test(value) && !emptyReg.test(value);
  }

  render() {
    const { isDisabled, showAgreementModal, submitDisabled, visibility, visible, help } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="register" style={{ padding: '48px 78px 0 60px', position: 'relative' }}>
        <Form>
          <FormItem>
            <span className="input-type">手机号码</span>
            {getFieldDecorator('mobile', {
              rules: [{ required: true, message: '请输入手机号！' }, { validator: this.mobileValidator }],
              validateTrigger: 'onBlur',
            })(<input type="text" className="input-style" style={{ width: '254px', marginBottom: '3px' }} placeholder="请输入手机号" />)}
            <button className="get-validate" disabled={isDisabled} onClick={this.onGetVerifyCodeClick}>
              {this.getVerifyCodeBtnText()}
            </button>
          </FormItem>
          <FormItem>
            <span className="input-type">验证码</span>
            {getFieldDecorator('verifycode', {
              rules: [{ required: true, message: '请输入验证码' }, { validator: this.verifycodeValidator }],
            })(
              <input
                type="text"
                className="input-style"
                style={{ width: '393px', marginLeft: '15px', marginBottom: '3px' }}
                placeholder="请输入收到的短信验证码（5分钟后失效）"
                maxLength={6}
              />
            )}
          </FormItem>
          <FormItem help={help} extra="密码由8-16个字符组成，必须包含大小写字母和数字">
            <span className="input-type">设置密码</span>
            <Popover
              content={
                <div style={{ padding: '4px 0' }}>
                  <div style={{ color: '#f5222d' }}>
                    <Icon type="close-circle-o" /> 您的密码不符合规格，请重新设置。
                  </div>
                </div>
              }
              overlayStyle={{ width: 290 }}
              placement="right"
              visible={visible}
            >
              {getFieldDecorator('password', {
                rules: [{ validator: this.passwordValidator }],
              })(
                <input
                  type="password"
                  className="input-style"
                  style={{ width: '393px', marginBottom: '3px' }}
                  maxLength={16}
                  placeholder="请输入密码"
                />
              )}
            </Popover>
          </FormItem>
          <FormItem>
            <span className="input-type">确认密码</span>
            {getFieldDecorator('rePassword', {
              rules: [{ required: true, message: '请再次输入密码' }, { validator: this.rePasswordValidator }],
            })(
              <input
                type="password"
                className="input-style"
                style={{ width: '393px', marginBottom: '3px' }}
                maxLength={16}
                placeholder="请再次输入密码"
              />
            )}
          </FormItem>
          <Checkbox onChange={this.onCheckBoxChange} style={{ fontSize: '0.8rem', marginLeft: '80px', color: '#fff' }}>
            我已阅读并同意
            <a onClick={this.onAgreementClick}>《用户注册协议》</a>
          </Checkbox>
          <div className="ant-form-explain" style={{ visibility: visibility, color: '#f04134' }}>
            请阅读并同意《用户注册协议》
          </div>
        </Form>
        <PanelModal
          title="用户注册协议"
          closeModal={this.onAgreementModalClose}
          isOpen={showAgreementModal}
          customStyles={closeAgreementModalStyles}
          width="80%"
          bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
        >
          <AgreementTxt />
        </PanelModal>
        <button className="btn-login" onClick={this.submit} disabled={submitDisabled} style={{ marginTop: '32px' }}>
          {this.renderLoading()}注 册
        </button>
      </div>
    );
  }
}

const Register = createForm()(RegistrationForm);

export default Register;
