/**
 * Created by ruiy on 9/14/15
 */

import Reflux from 'reflux';
import {Util} from 'component';

const Actions = Reflux.createActions({
  // authentication actions and common
  toggleSigninForm: {},
  updatePersonInfo: {asyncResult: true},
  updatePersonInfoSync: {asyncResult: false},
  getTicket: {children: ['success', 'failed']},
  signin: {children: ['completed', 'failed']},
  superSignin: {asyncResult: true},
  signout: {children: ['completed', 'failed']},
  chooseOperation: {children: ['completed', 'failed']},
  getRoleMenu: {children: ['completed', 'failed']},
  fetchAreaRoom: {children: ['success', 'failed']},
  setAreaShow: {},
  loadAreaTree: {children: ['success', 'failed']},
  getAreaList: {children: ['success', 'failed']},
  updateSelectedMenu: {},
  //housekeepr
  getFloorList: {},
  //hk-information
  removeNeighbor: {},
  addNeighbor: {},
  getNeighborList: {},
  AddHouseUser: {},
  //account
  fetchBillType: {},
  openTypeModal: {},
  setBillReminder: {},
  //hk-service
  createServiceOrder: {},
  openServiceDelModal: {},
  openCleanInfoModal: {},
  filterService: {},
  toggleServiceDone: {},
  getServiceList: {children: ['success', 'failed']},
  //hk-reservations
  filterMonth: {},
  getReservations: {children: ['success', 'failed']},
  cancelReservations: {children: ['success', 'failed']},
  processReservations: {children: ['success', 'failed']},
  updateReservations: {children: ['success', 'failed']},
  //hk-lock
  lockCancel: {children: ['completed', 'failed']},
  getTableLockList: {children: ['success', 'failed']},
  //hk-measure
  getMeasureList: {children: ['success', 'failed']},
  //hk-control
  getControlRoomDetail: {children: ['success', 'failed']},
  setTGState: {children: ['completed', 'failed']},
  lockDevice: {children: ['completed', 'failed']},

  // member actions
  getMember: {children: ['success', 'failed']},
  getRentHistory: {children: ['success', 'failed']},
  getFinancialFlow: {children: ['success', 'failed']},
  getServiceRecord: {children: ['success', 'failed']},
  getAuthenticationInformation: {children: ['success', 'failed']},
  getNotificationPush: {children: ['success', 'failed']},
  sendMSGNP: {children: ['success', 'failed']},
  getCommentFeedback: {children: ['success', 'failed']},
  sendMSGCF: {children: ['success', 'failed']},

  // organization actions

  saveServicesWorkclasses: {children: ['completed', 'failed']},
  queryWorkschedule: {children: ['success', 'failed']},
  queryAttendance: {children: ['success', 'failed']},

  // Financial actions
  getAccount: {children: ['success', 'failed']},
  getBill: {children: ['success', 'failed']},
  queryBill: {children: ['success', 'failed']},
  queryAccountFlow: {children: ['success', 'failed']},
  queryAccountIncome: {children: ['success', 'failed']},
  queryAccountOutcome: {children: ['success', 'failed']},
  queryCheckAccount: {children: ['success', 'failed']},
  payAccountBill: {children: ['success', 'failed']},
  remindBillCharge: {children: ['completed', 'failed']},
  getBillDetail: {children: ['success', 'failed']},

  // setting actions
  getCostStrategy: {children: ['success', 'failed']},
  getResourcesAccounting: {children: ['success', 'failed']},
  getMeasuringInstrument: {children: ['success', 'failed']},
  getContractTemplate: {children: ['success', 'failed']},
  getAppSetting: {children: ['success', 'failed']},

  //service
  getServiceItemList: {children: ['success', 'failed']},
  toggleServiceItemEdit: {},
  updateServiceItem: {children: ['completed', 'failed']},
  getArea: {children: ['success', 'failed']},

  //config actions
  getScheme: {children: ['success', 'failed']},
  getConfigPriceSchemeInit: {children: ['success', 'failed']},

  getConfigPriceScheme: {children: ['success', 'failed']},
  getConfigPriceSchemeShow: {children: ['success', 'failed']},
  getConfigPriceSchemeEdit: {children: ['success', 'failed']},
  editConfigPriceScheme: {children: ['completed', 'failed']},
  deleteConfigPriceScheme: {children: ['completed', 'failed']},

  //apartment actions
  getApartmentStatistic: {children: ['success', 'failed']},
  getApartmentProduce: {children: ['success', 'failed']},
  getApartmentScheme: {children: ['success', 'failed']},
  getApartmentHeaderCity: {children: ['completed', 'failed']},
  getApartmentHeaderCounty: {children: ['completed', 'failed']},

  getApartmentCity: {children: ['success', 'failed']},
  getApartmentCounty: {children: ['success', 'failed']},
  updateCityPosition: {},
  updateCountyPosition: {},

  //集中式
  getApartmentCenteralization: {children: ['success', 'failed']},
  clearApartmentCenteralization: {},
  searchCenteralizationCircle: {children: ['completed', 'failed']},
  getApartmentCenteralizationDetail: {children: ['success', 'failed']},
  getCenterCityList: {children: ['success', 'failed']},
  searchCommunityByName: {children: ['completed', 'failed']},
  getCenterCommunityByCity: {children: ['success', 'failed']},
  updateCenterRoomResource: {children: ['completed', 'failed']},
  addCenterRoomResource: {children: ['completed', 'failed']},
  getCenterRoomResource: {children: ['success', 'failed']},
  getApartmentCenterFloor: {children: ['success', 'failed']},
  addApartmentRoomPartFee: {children: ['success', 'failed']},
  removeApartmentRoomPartFee: {children: ['success', 'failed']},
  getApartmentRoomPart: {children: ['success', 'failed']},
  updateApartmentCenteralizationDetail: {children: ['completed', 'failed']},
  deleteApartmentCenteralizationDetail: {children: ['completed', 'failed']},
  addCenterCommunity: {children: ['completed', 'failed']},
  getBuildingList: {children: ['completed', 'failed']},
  updateBuildingList: {children: ['completed', 'failed']},
  updateBuildingFloor: {children: ['completed', 'failed']},
  deleteBuilding: {children: ['completed', 'failed']},
  deleteBuildingFloor: {children: ['completed', 'failed']},
  getFloorRoomList: {children: ['success', 'failed']},
  getAptCenterPriceList: {children: ['success', 'failed']},
  getRoomTagList: {children: ['success', 'failed']},
  getPartRoomTagList: {children: ['success', 'failed']},
  updateFloorRoom: {children: ['completed', 'failed']},
  getRoomInfo: {children: ['success', 'failed']},
  getCenteralizationRoomFeeItem: {children: ['success', 'failed']},
  getDecentralizedRoomFeeItem: {children: ['success', 'failed']},
  getRoomPartInfo: {children: ['success', 'failed']},
  getRoomAppliances: {children: ['success', 'failed']},
  getProductSubjectList: {asyncResult: true},
  getChildAllSubjectList: {asyncResult: true},
  getPartRoomAppliances: {children: ['success', 'failed']},
  GetPartRoomPriceList: {children: ['success', 'failed']},
  modifyApartmentCentralizationHousingRoomStatus: {children: ['completed', 'failed']},
  getCentralizationEntireRoomOptionStatus: {children: ['success', 'failed']},
  getCentralizationPartRoomOptionStatus: {children: ['success', 'failed']},
  getBuildingInfo: {children: ['completed', 'failed']},

  //分散式
  getApartmentDecentralized: {children: ['success', 'failed']},

  getApartmentDecentralizedManagement: {children: ['success', 'failed']},
  editApartmentDecentralizedManagement: {children: ['completed', 'failed']},
  deleteApartmentDecentralizedManagement: {children: ['completed', 'failed']},

  getApartmentDecentralizedConfiguration: {children: ['success', 'failed']},

  getApartmentDecentralizedHousingFacilities: {children: ['success', 'failed']},
  getApartmentDecentralizedHousingFeatures: {children: ['success', 'failed']},
  getApartmentDecentralizedHousingPrices: {children: ['success', 'failed']},
  getApartmentDecentralizedHousingCommunities: {children: ['success', 'failed']},
  addApartmentDecentralizedHousingCommunity: {children: ['completed', 'failed']},
  getApartmentDecentralizedHousingAparts: {children: ['success', 'failed']},
  getApartmentDecentralizedHousingSource: {children: ['success', 'failed']},
  addApartmentDecentralizedHousingSource: {children: ['completed', 'failed']},

  getApartmentDecentralizedHousingTotal: {children: ['success', 'failed']},
  addApartmentDecentralizedHousingTotal: {children: ['completed', 'failed']},
  editApartmentDecentralizedHousingTotal: {children: ['completed', 'failed']},
  deleteApartmentDecentralizedHousingTotal: {children: ['completed', 'failed']},

  getApartmentDecentralizedHousingApart: {children: ['success', 'failed']},
  addApartmentDecentralizedHousingApart: {children: ['completed', 'failed']},
  editApartmentDecentralizedHousingApart: {children: ['completed', 'failed']},
  deleteApartmentDecentralizedHousingApart: {children: ['completed', 'failed']},
  getDecentralizedPartPriceList: {children: ['success', 'failed']},
  getDecentralizedHousePriceList: {children: ['success', 'failed']},

  getDecentralizedHouseProductSubjectList: {children: ['success', 'failed']},
  getDecentralizedPartProductSubjectList: {children: ['success', 'failed']},

  getDecentralizedEntireRoomOptionStatus: {children: ['success', 'failed']},
  getDecentralizedPartRoomOptionStatus: {children: ['success', 'failed']},

  modifyApartmentDecentralizedHousingRoomStatus: {children: ['completed', 'failed']},
  getRoomOptionStatus: {children: ['completed', 'failed']},

  roomPictureUpload: {children: ['completed', 'failed']}, //客房照片单图上传
  getPictureByPack: {children: ['completed', 'failed']}, //根据packId获取图片
  delRoomPicture: {children: ['completed', 'failed']}, //删除图片
  changeRoomPicture: {children: ['completed', 'failed']}, //修改图片属性
  getRoomPictureClassify: {children: ['completed', 'failed']}, //获取客房图片分类

  /*房源查询*/
  getApartmentHousing: {children: ['success', 'failed']},
  getApartmentHousingDetail: {children: ['success', 'failed']},

  //配置-服务中心
  addRentItem: {},
  addCheckOutRule: {},
  deleteCheckOutRule: {},
  deleteRentItem: {},
  addProductAdItem: {children: ['completed', 'failed']},
  findProductById: {},

  makeProperty: {asyncResult: true},
  syncRoom: {asyncResult: true},

});

Actions.getTicket.listen(function () {
  const url = common.api.getTicket;
  const param = {};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.updatePersonInfo.listen(function (arg) {
  const url = common.api.Zone.getPersonalInformationUrl + arg.customerId;
  common.ajax(url, {}, 'GET', 'json', true, this.completed, this.failed);
});

Actions.signin.listen(function (person, pwd) {
  const url = common.api.signinUrl;
  const param = {username: person, pwd: pwd};
  this.promise(common.ajaxPromise(url, param, 'POST', 'json'));
});

Actions.superSignin.listen(function (args) {
  const url = common.api.superSigninUrl;
  this.promise(common.ajaxPromise(url, args, 'POST', 'json'));
});

Actions.signout.listen(function (params) {
  const url = common.api.signoutUrl;
  this.promise(common.ajaxPromise(url, params, 'GET', 'json'));
});

Actions.chooseOperation.listen(function (params) {
  const url = common.api.chooseOperation;
  this.promise(common.ajaxPromise(url, params, 'POST', 'json'));
});

Actions.getRoleMenu.listen(function (params) {
  const url = common.api.basic.getMenuByStaffId;
  common.ajax(url, params, 'POST', 'json', true, this.completed, this.failed);
});

Actions.loadAreaTree.listen(function () {
  const url = common.api.loadAreaTree;
  const param = {};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.getAreaList.listen(function () {
  const url = common.api.getAreaList;
  const param = {};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

//管家
Actions.fetchAreaRoom.listen(function (filters) {
  const url = common.api.managerUrl;
  const param = {id: filters};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.getServiceList.listen(function (filters, finish) {
  const url = common.api.housekeeper.Service.getServiceList;
  const param = {area: filters.roomId};
  if (finish) {
    param.startTime = filters.startTime;
    param.endTime = filters.endTime;
  } else {
    param.unfinished = 1;
  }

  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});

//获取预订table
Actions.getReservations.listen(function (filters) {
  const url = common.api.housekeeper.Reservations.reservationsByMonth;
  const param = {month: filters.month};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.cancelReservations.listen(function (id) {
  const url = common.api.housekeeper.Reservations.cancelReservations;
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.updateReservations.listen(function (id) {
  const url = common.api.housekeeper.Reservations.cancelReservations;
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.processReservations.listen(function (id) {
  const url = common.api.housekeeper.Reservations.cancelReservations;
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.lockCancel.listen(function (aid) {
  const url = common.api.housekeeper.Lock.del;
  const param = {aid: aid};
  this.promise(common.ajaxPromise(url, param, 'GET', 'json'));
});

Actions.getTableLockList.listen(function (area) {
  const url = common.api.housekeeper.Lock.tableList;
  const param = {area: area};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.getMeasureList.listen(function (area) {
  const url = common.api.housekeeper.Measure.list;
  const param = {area: area};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.getControlRoomDetail.listen(function (room) {
  const url = common.api.housekeeper.Control.roomDetail;
  const param = {id: room};
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});

Actions.setTGState.listen(function (serviceid, state) {
  const url = common.api.housekeeper.Control.control;
  const param = {serviceid: serviceid, state: state};
  this.promise(common.ajaxPromise(url, param, 'POST', 'json'));
});
Actions.lockDevice.listen(function (id, scope, locked) {
  const url = common.api.housekeeper.Control.lock;
  const param = {id: id, scope: scope, locked: locked};
  this.promise(common.ajaxPromise(url, param, 'POST', 'json'));
});

/* Member Actions */
Actions.getMember.listen(function () {
  const url = './data/member_get_member.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.getRentHistory.listen(function (id) {
  const url = './data/member_get_rent_history.json';
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.getFinancialFlow.listen(function (id) {
  const url = './data/member_get_financial_flow.json';
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.getServiceRecord.listen(function (id) {
  const url = './data/member_get_service_record.json';
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.getAuthenticationInformation.listen(function (id) {
  const url = './data/member_get_authentication_information.json';
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.getNotificationPush.listen(function (id) {
  const url = './data/member_get_notification_push.json';
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.sendMSGNP.listen(function (msg_id, msg_cnt, msg_bool) {
  const url = './data/member_sendMSGNP';
  const param = {msg_id: msg_id, msg_cnt: msg_cnt, msg_bool: msg_bool};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.getCommentFeedback.listen(function (id) {
  const url = './data/member_get_comment_feedback.json';
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.sendMSGCF.listen(function (msg_id, msg_cnt, msg_bool) {
  const url = './data/member_sendMSGCF.json';
  const param = {msg_id: msg_id, msg_cnt: msg_cnt, msg_bool: msg_bool};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

//service module
Actions.getServiceItemList.listen(function () {
  const url = common.api.Service.item_list;
  const param = {};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.updateServiceItem.listen(function (item) {
  const url = common.api.Service.item_update;
  const param = {};
  this.promise(common.ajaxPromise(url, param, 'POST', 'json'));
});


// Financial actions
Actions.getAccount.listen(function () {
  console.log('financial_getAccount');
  const url = './data/finacial_get_account.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.queryAccountFlow.listen(function (start_date, end_date) {
  console.log('financial_queryAccountFlow');
  const url = './data/finacial_query_account_flow.json';
  const param = {start_date: start_date, end_date: end_date};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.queryAccountIncome.listen(function (date) {
  console.log('queryAccountIncome');
  const url = './data/finacial_query_account_income.json';
  const param = {date: date};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.queryAccountOutcome.listen(function (date) {
  console.log('queryAccountOutcome');
  const url = './data/finacial_query_account_outcome.json';
  const param = {date: date};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.queryCheckAccount.listen(function (date) {
  console.log('queryCheckAccount');
  const url = './data/finacial_query_check_account.json';
  const param = {date: date};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.payAccountBill.listen(function () {
  console.log('financial_payAccountBill');
  const url = './data/finacial_pay_account_bill.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.getBill.listen(function () {
  console.log('financial_getBill');
  const url = './data/finacial_get_bill_info.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.queryBill.listen(function (start_date, end_date) {
  console.log('financial_queryBill');
  const url = './data/finacial_query_bill_info.json';
  const param = {start_date: start_date, end_date: end_date};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});
Actions.remindBillCharge.listen(function () {
  console.log('financial_remindBillCharge');
  const url = './data/finacial_remaind_bill_charge.json';
  this.promise(common.ajaxPromise(url, null, 'GET', 'json'));
});
Actions.getBillDetail.listen(function (bill) {
  console.log('financial_getBillDetail');
  const url = './data/finacial_get_bill_info.json';
  const param = {bill: bill};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

// setting actions
Actions.getCostStrategy.listen(function () {
  console.log('financial_getCostStrategy');
  const url = './data/finacial_get_cost_strategy.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.getResourcesAccounting.listen(function () {
  console.log('financial_getResourcesAccounting');
  const url = './data/finacial_get_resources_accounting.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.getMeasuringInstrument.listen(function () {
  console.log('financial_getMeasuringInstrumentt');
  const url = './data/finacial_get_measuring_instrument.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.getContractTemplate.listen(function () {
  console.log('financial_getContractTemplate');
  const url = './data/finacial_get_contract_template.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.getAppSetting.listen(function () {
  console.log('financial_getAppSetting');
  const url = './data/finacial_get_app_setting.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});


Actions.addProductAdItem.listen(function (data) {
  const url = 'http://localhost:8080/upload';
  const param = new FormData();
  param.append('file', data);
  this.promise(common.ajaxPromise(url, param, 'POST', 'json', false));
});


//config actions
Actions.getConfigPriceScheme.listen(function (orgId) {
  const url = common.api.PriceScheme.getConfigPriceScheme + orgId;
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});

Actions.getConfigPriceSchemeInit.listen(function () {
  const url = common.api.PriceScheme.getConfigPriceSchemeInit;
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});

Actions.getConfigPriceSchemeShow.listen(function (scheme) {
  const url = common.api.PriceScheme.getConfigPriceSchemeShow + scheme;
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});

Actions.getConfigPriceSchemeEdit.listen(function (scheme) {
  const url = common.api.PriceScheme.getConfigPriceSchemeShow + scheme;
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});

Actions.deleteConfigPriceScheme.listen(function (scheme) {
  const url = common.api.PriceScheme.deleteConfigPriceScheme + scheme;
  this.promise(common.ajaxPromise(url, null, 'POST', 'json', false));
});

Actions.editConfigPriceScheme.listen(function (what, schemeId, who, data) {
  let url = common.api.PriceScheme.editConfigPriceScheme;
  let param = null;
  switch (what) {
    case 'add':
      switch (who) {
        case '10000':
          param = {
            orgId: data.orgId,
            name: data.name,
            startTime: data.startTime,
            endTime: data.endTime,
            status: data.status,
            description: data.description
          };
          url += '/aptproduct/resourceprice/addpricescheme';
          this.promise(common.ajaxPromise(url, param, 'POST', 'json', false));
          break;
        default:
          url += '/aptproduct/resourceprice/addprice/schemeid/' + schemeId + '/prices';
          this.promise(common.ajaxPromise(url, {priceStr: data}, 'POST', 'json', false));
          break;
      }
      break;
    case 'clone':
      break;
    case 'edit':
      switch (who) {
        case '10000':
          param = {
            id: data.id,
            orgId: data.orgId,
            name: data.name,
            startTime: data.startTime,
            endTime: data.endTime,
            status: data.status,
            description: data.description
          };
          url += '/aptproduct/resourceprice/updatepricescheme';
          this.promise(common.ajaxPromise(url, param, 'POST', 'json', false));
          break;
        default:
          url += '/aptproduct/resourceprice/addprice/schemeid/' + schemeId + '/prices';
          this.promise(common.ajaxPromise(url, {priceStr: data}, 'POST', 'json', false));
          break;
      }
      break;
    case 'delete':
      url += '/aptproduct/resourceprice/deleteprice/ids/' + data;
      this.promise(common.ajaxPromise(url, null, 'POST', 'json', false));
      break;
    default:
      break;
  }
});


// apartment actions
Actions.getApartmentStatistic.listen(function () {
  const url = './data/apartment_get_statistic.json';
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.getApartmentCenteralization.listen(function (orgId, cityId, countyId, centerName = '') {
  const url = common.api.Apartment.getApartmentManagerList;
  const params = {
    orgId: orgId,
    centerType: 1051001,
    centerName: centerName,
    cityId: cityId,
    countyId: countyId,
    loadingElementId: 'table-load'
  };

  common.ajax(url, params, 'POST', 'json', true, this.success, this.failed);
});
Actions.getApartmentCity.listen(function () {
  const url = common.api.Apartment.getOpenCityList;
  common.ajax(url, {pageSize: 1000}, 'POST', 'json', true, this.success, this.failed);
});
Actions.getApartmentCounty.listen(function (cityCode) {
  const url = common.api.Apartment.getCountyList;
  const param = {cityCode: cityCode, pageSize: 9999};
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.getApartmentProduce.listen(function (bizOrgId) {
  const url = common.api.Apartment.getProductDict + $.param({bizOrgId: bizOrgId});
  common.ajax(url, {}, 'POST', 'json', true, this.success, this.failed);
});
Actions.getApartmentScheme.listen(function (bizOrgId) {
  const url = common.api.Apartment.getApartmentPriceScheme + 'orgid/' + bizOrgId;
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});


Actions.getApartmentHeaderCity.listen(function () {
  const url = common.api.Apartment.getOpenCityList;
  this.promise(common.ajaxPromise(url, {pageSize: 1000}, 'POST', 'json'));
});
Actions.getApartmentHeaderCounty.listen(function (cityCode) {
  const url = common.api.Apartment.getCountyList;
  const param = {cityCode: cityCode, pageSize: 9999};
  this.promise(common.ajaxPromise(url, param, 'POST', 'json'));
});

//集中式
Actions.getApartmentCenteralizationDetail.listen(function (id) {
  const url = common.api.Apartment.getCenteralizationDetail + $.param({centerId: id});
  common.ajax(url, {loadingElementId: 'body'}, 'POST', 'json', true, this.success, this.failed);
});

Actions.getCenterCommunityByCity.listen(function (cityId, countyId, keyWord, status) {
  const url = common.api.Apartment.getCenterCommunityByCity;
  const param = {keyWords: keyWord, countyId: countyId, cityId: cityId, status: status};
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.searchCommunityByName.listen(function (param) {
  const url = common.api.Apartment.getCenterCommunityByCity;
  this.promise(Util.ajaxPromise(url, param, 'POST', 'json', false, true));
});
Actions.getCenterRoomResource.listen(function (id) {
  const url = common.api.Apartment.getCenterRoomResource;
  const param = {communityId: id};
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.updateCenterRoomResource.listen(function (data) {
  const url = common.api.Apartment.getCenterRoomResource + 'post/';
  this.promise(common.ajaxPromise(url, data, 'POST', 'json'));
});
Actions.addCenterRoomResource.listen(function (data) {
  const url = common.api.Apartment.getCenterRoomResource + 'put/';
  this.promise(common.ajaxPromise(url, data, 'POST', 'json'));
});
Actions.getApartmentCenterFloor.listen(function (id) {
  const url = common.api.Apartment.getBuildingFloorList;
  const param = {
    buildingId: id,
    orderBy: 'floorNo asc',
    pageSize: 999
  };
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.getApartmentRoomPart.listen(function (id) {
  const url = common.api.Apartment.getApartmentRoomPart;
  const param = {id: id};
  common.ajax(url, param, 'GET', 'json', true, this.success, this.failed);
});

Actions.updateApartmentCenteralizationDetail.listen(function (data) {
  const url = common.api.Apartment.updateCenteralizationDetail;

  this.promise(common.ajaxPromise(url, data, 'POST', 'json', true, 'body'));
});

Actions.deleteApartmentCenteralizationDetail.listen(function (id) {
  const url = common.api.Apartment.deleteCenteralizationDetail + $.param({centerId: id});
  this.promise(common.ajaxPromise(url, {}, 'POST', 'json', false, 'body'));
});

Actions.addCenterCommunity.listen(function (data) {
  const url = common.api.Apartment.addCenterCommunity;
  this.promise(common.ajaxPromise(url, data, 'POST', 'json'));
});
Actions.getBuildingList.listen(function (id) {
  const url = common.api.Apartment.getBuildingList;
  const param = {
    apartmentId: id,
    pageSize: 200,
    orderBy: 'buildingNo asc'
  };
  this.promise(common.ajaxPromise(url, param, 'POST', 'json', false, 'body'));
});
Actions.updateBuildingList.listen(function (data, type) {
  let url = common.api.Apartment.getBuildingList;
  if (type == 'update') {
    url += 'put';
  } else {
    url += 'post';
  }
  this.promise(common.ajaxPromise(url, data, 'POST', 'json', false, 'body'));
});
//获取某楼层的房间列表
Actions.getFloorRoomList.listen(function (buildingId, floorId, svcCenterId) {
  const url = common.api.Apartment.getFloorRoomList;
  const param = {
    buildingId,
    floorId,
    svcCenterId: svcCenterId,
    orderBy: 'roomNo asc'
  };
  window.floorId = floorId;

  param.loadingElementId = 'body';

  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.updateBuildingFloor.listen(function (data, type) {
  let url = common.api.Apartment.getBuildingFloorList;
  if (type == 'update') {
    url += 'put';
  } else {
    url += 'post';
  }
  this.promise(common.ajaxPromise(url, data, 'POST', 'json', false, 'body'));
});
Actions.deleteBuildingFloor.listen(function (id) {
  const url = common.api.Apartment.getBuildingFloorList + 'delete/id/' + id;
  this.promise(common.ajaxPromise(url, {}, 'GET', 'json'));
});
Actions.deleteBuilding.listen(function (id) {
  const url = common.api.Apartment.getBuildingList + 'delete/id/' + id;
  this.promise(common.ajaxPromise(url, {}, 'GET', 'json'));
});
Actions.getAptCenterPriceList.listen(function (scheme) {
  const url = common.api.Apartment.getPriceSchemeList + scheme;
  common.ajax(url, {}, 'GET', 'json', true, this.success, this.failed);
});
Actions.getRoomTagList.listen(function () {
  const url = common.api.Apartment.getApartmentTag;
  common.ajax(url, {status: 1}, 'POST', 'json', true, this.success, this.failed);
});
Actions.getPartRoomTagList.listen(function () {
  const url = common.api.Apartment.getApartmentTag;
  common.ajax(url, {status: 1}, 'POST', 'json', true, this.success, this.failed);
});
Actions.updateFloorRoom.listen(function (data, uploads, additionFiles, type) {
  let url = common.api.Apartment.getFloorRoomBase;
  if (type == 'update') {
    url += 'put';
  } else {
    url += 'post';
  }

  const param = new FormData();

 /* if (uploads) {
    uploads.map((item) => {
      param.append('pics', item);
    });
  } else {
    param.append('pics', '');
  }

  if (additionFiles) {
    additionFiles.map(item => {
      param.append('files', item);
    });
  } else {
    param.append('files', '');
  }*/

  for (const key in data) {
    if (typeof data[key] == 'object') {
      param.append(key, JSON.stringify(data[key]));
    } else {
      param.append(key, data[key]);
    }
  }
  this.promise(Util.ajaxPromise(url, param, 'POST', 'json', true, 'body'));
});
Actions.getRoomInfo.listen(function (id) {
  const url = common.api.Apartment.getRoomInfo + id + '/personId/-999';
  common.ajax(url, {loadingElementId: 'body'}, 'GET', 'json', true, this.success, this.failed);
});
Actions.getCenteralizationRoomFeeItem.listen(function (id) {
  let url = common.api.Apartment.getRoomFeeItem;

  url = url.replace('{id}', id);

  common.ajax(url, {}, 'GET', 'json', true, this.success, this.failed);
});
Actions.getDecentralizedRoomFeeItem.listen(function (id) {
  let url = common.api.Apartment.getRoomFeeItem;

  url = url.replace('{id}', id);

  common.ajax(url, {}, 'GET', 'json', true, this.success, this.failed);
});
Actions.getRoomPartInfo.listen(function (id) {
  const url = common.api.Apartment.getRoomInfo + id + '/personId/-999';
  common.ajax(url, {loadingElementId: 'body'}, 'GET', 'json', true, this.success, this.failed);
});
Actions.getRoomAppliances.listen(function (id) {
  const url = common.api.Apartment.getRoomFacilities + 'productId=' + id;
  common.ajax(url, {}, 'POST', 'json', true, this.success, this.failed);
});
Actions.getProductSubjectList.listen(function (params) {
  const url = common.api.Config.getProductSubject;
  common.ajax(url, params, 'POST', 'json', true, this.completed, this.failed);
});
Actions.getChildAllSubjectList.listen(function (params) {
  const url = common.api.Config.getProductSubject;
  common.ajax(url, params, 'POST', 'json', true, this.completed, this.failed);
});
Actions.getPartRoomAppliances.listen(function (id) {
  const url = common.api.Apartment.getRoomFacilities + 'productId=' + id;
  common.ajax(url, {}, 'POST', 'json', true, this.success, this.failed);
});
Actions.GetPartRoomPriceList.listen(function (scheme) {
  const url = common.api.Apartment.getPriceSchemeList + scheme;
  common.ajax(url, {}, 'GET', 'json', true, this.success, this.failed);
});
//end


//分散式
Actions.getApartmentDecentralized.listen(function (orgId, centerType, cityId, countyId, centerName) {
  const url = common.api.Apartment.getApartmentDecentralized;
  const param = {orgId: orgId, centerType: centerType, cityId: cityId, countyId: countyId, centerName: centerName}
  for (const o in param) {
    if (param[o] == '-1' || param[o] == '') {
      delete param[o]
    }
  }
  param.loadingElementId = 'table-load';
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});

//客房照片单图上传
Actions.roomPictureUpload.listen(function (data, file) {
  const url = common.api.Apartment.roomPictureUpload;
  const params = new FormData();
  for (const key in data) {
    if (typeof data[key] == 'object') {
      params.append(key, JSON.stringify(data[key]));
    } else {
      params.append(key, data[key]);
    }
  }
  params.append('pics', file)
  this.promise(common.ajaxPromise(url, params, 'POST', 'json', true, 'body'));
});

//根据packId获取图片
Actions.getPictureByPack.listen(function (packageId) {
  const url = `${common.api.Apartment.getPictureByPack}?packageId=${packageId}`;
  this.promise(common.ajaxPromise(url, '', 'Get', 'json', true, 'body'));
});

//删除图片
Actions.delRoomPicture.listen(function (params) {
  debugger;
  const url = common.api.Apartment.delRoomPicture;
  this.promise(common.ajaxPromise(url, params, 'POST', 'json', false, 'body'));
});

//修改图片属性
Actions.changeRoomPicture.listen(function (params) {
  const url = common.api.Apartment.changeRoomPicture;
  this.promise(common.ajaxPromise(url, params, 'POST', 'json', false, 'body'));
});

//获取客房照片分类
Actions.getRoomPictureClassify.listen(function (params) {
  const url = common.api.Apartment.getRoomPictureClassify;
  this.promise(common.ajaxPromise(url, params, 'Get', 'json', true, 'body'));
});

//Management
Actions.getApartmentDecentralizedManagement.listen(function (centerId) {
  const url = common.api.Apartment.getApartmentDecentralizedManagement;

  const param = {centerId: centerId};
  param.loadingElementId = 'body';
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.editApartmentDecentralizedManagement.listen(function (data) {
  const url = common.api.Apartment.editApartmentDecentralizedManagement;

  this.promise(common.ajaxPromise(url, data, 'POST', 'json', true, 'body'));
});
Actions.deleteApartmentDecentralizedManagement.listen(function (centerId) {
  const url = common.api.Apartment.deleteApartmentDecentralizedManagement + $.param({centerId: centerId});
  this.promise(common.ajaxPromise(url, {}, 'POST', 'json', true, 'body'));
});
Actions.modifyApartmentCentralizationHousingRoomStatus.listen(function (param) {
  const url = common.api.Apartment.modifyApartmentCentralizationHousingRoomStatus;
  this.promise(common.ajaxPromise(url, param, 'POST', 'json', false, 'body'));
});
//Configuration
Actions.getApartmentDecentralizedConfiguration.listen(function (svcCenterId) {
  const url = common.api.Apartment.getApartmentDecentralizedConfiguration;
  const param = {svcCenterId: svcCenterId, loadingElementId: 'body'};
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
//Housing
Actions.getApartmentDecentralizedHousingFacilities.listen(function (param) {
  const url = common.api.Apartment.getApartmentDecentralizedHousingFacilities;
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.getApartmentDecentralizedHousingFeatures.listen(function () {
  const url = common.api.Apartment.getApartmentDecentralizedHousingFeatures;
  common.ajax(url, {status: 1}, 'POST', 'json', true, this.success, this.failed);
});
Actions.getApartmentDecentralizedHousingPrices.listen(function (param) {
  const url = common.api.Apartment.getApartmentDecentralizedHousingPrices + param;
  common.ajax(url, null, 'GET', 'json', true, this.success, this.failed);
});
Actions.getApartmentDecentralizedHousingAparts.listen(function (id) {
  const url = common.api.Apartment.getApartmentDecentralizedHousingAparts;
  const param = {id: id};
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.getApartmentDecentralizedHousingCommunities.listen(function (cityId, name, status) {
  const url = common.api.Apartment.getApartmentDecentralizedHousingCommunities;
  const param = {cityId: cityId, keyWords: name, status: status};
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.addApartmentDecentralizedHousingCommunity.listen(function (param) {
  const url = common.api.Apartment.addApartmentDecentralizedHousingCommunity;
  this.promise(common.ajaxPromise(url, param, 'POST', 'json'));
});
Actions.getApartmentDecentralizedHousingSource.listen(function (param) {
  const url = common.api.Apartment.getApartmentDecentralizedHousingSource;
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.addApartmentDecentralizedHousingSource.listen(function (param) {
  const url = common.api.Apartment.addApartmentDecentralizedHousingSource;
  this.promise(common.ajaxPromise(url, param, 'POST', 'json'));
});
Actions.getApartmentDecentralizedHousingTotal.listen(function (param) {
  const url = common.api.Apartment.getApartmentDecentralizedHousingTotal + param + '/personId/-999';
  common.ajax(url, {loadingElementId: 'body'}, 'GET', 'json', true, this.success, this.failed);
});
Actions.addApartmentDecentralizedHousingTotal.listen(function (data, uploads, additionFiles) {
  const url = common.api.Apartment.addApartmentDecentralizedHousingTotal;
  const param = new FormData();
  if (uploads) {
    uploads.map((item) => {
      param.append('pics', item);
    });
  } else {
    param.append('pics', '');
  }

  if (additionFiles) {
    additionFiles.map(item => {
      param.append('files', item);
    });
  } else {
    param.append('files', '');
  }

  for (const obj in data) {
    if (typeof data[obj] == 'object') {
      param.append(obj, JSON.stringify(data[obj]));
    } else {
      param.append(obj, data[obj]);
    }
  }
  this.promise(common.ajaxPromise(url, param, 'POST', 'json', true));
});

Actions.editApartmentDecentralizedHousingTotal.listen(function (data, uploads, additionFiles) {
  const url = common.api.Apartment.editApartmentDecentralizedHousingTotal;
  const param = new FormData();
  if (uploads) {
    uploads.map((item) => {
      param.append('pics', item);
    });
  } else {
    param.append('pics', '');
  }

  if (additionFiles) {
    additionFiles.map(item => {
      param.append('files', item);
    });
  } else {
    param.append('files', '');
  }

  for (const obj in data) {
    if (typeof data[obj] == 'object') {
      param.append(obj, JSON.stringify(data[obj]));
    } else {
      param.append(obj, data[obj]);
    }
  }

  this.promise(Util.ajaxPromise(url, param, 'POST', 'json', true, 'body'));
});
Actions.deleteApartmentDecentralizedHousingTotal.listen(function (param) {
  const url = common.api.Apartment.deleteApartmentDecentralizedHousingTotal + param;
  this.promise(common.ajaxPromise(url, null, 'GET', 'json', true));
});
Actions.getApartmentDecentralizedHousingApart.listen(function (param) {
  const url = common.api.Apartment.getApartmentDecentralizedHousingApart + param + '/personId/-999';
  common.ajax(url, {loadingElementId: 'body'}, 'GET', 'json', true, this.success, this.failed);
});

Actions.addApartmentDecentralizedHousingApart.listen(function (data, uploads, additionFiles) {
  const url = common.api.Apartment.addApartmentDecentralizedHousingApart;
  const param = new FormData();
  if (uploads) {
    uploads.map((item) => {
      param.append('pics', item);
    });
  } else {
    param.append('pics', '');
  }

  if (additionFiles) {
    additionFiles.map(item => {
      param.append('files', item);
    });
  } else {
    param.append('files', '');
  }

  for (const obj in data) {
    if (typeof data[obj] == 'object') {
      param.append(obj, JSON.stringify(data[obj]));
    } else {
      param.append(obj, data[obj]);
    }
  }
  this.promise(common.ajaxPromise(url, param, 'POST', 'json', true, 'body'));
});
Actions.editApartmentDecentralizedHousingApart.listen(function (data, uploads, additionFiles) {
  const url = common.api.Apartment.editApartmentDecentralizedHousingApart;
  const param = new FormData();
  if (uploads) {
    uploads.map((item) => {
      param.append('pics', item);
    });
  } else {
    param.append('pics', '');
  }

  if (additionFiles) {
    additionFiles.map(item => {
      param.append('files', item);
    });
  } else {
    param.append('files', '');
  }

  for (const obj in data) {
    if (typeof data[obj] == 'object') {
      param.append(obj, JSON.stringify(data[obj]));
    } else {
      param.append(obj, data[obj]);
    }
  }
  this.promise(common.ajaxPromise(url, param, 'POST', 'json', true, 'body'));
});
Actions.deleteApartmentDecentralizedHousingApart.listen(function (param) {
  const url = common.api.Apartment.deleteApartmentDecentralizedHousingApart + param;
  this.promise(common.ajaxPromise(url, null, 'GET', 'json', true));
});
Actions.getDecentralizedPartPriceList.listen(function (id) {
  const url = common.api.Apartment.getPriceSchemeList + id;
  common.ajax(url, {}, 'GET', 'json', true, this.success, this.failed);
});
Actions.getDecentralizedHousePriceList.listen(function (id) {
  const url = common.api.Apartment.getPriceSchemeList + id;
  common.ajax(url, {}, 'GET', 'json', true, this.success, this.failed);
});

//分散式房间 科目列表
Actions.getDecentralizedHouseProductSubjectList.listen(function (params) {
  const url = common.api.Config.getProductSubject;
  common.ajax(url, params, 'POST', 'json', true, this.success, this.failed);
});
Actions.getDecentralizedPartProductSubjectList.listen(function (params) {
  const url = common.api.Config.getProductSubject;
  common.ajax(url, params, 'POST', 'json', true, this.success, this.failed);
});

Actions.modifyApartmentDecentralizedHousingRoomStatus.listen(function (param) {
  const url = common.api.Apartment.modifyApartmentDecentralizedHousingRoomStatus;
  this.promise(common.ajaxPromise(url, param, 'POST', 'json', false, 'body'));
});
Actions.getRoomOptionStatus.listen(function (param) {
  const url = common.api.Apartment.getRoomOptionalStatus;

  this.promise(common.ajaxPromise(url, param, 'POST', 'json', false));
});


//房源查询
Actions.getApartmentHousing.listen(function (cityId, countyId, keyWords) {
  const url = common.api.Apartment.getApartmentHousing;
  const param = {cityId: cityId, countyId: countyId, keyWords: keyWords};

  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});
Actions.getApartmentHousingDetail.listen(function (id) {
  const url = common.api.Apartment.getApartmentHousingDetail;
  const param = id;
  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});

Actions.getCentralizationEntireRoomOptionStatus.listen(function (param) {
  const url = common.api.Apartment.getRoomOptionalStatus;

  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});

Actions.getCentralizationPartRoomOptionStatus.listen(function (param) {
  const url = common.api.Apartment.getRoomOptionalStatus;

  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});

Actions.getBuildingInfo.listen(function (id) {
  const url = common.api.Apartment.getBuildingList + 'id/' + id;

  this.promise(common.ajaxPromise(url, {}, 'GET', 'json', false));
});

Actions.getDecentralizedEntireRoomOptionStatus.listen(function (param) {
  const url = common.api.Apartment.getRoomOptionalStatus;

  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});

Actions.getDecentralizedPartRoomOptionStatus.listen(function (param) {
  const url = common.api.Apartment.getRoomOptionalStatus;

  common.ajax(url, param, 'POST', 'json', true, this.success, this.failed);
});

Actions.makeProperty.listen(function (param) {
  const url = common.api.Apartment.makeProperty;
  this.promise(Util.ajaxPromise(url, param, 'POST', 'json', false, 'body'));
});

Actions.syncRoom.listen(function (param) {
  const url = common.api.Apartment.syncRoom;
  this.promise(Util.ajaxPromise(url, param, 'POST', 'json', false, 'body'));
});

export default Actions;
