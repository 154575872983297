/**
 * Created by neo on 2017/5/22.
 */

import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';

import util, {layComponent, getPropertyNavName} from '../util.js';

const create = PanelFactory.create;

export default function customer(app) {
  return (
    <Route name="客户"
      menuKey={107}
      path="/memberManage"
      component={create()}
      onEnter={util.requireAuth}>
      <IndexRoute onEnter={(nextState, replace) => replace('/memberManage/members/list')}/>
      <Route name="租客管理"
        path="/memberManage/members/(:action)"
        component={layComponent(() => import('../../memberManager/MemberList'))}
        onEnter={util.requireAuth}
        menuKey={107001}
        getDisplayName={getPropertyNavName('')}/>
      <Route name="黑名单"
        path="/memberManage/bmembers/(:action)"
        component={layComponent(() => import('../../memberManager/BlackMemberList'))}
        onEnter={util.requireAuth}
        menuKey={107002}
        getDisplayName={getPropertyNavName('')}/>
    </Route>
  )
}
