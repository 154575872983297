/**
 * Created by gjj on 2017/7/5.
 * 资产运营商的资产界面
 */

import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';

import util, {layComponent} from '../util.js';

const create = PanelFactory.create;

export default function assetOwner(app) {
  return (
    <Route
      name="资产"
      path="/assetOwner"
      component={create()}
      onEnter={util.requireAuth}
      menuKey={103}
    >
      <IndexRoute onEnter={(nextState, replace) => replace('/assetApartment/manage')}/>
      <Route name="资产管理"
        path="/assetOwner/manage"
        component={layComponent(() => import('../../AssetOwner/AssetManagement/AMList'))}
        onEnter={util.requireAuth}
        menuKey={103003}
      />
      <Route name="保养任务"
        path="/assetOwner/maint"
        component={layComponent(() => import('../../AssetOwner/Maintenance'))}
        onEnter={util.requireAuth}
        menuKey={103004}
      />
      <Route name="资产发布管理"
        path="/assetOwner/assetPublish"
        component={layComponent(() => import('../../AssetOwner/AssetPublish'))}
        onEnter={util.requireAuth}
        menuKey={103005}
      />
    </Route>
  )
}
