import React, { Component } from 'react';
import { Card } from '@unovo/antd';
import { MessageNew, Util, FormTable } from '@unovo/component';
import moment from 'moment';

//请求列表数据
const makeRequest = param => {
  param.loadingElementId = 'msg-table';
  return request.ajaxPromise(common.api.basic.getDownloadRecord, param, 'POST', 'json', false);
};

export default class DownLoad extends Component {
  constructor(props) {
    super(props);

    const staffInfo = common.getStaff();

    this.state = {
      customerId: staffInfo.id,
      data: [],
      dataTotalSize: 0,
    };
  }

  componentDidMount() {
    this.timer && clearInterval(this.timer);
    this.requestAction();
  }

  componentWillMount() {
    this.timer && clearInterval(this.timer);
  }

  requestAction = () => {
    makeRequest(this.getParams()).then(result => {
      if (result.errorCode == 0) {
        const list = result.data;
        this.setState({
          data: list.list,
          dataTotalSize: list.totalCount,
        });
        const unDoneData = list.list.filter(item => {
          return item.status === 1; // 导出未完成
        });
        if (unDoneData.length != 0 && !this.timer) {
          // 当有未完成的导出的时候建立个循环请求列表事件
          this.timer = setInterval(() => {
            this.requestAction();
          }, 3000);
        }
        if (unDoneData.length == 0 && this.timer) {
          //不存在的情况就删除这个请求循环事件
          clearInterval(this.timer);
        }
      }
    });
  };
  handleClick = value => {
    window.open(value);
  };

  columns = [
    {
      title: '文件名称',
      dataIndex: 'showName',
      width: '35%',
      align: 'left',
    },
    {
      title: '导出开始时间',
      dataIndex: 'createDate',
      width: '20%',
    },
    {
      title: '导出耗时',
      dataIndex: 'time',
      width: '15%',
      render: (val, row) => {
        if (row.completeDate && row.createDate) {
          return moment(moment(row.completeDate) - moment(row.createDate))
            .subtract(8, 'hours')
            .format('HH:mm:ss');
        }
      },
    },
    {
      title: '文件大小',
      dataIndex: 'fileSize',
      width: '10%',
    },
    {
      title: '操作',
      dataIndex: 'status',
      width: '20%',
      render: (val, row) => {
        if (val === 1) {
          return row.statusName;
        } else if (val === 2) {
          return <a onClick={this.handleClick.bind(this, row.showPath)}>下载</a>;
        } else {
          return '导出失败';
        }
      },
    },
  ];

  getParams = () => {
    return {
      customId: this.state.customerId,
    };
  };

  render() {
    const { data, dataTotalSize } = this.state;
    return (
      <Card
        title={
          <div>
            <span style={{ fontSize: '16px', marginRight: '10px' }}>下载内容</span>
            <span style={{ color: '#807d7d' }}>导出内容较多时需要稍作等待，若在列表中未显示您选择导出的内容，请稍后刷新页面再进行下载。</span>
          </div>
        }
      >
        <FormTable
          id="msg-table"
          columns={this.columns}
          rowKey="id"
          pagination={true}
          wrap={true}
          remote={false}
          data={data}
          fetchInfo={{
            dataTotalSize: dataTotalSize,
          }}
        />
      </Card>
    );
  }
}
