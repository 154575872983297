import React, { Component } from 'react';
import { Link } from 'react-router';
import { Breadcrumb, Popover, Icon } from 'antd';
import { MessageNew, Util } from 'component';
import Actions from '../../actions/actions';
/*import Actions from '../../../actions/actions.jsx';*/
import './style.less';
import RecentlyDownload from './RecentlyDownload';

const IgnoreRouteName = {
  wrapper: true,
  panel: true,
  indexroute: true,
  home: true,
};

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person: common.getPerson(),
      currentStaff: common.getStaff(),
      notices: '',
      display: 'none',
    };
  }

  componentWillReceiveProps(nextProps) {
    const updateState = {
      person: {},
      currentStaff: {},
    };

    if (nextProps.person) updateState.person = nextProps.person;

    if (nextProps.currentStaff) updateState.currentStaff = nextProps.currentStaff;

    this.setState(updateState);
  }

  itemRender = (route, params, routes, paths) => {
    const isLink = routes.indexOf(route) === 0;
    if (isLink) {
      return (
        <Link style={{ fontSize: '14px', color: '#b2b2b2' }} to={route.path}>
          {route.name}
        </Link>
      );
    }
    return <span style={{ fontSize: '14px', color: '#b2b2b2' }}>{route.name}</span>;
  };

  usefulRoutes = () => {
    const { routes = [] } = this.props;

    const result = [];

    const lastRoute = routes[routes.length - 1];

    routes.forEach(item => {
      if (item.name && !IgnoreRouteName[item.name.toLowerCase()]) {
        result.push(item);
      }
    });

    if (lastRoute.getDisplayName) {
      const targetName = lastRoute.getDisplayName();

      if (targetName) {
        result.push({
          name: targetName,
          path: '/detailName',
        });
      }
    }

    return result;
  };

  getUserImage = () => {
    const { person } = this.state;
    return person.headPicUrl ? person.headPicUrl : './static/img/avatar.png';
  };

  getOrgImage = () => {
    let defaultLogo = '';
    const { currentStaff } = this.state;

    if (currentStaff && currentStaff.orgPicUrl) {
      defaultLogo = currentStaff.orgPicUrl;
    }
    if (defaultLogo) {
      return (
        <div className="left-logo">
          <img id="favicon" src={defaultLogo} alt="logo" />
        </div>
      );
    } else {
      return this.renderSiteTitle();
    }
  };

  renderSiteTitle() {
    let title = '';
    const { currentStaff } = this.state;
    if (currentStaff) {
      title = currentStaff.orgShortName || currentStaff.orgName;
    }

    return (
      <a className="left-title" title={title}>
        {title}
      </a>
    );
  }

  signOut = e => {
    this.hideMenu();
    Actions.signout({}).then(result => {
      if (result.errorCode == 0) {
        MessageNew.success({ title: '退出成功!' }, () => {
          Util.push('/signin');
        });
      } else {
        MessageNew.error({ title: '操作失败!', content: result.message });
      }
    });
  };

  showMenu = e => {
    //未引入jquery，不能用one绑定事件，chrome下事件只执行一次，Firefox执行多次造成下拉未展示，故每次执行事件后，复写事件为空
    this.setState(
      (state, props) => {
        return { display: state.display == 'none' ? 'block' : 'none' };
      },
      function() {
        document.onclick = () => {
          this.hideMenu();
          document.onclick = (function() {}());
        };
      }
    );
  };

  hideMenu = () => {
    this.setState({ display: 'none' });
  };

  render() {
    const { person, notices, display, currentStaff } = this.state;
    const routes = this.usefulRoutes();

    return (
      <div className="main-top">
        {this.getOrgImage()}
        <div className="middle-breadcrumb">
          <Breadcrumb separator=">" routes={routes} itemRender={this.itemRender} />
        </div>
        <Popover placement="bottom" destroyTooltipOnHide={true} content={<RecentlyDownload customerId={currentStaff.id} />}>
          <div className="header-arrow-down" />
        </Popover>
        <div className="right-in-brief">
          <div className="in-brief-item top-nav-setting" />
          <div className="in-brief-item-2x" onClick={this.showMenu}>
            <div className="nameText">{person.name ? person.name : '匿名用户'}</div>
            <div className="in-brief-avatar-container">
              <img className="in-brief-avatar" size="40" src={this.getUserImage()} alt="头像" />
            </div>
          </div>
          <div className="in-brief-menu" style={{ display: display }}>
            <div className="in-brief-menu-top" />
            <div className="in-brief-wrap-item">
              <Link className="in-brief-item-2x" to="/zone/personal" onClick={this.hideMenu}>
                <div className="in-brief-menu-item" style={{ borderBottom: '1px solid #eee' }}>
                  个人中心
                </div>
              </Link>
              <a onClick={this.signOut}>
                <div className="in-brief-item-2x">
                  <div className="in-brief-menu-item">退出</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
