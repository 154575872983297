export default {
  checkFieldData: function (field, name, type) {
    if (field && name && type) {
      if (field[name]) {
        return field[name] && (field[name].toLowerCase() == type)
      }
    }
    return false
  }
}
