import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { PanelFactory } from 'component';

import util, { layComponent } from '../util';

const create = PanelFactory.create;

export default function operation(app) {
  return (
    <Route name="运营" path="/operation" component={create()} onEnter={util.requireAuth} menuKey={106}>
      <Route name="资源运营" path="/resource-operation" component={create()} onEnter={util.requireAuth} menuKey={106001}>
        <IndexRoute onEnter={(nextState, replace) => replace('/resource-operation/elecList/list')} />
        <Route
          name="电费运营"
          path="/resource-operation/elecList/(:action)"
          component={layComponent(() => import('../../ResourceOperation/Wrapper'))}
          onEnter={util.requireAuth}
          menuKey={106001001}
        />
        <Route
          name="抄表统计"
          path="/resource-operation/meter"
          component={layComponent(() => import('../../ResourceOperation/MeterReadingStatistics'))}
          onEnter={util.requireAuth}
          menuKey={106001002}
        />
      </Route>
      <Route name="资产运营" path="/assetOpert" component={create()} onEnter={util.requireAuth} menuKey={106002}>
        <IndexRoute onEnter={(nextState, replace) => replace('/assetOpert/assetList')} />
        <Route
          name="资产运营"
          path="/assetOpert/assetList"
          component={layComponent(() => import('../../AssetOperation/AssetManage/List'))}
          onEnter={util.requireAuth}
          menuKey={106002001}
        />
        <Route
          name="合同详情"
          path="/assetOpert/asset/cont/view"
          component={layComponent(() => import('../../AssetOperation/AssetManage/View'))}
          onEnter={util.requireAuth}
          menuKey=""
        />
        <Route
          name="已关联资产"
          path="/assetOpert/asset/cont/prop"
          component={layComponent(() => import('../../AssetOperation/AssetManage/Conn'))}
          onEnter={util.requireAuth}
          menuKey=""
        />
        <Route
          name="核算分利"
          path="/assetOpert/asset/cont/profit"
          component={layComponent(() => import('../../AssetOperation/Profit'))}
          onEnter={util.requireAuth}
          menuKey={106}
        />
      </Route>
      <Route name="客户运营" path="/message" component={create()} onEnter={util.requireAuth} menuKey={106005}>
        <IndexRoute onEnter={(nextState, replace) => replace('/message/msg')} />
        <Route
          name="消息查询"
          path="/message/msg"
          component={layComponent(() => import('../../Message/Message/Message'))}
          onEnter={util.requireAuth}
          menuKey={106005001}
        />
        <Route
          name="通知查询"
          path="/message/notification"
          component={layComponent(() => import('../../Message/Notification/Notification'))}
          onEnter={util.requireAuth}
          menuKey={106005002}
        />
        <Route
          name="发送通知"
          path="/message/sendMsg"
          component={layComponent(() => import('../../Message/SendMsg/SendMsg'))}
          onEnter={util.requireAuth}
          menuKey={106005003}
        />
      </Route>
      <Route name="网络发布" path="/room-hub" component={create()} onEnter={util.requireAuth} menuKey={106004}>
        <IndexRoute onEnter={(nextState, replace) => replace('/room-hub/distribute/')} />

        <Route
          name="看房热线"
          path="hotline/(:action)"
          component={layComponent(() => import('../../RoomHub/HotLine/HotLine'))}
          onEnter={util.requireAuth}
          menuKey={106004001}
        />
        <Route
          name="发布管理"
          path="distribute/(:action)"
          component={layComponent(() => import('../../RoomHub/DistributeManage/DistributeManage'))}
          onEnter={util.requireAuth}
          menuKey={106004002}
        />
        <Route
          name="渠道审批查询"
          path="audit/(:action)"
          component={layComponent(() => import('../../RoomHub/AuditQuery/AuditManage'))}
          onEnter={util.requireAuth}
          menuKey={106004004}
        />
      </Route>
    </Route>
  );
}
