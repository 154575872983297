import * as utils from '../../utils/util';
import Dict from '../../config/dict';

const ancestorKeys = {};

const assetApartment = {
  moduleId: 3,
  moduleCode: '103',
  moduleName: '资产',
  menu: [
    {
      menuId: 8,
      menuCode: '103001',
      menuName: '资产管理',
      function: [
        {
          funId: 16,
          funCode: '103001001',
          funName: '资产管理',
          funType: '菜单'
        }
      ]
    },
    {
      menuId: 9,
      menuCode: '103002',
      menuName: '选配资产发布',
      function: [
        {
          funId: 17,
          funCode: '103002001',
          funName: '选配资产发布',
          funType: '菜单'
        }
      ]
    }
  ]
};

const assetOwner = {
  moduleId: 3,
  moduleCode: '103',
  moduleName: '资产',
  menu: [
    {
      menuId: 8,
      menuCode: '103003',
      menuName: '资产管理',
      function: []
    },
    {
      menuId: 8,
      menuCode: '103004',
      menuName: '保养任务',
      function: []
    },
    {
      menuId: 9,
      menuCode: '103005',
      menuName: '资产发布管理',
      function: []
    }
  ]
};

const anotherReportData = {
  children: [
    {
      function: [],
      menuId: 51,
      menuCode: '111003',
      menuName: '电表监控',
    }
  ],
  data: {
    name: '报表',
    icon: 'report',
    key: '111',
    path: '/report',
    children: [
      {
        key: '111003',
        name: '电表监控',
        children: [],
        leaf: true,
        path: '/report/ReportMonitoring'
      }
    ],
  }
}

// eslint-disable-next-line consistent-return
function makeMenu(menu, key) {
  if (key === '103') {
    // 电费运营商显示的时候会图标为空
    if (utils.isApartmentOrg(Dict.OrgTypes.Apartment)) {
      return assetApartment.menu;
    } else if (utils.isAssetOrg(Dict.OrgTypes.Asset)) {
      return assetOwner.menu;
    } else if (utils.isPropertyOrg(Dict.OrgTypes.Property)) {
      return assetApartment.menu;
    }
  } else if (key === '111') {
    if (utils.isPropertyOrg(Dict.OrgTypes.Property)) {
      Array.prototype.push.apply(anotherReportData.children, menu)
      return anotherReportData.children
    } else {
      return menu
    }
  } else {
    return menu;
  }
}

function isLeaf(localItem, item, childKey) {
  if (localItem.leaf) {
    return true;
  } else if (!localItem.icon && item[childKey].length === 0) {
    return true;
  }
  return false;
}

function getDictData(key) {
  return Dict.MenuConfig[key];
}

// 获取页面
function getFunctions(func, parentKey) {
  const result = [];

  func.forEach(item => {
    ancestorKeys[item.funCode] = [parentKey];

    const matchDict = getDictData(item.funCode);

    if (matchDict) {
      result.push({
        name: item.funName,
        key: item.funCode,
        path: matchDict.path,
        children: [],
        leaf: true,
      })
    }
  });

  return result;
}

// 获取菜单
// 针对103 资产特殊处理
function getMenus(menu, parentKey) {
  const result = [];

  const finalMenu = makeMenu(menu, parentKey);

  finalMenu && finalMenu.forEach(item => {
    ancestorKeys[item.menuCode] = [parentKey];

    const matchDict = getDictData(item.menuCode);

    if (matchDict) {
      result.push({
        name: item.menuName,
        key: item.menuCode,
        path: matchDict.path,
        children: matchDict.leaf ? [] : getFunctions(item.function, item.menuCode),
        leaf: isLeaf(matchDict, item, 'function'),
      });
    }
  });

  return result;
}

// 获取模块
function getModules(data) {
  const result = [];

  data.forEach(item => {
    if (item.show !== false) {
      const module = getDictData(item.moduleCode);

      if (module) {
        result.push({
          name: item.moduleName,
          key: item.moduleCode,
          icon: module.icon,
          path: module.path,
          children: getMenus(item.menu, item.moduleCode),
        });
      }
    }
  });

  return result;
}

function getMenuData(menuData) {
  const result = getModules(menuData);
  const home = {
    name: '首页',
    key: 'home',
    icon: 'home',
    path: '/home',
    leaf: true,
    children: [],
    btnList: []
  };

  // 针对首页 判断是否有财务权限
  const hasFinance = result.some(item => {
    return item.key === Dict.ModuleDict.Finance;
  });

  if (hasFinance) {
    home.btnList.push({
      funCode: 'TodoList',
      funName: '我的待办',
      funType: '菜单'
    })
  }

  result.unshift(home);

  const checkReport = result.find(a => {
    return a.key === '111'
  })

  if (!checkReport && utils.isPropertyOrg(Dict.OrgTypes.Property)) {
    result.push(anotherReportData.data)
  }

  return {menuResult: result, ancestorKeys};
}

export default {
  getMenuData
}
