import React from 'react';
import { notification, Tabs } from 'antd';
import Footer from './Footer';
import Login from './Login';
import Register from './Register';
import GetBack from './GetBack';
import config from '../../../config/config';
import SigninStore from '../../../stores/signinStore';
import LocalUtil from '../../../components/Util';

import './style.css';
import './img-slide.css';

const TabPane = Tabs.TabPane;

export default class Signin extends React.Component {
  constructor(props) {
    super(props);

    const state = { ...SigninStore.getDefaultState() };

    this.state = state;
  }

  onStatusChange = data => {
    this.setState(data);
  };

  componentWillMount() {
    const cookieName = common.baseInfo.cookieName;
    common.signOut(cookieName);
    SigninStore.clearSignin();

    // 清除menu缓存

    sessionStorage.removeItem('openKeys');
    sessionStorage.removeItem('selectedKeys');
  }

  componentWillUnmount() {
    this.unsubscribe();
    notification.close(config.deployKey);
  }

  componentDidMount() {
    common.checkIsExit = true;
    this.unsubscribe = SigninStore.listen(this.onStatusChange);
    let h = document.body.clientHeight - 130;
    if (h < 831) {
      h = 831;
    }
    document.getElementsByClassName('slideshow-image')[0].style.height = h + 'px';
    document.getElementsByClassName('footer')[0].style.top = h + 'px';

    LocalUtil.switchBody('app-signin');
  }

  tabClick = index => {
    this.setState({ indexTable: index });
  };

  renderInput = () => {
    if (this.state.inputType != 'getBack') {
      return (
        <Tabs activeKey={this.state.indexTable || '0'} onTabClick={this.tabClick} destroyInactiveTabPane>
          <TabPane tab="登录" key="0">
            <Login changeType={this.onStatusChange} />
          </TabPane>
          <TabPane tab="注册" key="1">
            <Register changeType={this.onStatusChange} />
          </TabPane>
        </Tabs>
      );
    }
    return (
      <Tabs className="back-password">
        <TabPane tab="密码找回" key="0">
          <GetBack changeType={this.onStatusChange} />
        </TabPane>
      </Tabs>
    );
  };

  render() {
    return (
      <div id="signin">
        <div className="slideshow">
          <div className="slideshow-image" />
          <div className="signin-container">
            <div className="singin-logo" />
            <div className="wrap-input">
              <div className="input-type" style={{ marginTop: '50px' }}>
                {this.renderInput()}
              </div>
            </div>
          </div>
          <Footer showNotification={this.state.showNotification || false} />
        </div>
      </div>
    );
  }
}
