import assets from './assets';
import assetOwner from './assetOwner';
import configuration from './configuration';
import customer from './customer';
import employee from './employee';
import equipment from './equipment';
import financial from './financial';
// import operation from './operation';
import property from './property';
import operation from './operation';
// import property from './property';
import report from './report';
import room from './room';
import service from './service';
import zone from './zone';
import risk from './risk';

export default {
  assets,
  assetOwner,
  configuration,
  customer,
  employee,
  equipment,
  financial,
  // operation,
  property,
  operation,
  // property,
  report,
  room,
  service,
  zone,
  risk,
};
