import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';

import util, {layComponent, getNameByQueryString} from '../util.js';

const create = PanelFactory.create;

export default function employee(app) {
  return (
    <Route name="员工"
      path="/organization"
      component={create()}
      onEnter={util.requireAuth}
      menuKey="109"
    >
      <IndexRoute onEnter={(nextState, replace) => replace('/organization/department')}/>
      <Route name="部门管理"
        path="/organization/department"
        component={layComponent(() => import('../../Organization/Department/Department'))}
        onEnter={util.requireAuth}
        menuKey="109001"
      />
      <Route name="岗位管理"
        path="/organization/post"
        component={layComponent(() => import('../../Organization/Post'))}
        onEnter={util.requireAuth}
        menuKey="109002"
      />
      <Route name="雇员管理"
        path="/organization/employee/(:action)"
        component={layComponent(() => import('../../Organization/Employee'))}
        onEnter={util.requireAuth}
        menuKey="109003"
      />
    </Route>
  )
}
