export default {
  106: { name: '运营', path: '/resource-operation/elecList/', icon: 'operation' },
  // 106001: { name: '资源运营', path: '/resource-operation/elecList/' },
  106001001: { name: '电费运营', path: '/resource-operation/elecList/' },
  106001002: { name: '抄表统计', path: '/resource-operation/meter' },
  // 106002: { name: '资产运营', path: '/assetOpert/assetList', leaf: 'true' },
  // "106003": {"name": "广告运营", "path": ""},
  // 106002001: { name: '资产运营', path: '/assetOpert/assetList' },
  // "106003001": {"name": "广告投放", "path": ""},
  106005: { name: '客户运营', path: '/message/msg' },
  106005001: { name: '消息查询', path: '/message/msg' },
  106005002: { name: '通知查询', path: '/message/notification' },
  106005003: { name: '发送通知', path: '/message/sendMsg' },
  106004: { name: '网路发布', path: '/room-hub' },
  106004001: { name: '看房热线', path: '/room-hub/hotline/list' },
  106004002: { name: '发布管理', path: '/room-hub/distribute/' },
  106004004: { name: '渠道审批查询', path: '/room-hub/audit/' },
};
