import AssetManage from './asset-manage';
import FinanceSubject from './FinanceSubject';
import MenuConfig from './modules';
import ModuleDict from './modules-dict';
import ResourcePrice from './resource-price';
import global from './global';

export default {
  AssetManage: AssetManage,
  FinanceSubject: FinanceSubject,
  MenuConfig,
  ModuleDict,
  ResourcePrice,
  global
}
