import React, {Component} from 'react';

import {MessageNew} from 'component';
import './pendingStyle.css';
import BlockMe from './Approval/BlockMe'

function getPending(params) {
  return common.ajaxPromise(common.api.Finance.Todo.getTodoListURL, params, 'POST', 'json', false, 'todo-wrap');
}

export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    }
  }

  componentDidMount() {
    this.getHistoryList();
  }

  getHistoryList() {
    const {orgId, staffId} = common.getStaff()
    const params = {
      isPage: 1,
      page: 1,
      pageSize: 10,
      searchType: 1,
      searchState: 0,
      orgId: orgId,
      staffId: staffId,
      businessType: Dict.FLOW_BUSINESS_TYPE.PROPERTY_CONTRACT
    }
    getPending(params)
      .then((result) => {
        if (result.errorCode == 0) {
          this.setState({list: result.data.list})
        } else {
          MessageNew.error({
            title: result.message
          });
        }
      }, (err) => {
        MessageNew.error({title: Dict.Messages.content['10001']});
      })
  }

  getList(list) {
    return list.map((item) => {
      return (
        <div key={item.taskId} className="div-item clearfix">
          <span className="span-he">{item.taskTypeDesc}</span>
          <div className="span-she">{item.taskTitle}</div>
          <div className="span-task"><BlockMe key={item.taskId} scope="table" data={item.taskBody.elements[0]}/></div>
        </div>
      )
    })
  }

  render() {
    const list = this.state.list;
    return (
      <div className="div-all">
        <div className="div-item clearfix">
          <span className="span-he">业务合同</span>
          <div className="span-she">合同主体</div>
          <div className="span-task">任务描述</div>
        </div>
        {this.getList(list)}
      </div>
    )
  }
}
