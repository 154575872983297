import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';
import util, {layComponent, getNameByQueryString} from '../util';

const create = PanelFactory.create;

export default function room(app) {
  return (
    <Route
      name="客房"
      menuKey={101}
      path="/room"
      component={create()}
      onEnter={util.requireAuth}
    >
      <IndexRoute onEnter={(nextState, replace) => replace('/housekeeper/bookingManagement')}/>
      <Route
        name="客房管理"
        menuKey={101002}
        path="/apartment"
        component={create()}
        onEnter={util.requireAuth}
      >
        <IndexRoute onEnter={(nextState, replace) => replace('/apartment/centeralization/list')}/>
        <Route
          name="集中式公寓"
          menuKey={101002001}
          path="/apartment/centeralization/(:action)"
          component={layComponent(() => import('../../apartment/Centeralization/centeralization'))}
          onEnter={util.requireAuth}
        />
        <Route
          name="分散式公寓"
          menuKey={101002002}
          path="/apartment/decentralized"
          component={layComponent(() => import('../../apartment/Decentralized/decentralized'))}
          onEnter={util.requireAuth}
        >
          <Route
            name="客房总览"
            menuKey=""
            path="/apartment/decentralized/configuration"
            component={layComponent(() => import('../../apartment/Decentralized/decentralizedConfiguration'))}
            onEnter={util.requireAuth}
          />
          <Route
            name="管理中心"
            menuKey=""
            path="/apartment/decentralized/management"
            component={layComponent(() => import('../../apartment/Decentralized/decentralizedManagement'))}
            onEnter={util.requireAuth}
          />
          <Route
            name="配置客房"
            menuKey=""
            path="/apartment/decentralized/housing"
            component={layComponent(() => import('../../apartment/Decentralized/decentralizedHousing'))}
            onEnter={util.requireAuth}
          />
        </Route>
      </Route>
      <Route
        name="客房动态"
        menuKey={101001}
        path="/housekeeper"
        component={create()}
      >
        <IndexRoute onEnter={(nextState, replace) => replace('/housekeeper/bookingManagement')}/>
        <Route
          name="租房动态"
          menuKey={101001001}
          path="/housekeeper/bookingManagement"
          component={layComponent(() => import('../../HouseKeeper/BookingManagement/BookingManagement'))}
          onEnter={util.requireAuth}
        />
        <Route
          name="实时房态"
          menuKey={101001002}
          path="/housekeeper/roomStatus/(:action)"
          component={layComponent(() => import('../../HouseKeeper/RoomStatus/RoomIndex'))}
          onEnter={util.requireAuth}
          getDisplayName={getNameByQueryString('dpn')}
        />
        <Route
          name="远期房态"
          menuKey={101001003}
          path="/housekeeper/roomStatusDiagram"
          component={layComponent(() => import('../../HouseKeeper/RoomStatusDiagram/RoomStatusDiagram'))}
          onEnter={util.requireAuth}
        />
        <Route
          name="即将入住"
          menuKey={101001004}
          path="/housekeeper/recentCheckin"
          component={layComponent(() => import('../../HouseKeeper/RecentCheckin/RecentCheckin'))}
          onEnter={util.requireAuth}
        />
        <Route
          name="即将退房"
          menuKey={101001005}
          path="/housekeeper/recentCheckout"
          component={layComponent(() => import('../../HouseKeeper/RecentCheckout/RecentCheckout'))}
          onEnter={util.requireAuth}
        />
        <Route
          name="入住记录"
          menuKey={101001006}
          path="/housekeeper/room-history"
          component={layComponent(() => import('../../HouseKeeper/RoomHistory'))}
          onEnter={util.requireAuth}
        />
        <Route
          name="空置客房"
          menuKey={101001007}
          path="/housekeeper/vacantRoom"
          component={layComponent(() => import('../../HouseKeeper/VacantRoom/VacantRoom'))}
          onEnter={util.requireAuth}
        />
        <Route
          name="到期客房"
          menuKey={101001008}
          path="/housekeeper/room-overdue"
          component={layComponent(() => import('../../HouseKeeper/RoomOverdue'))}
          onEnter={util.requireAuth}
        />
      </Route>
      <Route
        name="客房合同"
        menuKey="101003"
        path="/memberManage/contract/(:action)"
        component={layComponent(() => import('../../apartment/Contract/Contract'))}
        onEnter={util.requireAuth}
      />
      <Route
        name="客房白名单"
        path="/apartment/roomwhitelist"
        component={layComponent(() => import('../../apartment/RoomWhiteList'))}
        onEnter={util.requireAuth}
      />
      <Route
        name="欠费策略"
        path="/apartment/arrears"
        component={layComponent(() => import('../../apartment/Arrears'))}
        onEnter={util.requireAuth}
      />
    </Route>
  )
}
