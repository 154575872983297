//服务
export default {
  108: {
    name: '服务',
    path: '/service',
    icon: 'service',
  },
  108001: {
    name: '服务动态',
    path: '/service/dynamic',
    leaf: true,
  },
  108002: {
    name: '服务管理',
    path: '/service/management',
    leaf: true,
  },
  108003: {
    name: '评价管理',
    path: '/service/evaluation',
    leaf: true,
  },
  108004: {
    name: '用户反馈',
    path: '/service/compAndSugg',
    leaf: true,
  },
  108001001: {
    name: '服务动态',
    path: ''
  },
  108002002: {
    name: '服务管理',
    path: ''
  },
  108003003: {
    name: '评价管理',
    path: ''
  },
  108004004: {
    name: '用户反馈',
    path: ''
  }
}
