import React from 'react';
import {Menu} from 'antd';
import {Link} from 'react-router';
import './style.less';


const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;
const OPEN_CLOSE_ICONS = {
  plus: 'minus',
  minus: 'plus'
};

function MenuIcon(props) {
  return <span className={`menu-module menu-module-${props.type}`} />
}

export default class FoldMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    }
  }

  renderDynamicMenu = (menus = [], moduleCount = 0) => {
    const result = [];
    let menuCount = moduleCount;

    menus.forEach((item, index) => {
      if (item.leaf) {
        menuCount++;

        result.push(
          <MenuItem key={item.key}>
            <Link activeClassName="menu-active" to={item.path}>
              {item.icon ? <MenuIcon type={item.icon} /> : null}
              <span className="nav-text">
                {item.name}
              </span>
            </Link>
          </MenuItem>
        )
      } else {
        menuCount++;
        if (item.children && item.key.split('').length < 6) {
          result.push(
            <SubMenu
              count={menuCount}
              key={item.key}
              title={(
                <span>
                  {item.icon ? <MenuIcon type={item.icon} /> : null}
                  <span className="nav-text">
                    {item.name}
                  </span>
                </span>
              )}
            >
              {this.renderDynamicMenu(item.children, menuCount)}
            </SubMenu>
          )
        }
        if (item.key && item.key.length === 6) {
          result.push(
            <MenuItem key={item.key}>
              <span>
                {item.icon ? <MenuIcon type={item.icon} /> : null}
                <span className="nav-text">
                  {item.name}
                </span>
              </span>
            </MenuItem>
          )
        }
      }
    });

    return result
  };

  getNewMenus = (targetKey, menus) => {
    return menus.map(item => {
      /*eslint no-param-reassign: "off" */

      if (OPEN_CLOSE_ICONS[item.icon]) { // 需要变化的范围
        item.icon = OPEN_CLOSE_ICONS.minus; // 初始化 全是加号
        if (item.key == targetKey) {
          item.icon = OPEN_CLOSE_ICONS.plus; // 如果是展开状态，则为减号
        }
      } else if (item.children) {
        item.children = this.getNewMenus(targetKey, item.children)
      }

      return item
    })
  };

  getAncestorKeys = (key) => {
    const {ancestorKeys} = this.props;

    return ancestorKeys[key] || [];
  };

  onMenuSelect = (obj) => {
    const {onMenuSelect} = this.props;
    const ancestoryKey = this.getAncestorKeys(obj.key);
    onMenuSelect(obj, ancestoryKey);
  };


  render() {
    const {menuData, selectedKeys} = this.props;
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={selectedKeys}
        onSelect={this.onMenuSelect}
        inlineCollapsed
      >
        {this.renderDynamicMenu(menuData)}
      </Menu>
    )
  }
}
