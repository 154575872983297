import React, {Component} from 'react'
import {Breadcrumb, Icon, Badge, Spin} from 'antd'
import {MessageNew, Util} from 'component';
import moment from 'moment';
import './recentlyDownload.less'

function makeRequest(params) {
  return request.ajaxPromise(common.api.basic.getDownloadRecord, params, 'POST', 'json', false)
}

export default class RecentlyDownload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.getTopFiveDownload();
  }

  getTopFiveDownload() {
    if (this.props.customerId) {
      this.setState({
        loading: true,
      })
      makeRequest({
        customId: this.props.customerId,
        isPage: 1,
        page: 1,
        pageSize: 5,
      })
        .then(result => {
          if (result.errorCode === 0 && result.data && result.data.list) {
            this.setState({
              list: result.data.list,
              loading: false,
            })
          }
        }, err => {
          this.setState({
            list: [],
            loading: false,
          })
        })
    }
  }

  handleMoreClick() {
    Util.push('/download')
  }

  renderList(list) {
    if (this.state.loading) {
      return <li style={{textAlign: 'center'}}><Spin/></li>
    } else {
      if (list.length) {
        return list.map(item =>
          (
            <li className="clearfix">
              <div className="fl" style={{width: '80%'}}>
                <div className="fileName">{item.showName}</div>
                <div className="clearfix sub-title">
                  <div className="fl">{item.createDate}</div>
                  <div className="fr">{item.fileSize}</div>
                </div>
              </div>
              <div className="fr download-btn"><a href={item.showPath} target="_blank" download={item.fileName}>下载</a>
              </div>
            </li>
          )
        )
      } else {
        return <li className="empty-download">暂无导出记录</li>;
      }
    }
  }

  render() {
    const {list} = this.state;
    return (
      <div className="recently-download-list">
        <ul>
          <li><h3>最近导出</h3></li>
          {
            this.renderList(list)
          }
          <li><a className="download-more-record" onClick={this.handleMoreClick}>更多导出记录</a></li>
        </ul>
      </div>
    )

  }
}
