/**
 * Created by neo on 2017/3/30.
 */

const dict = {};

//手续费
dict.Poundage = {
  ExchangeHouses: 1050000, //换房手续费
  Sublease: 1050100, //转租手续费
  Transfer: 1050200, //转账手续费
  Others: 1059900, //其他手续费
};

//违约金
dict.BreachOfContract = {
  Rent: 1060000, //租金违约金
  Others: 1069900, //其他违约金
};

//收支
dict.BudgeType = {
  In: 0,
  Out: 1,
};

//付款渠道-
dict.PayChannelLine = {
  LineTop: 0, //线上
  LineBottom: 1, //线下
};

//流水中清算状态
dict.ClearCountStatus = {
  NontBilled: 0, //无需清算
  StayBill: 1, //待清算
  Billed: 2, //已清算
  Billing: 3, //清算中
};
//流水中清算操作状态
dict.ClearOperatStatus = {
  BookOperateCreate: 0,
  BookOperateSuccess: 1,
  BookOperateFail: 2,
};

export default dict;
