//报表
export default {
  111: {
    name: '报表',
    path: '/report',
    icon: 'report',
  },
  111001: {
    name: '统计报表',
    path: '/report/staticReport',
  },
  111002: {
    name: '经营日报',
    path: '/report/operationDaily',
  },
  111003: {
    name: '电表监控',
    path: '/report/ReportMonitoring',
  },
  111001001: {
    name: '运营统计',
    path: '/report/staticReport/summary',
  },
  111001002: {
    name: '客房统计',
    path: '/report/staticReport/guestRoom',
  },
  111001003: {
    name: '财务统计',
    path: '/report/staticReport/finance',
  },
  111001004: {
    name: '会员统计',
    path: '/report/staticReport/member',
  },
  111001005: {
    name: '能耗统计',
    path: '/report/staticReport/energy',
  },
  // 111001006: {
  //   name: '热水统计',
  //   path: '/report/staticReport/hot'
  // },
  // 111001007: {
  //   name: '冷水统计',
  //   path: '/report/staticReport/cold'
  // },
  111002001: {
    name: '运营汇总',
    path: '/report/operationDaily/summary',
  },
  111002002: {
    name: '账本流水',
    path: '/report/operationDaily/bookJournal',
  },
  111002003: {
    name: '账单管理',
    path: '/report/operationDaily/billManagement',
  },
  111002004: {
    name: '平台结算',
    path: '/report/operationDaily/platformCheckout',
  },
  111002005: {
    name: '空置客房',
    path: '/report/operationDaily/vacantRoom',
  },
  111002006: {
    name: '服务详情',
    path: '/report/operationDaily/serviceDetails',
  },
  111002007: {
    name: '投诉建议',
    path: '/report/operationDaily/suggestion',
  },
};
