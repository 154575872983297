export default {
  // 103: {name: '资产', path: '/assetApartment/manage', icon: 'assets'},
  103001: {name: '资产管理', path: '/assetApartment/manage', leaf: 'true'},
  103002: {name: '选配资产', path: '/assetApartment/assetPublish', leaf: 'true'},
  103001001: {name: '资产管理', path: '/assetApartment/manage'},
  103002001: {name: '选配资产', path: '/assetApartment/assetPublish'},

  103003: {name: '资产管理', path: '/assetOwner/manage', leaf: 'true'},
  103004: {name: '保养任务', path: '/assetOwner/maint', leaf: 'true'},
  103005: {name: '资产发布管理', path: '/assetOwner/assetPublish', leaf: 'true'},
}
