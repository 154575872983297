/* eslint-disable max-len  */
import React, { Component } from 'react';
import { Card, Icon } from 'antd';
import { Util } from 'component';
import './style.css';
import Pending from './Pending';
import History from './History';

import LocalUtil from '../../components/Util';

export default class Home extends Component {
  onClick() {
    Util.push('/financial/todo');
  }

  componentDidMount() {
    this.pageDisabled(this.props.location.query);
    LocalUtil.switchBody('app-home');
  }

  componentWillUnmount() {
    LocalUtil.switchBody('app-home', true);
  }

  pageDisabled = idData => {
    window.localStorage.idData = JSON.stringify(idData);
    window.addEventListener('storage', e => {
      if (e.key == 'idData') {
        const node = document.createElement('div');
        node.id = 'pageDisabled';
        document.body.appendChild(node);
        const element = document.getElementById('pageDisabled');

        // eslint-disable-next-line operator-linebreak
        element.innerHTML =
          '<div><div data-reactroot=""><div class="ant-modal-mask"></div><div tabindex="-1" class="ant-modal-wrap " role="dialog"><div role="document" class="ant-modal ant-confirm ant-confirm-confirm" style="width: 320px;"><div class="ant-modal-content"><button aria-label="Close" class="ant-modal-close"><span class="ant-modal-close-x"></span></button><div class="ant-modal-body"><div class="ant-confirm-body-wrapper"><div class="ant-confirm-body"><i class="anticon anticon-exclamation-circle"></i><span class="ant-confirm-title">当前用户信息已失效！</span></div></div></div></div><div tabindex="0" style="width: 0px; height: 0px; overflow: hidden;">sentinel</div></div></div></div></div>';
      }
    });
  };

  getPending(fTodoList) {
    if (fTodoList) {
      return (
        <div id="todo-wrap" className="historyInfo">
          <Card
            title="我的待办"
            extra={
              <a style={{ color: '#a5acb1' }} onClick={this.onClick}>
                <Icon type="right" />
              </a>
            }
            style={{ width: '100%', border: 'none' }}
          >
            <Pending />
          </Card>
        </div>
      );
    } else {
      return (
        <Card title="我的待办" style={{ width: '100%', border: 'none' }}>
          <div className="pendingInfo">
            <div>未配置岗位职责</div>
          </div>
        </Card>
      );
    }
  }

  render() {
    const { menuResult = [], route } = this.props;

    // todo 检查权限
    const allowPlace = LocalUtil.getShowPlace(menuResult, route.mKey);
    // 用于判断是否有我的待办权限 fTodoList==true 说明有待办的权限
    const { fTodoList = false } = allowPlace;

    return (
      <div className="home-info">
        {/*<div style={{textAlign: 'center', background: "white", borderRadius: "3px"}}>*/}
        {/*<h3 className="SummaryTop">当月运营汇总</h3>*/}
        {/*<Summary/>*/}
        {/*</div>*/}
        <div style={{ overflow: 'hidden' }}>
          <div style={{ width: '100%', float: 'left' }}>{this.getPending(fTodoList)}</div>
        </div>
        <div className="bottomNav">
          <div style={{ width: '31.9%', float: 'left', display: 'inline-block' }}>
            <img src="static/img/home/feature1.png" alt="" />
          </div>
          <div style={{ width: '31.9%', display: 'inline-block' }}>
            <img src="static/img/home/feature2.png" alt="" />
          </div>
          <div style={{ width: '31.9%', float: 'right', display: 'inline-block' }}>
            <img src="static/img/home/feature3.png" alt="" />
          </div>
        </div>
      </div>
    );
  }
}
