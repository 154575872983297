const Environment = {
  production: {
    UA: 'Apartment',
    versionId: '134',
    baseUrl: window.location.protocol + '//' + window.location.host,
    baseDir: window.location.protocol + '//' + window.location.host + '/upms',
  },
  hot: {
    UA: 'Apartment',
    versionId: '241',
    baseUrl: window.location.protocol + '//' + window.location.host,
    baseDir: window.location.protocol + '//' + window.location.host + '/upms',
  },
};

function makeEnv() {
  if (process.env.NODE_ENV === 'production') {
    return {...Environment.production}
  }
  return {...Environment.hot}
}

export default makeEnv()
