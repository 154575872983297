/**
 * Created by neo on 2017/8/16.
 * 公寓运营商的资产界面
 */

import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';

import util, {layComponent} from '../util.js';

const create = PanelFactory.create;
export default function assets(app) {
  return (
    <Route
      name="资产"
      path="/assetApartment"
      component={create()}
      onEnter={util.requireAuth}
      menuKey={103}
    >
      <IndexRoute onEnter={(nextState, replace) => replace('/assetApartment/manage')}/>
      <Route
        name="资产管理"
        path="/assetApartment/manage"
        component={layComponent(() => import('../../AssetApartment/AssetManagement/AMList'))}
        onEnter={util.requireAuth}
        menuKey={103001}
      />
      <Route
        name="选配资产"
        path="/assetApartment/assetPublish"
        component={layComponent(() => import('../../AssetApartment/AssetPublish'))}
        onEnter={util.requireAuth}
        menuKey={103002}
      />
    </Route>
  )
}
