import React from 'react';
import PropTypes from 'prop-types';
import {MessageNew} from 'component';
import Menu from '../components/Menu';
import MenuUtil from '../components/Menu/util';
import Header from '../components/Header';
import request from '../utils/request';

import './BasicLayout.less';

class BasicLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    const initStates = {
      menuSelectedKeys: [],
      ancestorKeys: [],
      menuData: [],
      person: {},
      currentStaff: {},
      staffList: [],
    };

    initStates.person = common.getPerson();
    initStates.currentStaff = common.getStaff();
    initStates.staffList = common.getStaffList();

    this.state = initStates;
  }

  componentDidMount() {
    this.getMenuData();
  }

  getMenuData = () => {
    const staffId = common.getStaffId();
    request.ajaxPromise(common.api.basic.getMenuByStaffId, {
      staffId: staffId
    })
      .then(result => {
        if (result.errorCode == 0) {
          if (result.data && result.data.length == 0) {
            MessageNew.info({
              title: '请联系管理员配置岗位职责!'
            })
          }
          const menuResult = MenuUtil.getMenuData(result.data);
          this.setState({
            menuData: menuResult.menuResult,
            ancestorKeys: menuResult.ancestorKeys
          })
        } else {
          MessageNew.error({
            title: '获取菜单失败!'
          })
        }
      });
  }

  render() {
    const {children, routes} = this.props;

    const {menuData, ancestorKeys, currentStaff, person} = this.state;

    common.__location = this.props.location;

    return (
      <div className="basic-container">
        <Header
          routes={routes}
          person={person}
          currentStaff={currentStaff}
        />
        <Menu
          onChange={this.handleCollapse}
          menuData={menuData}
          routes={routes}
          ancestorKeys={ancestorKeys}
        >
          {children ? React.cloneElement(children, {menuResult: menuData}) : null}
        </Menu>
      </div>
    )
  }
}

BasicLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default BasicLayout
