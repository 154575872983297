/**
 * Created by neo on 16/5/27.
 */

module.exports = {
  date: '2019062701',
  version: '6.0.0',
  cookieName: 'intebox_sso_tkt',
  pageSize: 10,
  pageSize_25: 25,
  pageSize_50: 50,
  siteType: 'upms',
  deployKey: 'deployKey',
  expiryDay: 7,
};
