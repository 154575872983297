import React, { Component } from 'react';
import { MessageNew, Util } from 'component';

import DeployInfoModal from '../../components/DeployInfoModal';

function getHistory(params) {
  return common.ajaxPromise(common.api.history.getHistory, params, 'POST', 'json', false, 'history-wrap');
}

export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      deployment: [],
    };
  }

  componentDidMount() {
    this.getHistoryList();
  }

  getHistoryList() {
    const param = {
      appId: 10,
      pageSize: 10,
      isPage: 1,
      page: 1,
      orderBy: 'startTime desc',
    };
    getHistory(param).then(
      result => {
        if (result.errorCode == 0) {
          this.setState({ list: result.data.list });
        } else {
          MessageNew.error({
            title: result.message,
          });
        }
      },
      err => {
        MessageNew.error({ title: Dict.Messages.content['10001'] });
      }
    );
  }

  onhandleClick(id) {
    Util.ajaxPromise(common.api.getHistoryDetail, { versionId: id }, 'POST', 'json', false, 'body').then(
      result => {
        if (result.errorCode == 0 && result.data && result.data.infolist) {
          this.setState({ deployment: result.data });
        } else {
          MessageNew.error({
            title: '查询失败',
            content: result.message,
          });
          this.setState({ deployment: [] });
        }
      },
      error => {
        this.setState({ deployment: [] });
        MessageNew.error({
          title: Dict.Messages.title[10001],
          content: Dict.Messages.content[10001],
        });
      }
    );
    this.refs.update.open();
  }

  getList(list) {
    return list.map(item => {
      return (
        <div key={item.id} className="historyList" onClick={this.onhandleClick.bind(this, item.id)}>
          <span style={{ float: 'left' }}>{item.appName + ' 发布了更新日志'}</span>
          <span style={{ float: 'right' }}>{item.startTime.replace(/-/g, '').substring(0, 8)}</span>
        </div>
      );
    });
  }

  render() {
    const list = this.state.list;
    return (
      <div>
        <div style={{ height: '255px', overflow: 'auto', padding: '0 25px' }}>{this.getList(list)}</div>
        <DeployInfoModal ref="update" deployment={this.state.deployment} id="history-modal" />
      </div>
    );
  }
}
