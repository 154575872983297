export default {
  101: { name: '客房', path: '/room', icon: 'room' },
  101001: { name: '客房动态', path: '/housekeeper/bookingManagement' },
  101002: { name: '客房管理', path: '/apartment/centeralization/list' },
  101003: { name: '客房合同', path: '/memberManage/contract/', leaf: 'true' },
  101004: { name: '客房白名单', path: '/apartment/roomwhitelist', leaf: 'true' },
  101001001: { name: '租房动态', path: '/housekeeper/bookingManagement' },
  101001002: { name: '实时房态', path: '/housekeeper/roomStatus/' },
  101001003: { name: '远期房态', path: '/housekeeper/roomStatusDiagram' },
  101001004: { name: '即将入住', path: '/housekeeper/recentCheckin' },
  101001005: { name: '即将退房', path: '/housekeeper/recentCheckout' },
  101001006: { name: '入住记录', path: '/housekeeper/room-history' },
  101001007: { name: '空置客房', path: '/housekeeper/vacantRoom' },
  101001008: { name: '到期客房', path: '/housekeeper/room-overdue' },
  101002001: { name: '集中式公寓', path: '/apartment/centeralization/list' },
  // 101002002: { name: '分散式公寓', path: '/apartment/decentralized' },
  101003001: { name: '客房合同', path: '/memberManage/contract/' },
  101004001: { name: '客房白名单', path: '/apartment/roomwhitelist' },
};
