export default {
  // 102: {name: '物业', path: '/property/manage/', icon: 'property'},
  // 102001: {name: '物业管理', path: '/property/manage/', leaf: 'true'},
  // 102002: {name: '业主管理', path: '/property/homeOwner/', leaf: 'true'},
  // 102003: {name: '关联客房', path: '/property/houses', leaf: 'true'},
  // 102004: {name: '充值管理', path: '/property/recharge', leaf: 'true'},
  // 102005: {name: '租期开门设置', path: '/property/rentDoor/', leaf: 'true'},
  // 102001001: {name: '物业管理', path: '/property/manage/'},
  // 102002002: {name: '业主管理', path: '/property/homeOwner/'},
  // 102003003: {name: '关联客房', path: '/property/houses/'},
  // 102004004: {name: '充值管理', path: '/property/recharge/'},
  // 102005005: {name: '设置', path: '/property/rentDoor/setting'},
};
