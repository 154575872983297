import React, {Component} from 'react';
import {Button, Icon} from 'antd';
import classNames from 'classnames';
import {Util} from 'component';
import {Link} from '@unovo/react-router';
import {CSSTransitionGroup} from 'react-transition-group';

import FoldMenu from './FoldMenu';
import Footer from '../Footer';
import './style.less'

function MenuIcon(props) {
  const className = classNames('menu-module', `menu-module-${props.type}`);
  return <span className={className} />
}

export default class Menu extends Component {
  constructor(props) {
    super(props);

    const openKeys = this.getOpenKeys(props);

    this.time = null;

    this.state = {
      openKeys: openKeys, //展开的菜单key
      secondMenuData: [], //二级菜单数据
      isFold: false //菜单是否折叠
    }
  }

  componentWillReceiveProps(nextProps) {
    const openKeys = this.getOpenKeys(nextProps);

    this.setState({openKeys})
  }

  /**
   * 从顶层传过来的路由信息中获取菜单信息
   * @returns {Array} 已展开的菜单list
   */
  getOpenKeys = ({routes}) => {

    const result = [];
    routes.map((item) => {
      if (item.menuKey) { //处理下menuKey,去除undefined的情况
        result.push(item.menuKey.toString())
      }
    });
    return result;
  };

  /**
   * 根据父级菜单的key来获取子孙后代中第一个的key 和url
   * @param menuData 菜单元数据
   * @param parentKey 父级菜单key
   * @param resultKey 结果key集合
   * @returns {*} key 和url
   */
  getAllChildrenKey = (menuData, parentKey, resultKey = []) => {
    resultKey.push(parentKey);
    let targetUrl = '/home';
    for (let i = 0, len = menuData.length - 1; i <= len; i++) {
      const current = menuData[i];
      if (current.key === parentKey && current.children && current.children.length != 0) { //存在下一级的情况
        return this.getAllChildrenKey(current.children, current.children[0].key, resultKey)
      }
      if (current.key === parentKey && current.children && current.children.length == 0) { //最后一级
        targetUrl = current.path;
        return {
          openKeys: resultKey,
          targetUrl: targetUrl
        };
      }
    }
  };

  /**
   * 根据展开的一级， 二级，三级菜单key来获取展开的二级、三级菜单数据
   * @param firstKey 一级菜单key
   * @param secondKey 二级菜单key
   * @param thirdKey 三级菜单key
   * @param menuList 菜单元数据
   * @returns {{secondMenuList: Array, thirdMenuList: Array}} 展开的二级、三级菜单数据
   */
  getMenChildByKey(firstKey, secondKey, thirdKey, menuList) {
    const matchedMenu = menuList.filter(item => item.key == firstKey); //根据firstkey来获取二级菜单数据

    const childMenuObj = {
      secondMenuList: [],
      thirdMenuList: [],
    };

    if (matchedMenu.length && matchedMenu[0].children) {
      childMenuObj.secondMenuList = matchedMenu[0].children;

      //有子菜单
      if (secondKey && thirdKey && childMenuObj.secondMenuList.length) {
        const filterResult = childMenuObj.secondMenuList.filter(item => item.key == secondKey);

        if (filterResult.length && filterResult[0].children) {
          childMenuObj.thirdMenuList = filterResult[0].children;
        }
      }
    }
    return childMenuObj;
  }

  /**
   * 一级菜单mouse事件
   * @param secondMenuData 子集菜单原数据
   * @param activityKey 当前层级菜单key
   */
  firstMenuItemEnter = (secondMenuData = [], activityKey) => {
    clearTimeout(this.time);

    this.time = setTimeout(() => {
      this.setState({
        secondMenuData: secondMenuData
      })
    }, 300);
  };

  /**
   * 一级菜单click事件
   * @param secondMenuData 子集菜单原数据
   * @param activityKey 当前层级菜单数据
   */
  firstMenuItemClick = (secondMenuData = [], activityKey) => {
    const {menuData} = this.props;
    const target = this.getAllChildrenKey(menuData, activityKey);

    Util.push(target.targetUrl);
  };

  /**
   * 二级菜单click事件
   * @param menuData 子集菜单原数据
   */
  secondMenuItemClick = (menuItem) => {
    let targetUrl = '';

    if (menuItem.children && menuItem.children.length) {
      targetUrl = menuItem.children[0].path;
    } else if (menuItem.leaf) {
      // 最底层
      targetUrl = menuItem.path;
    }

    Util.push(targetUrl);
  };

  /**
   * 浮层二级菜单click事件，点击后将浮层菜单清空
   * @param menuData 子集菜单原数据
   */
  upperSecondMenuItemClick = (menuItem) => {
    let targetUrl = '';

    if (menuItem.children && menuItem.children.length) {
      targetUrl = menuItem.children[0].path;
    } else if (menuItem.leaf) {
      // 最底层
      targetUrl = menuItem.path;
    }

    this.setState({
      secondMenuData: []
    });
    Util.push(targetUrl);
  };

  onMenuLeave = () => {
    if (this.time)
      clearTimeout(this.time);
  }

  /**
   * 渲染一级菜单
   * @param menuData 菜单元数据
   * @returns {Array}
   */
  renderFirstMenu = (menuData = []) => {
    const result = [];
    const {openKeys} = this.state;
    menuData.forEach((item, index) => {
      if (item.leaf) { //叶子节点
        result.push(
          <Link
            // onMouseEnter={this.firstMenuItemEnter.bind(this, item.children, item.key)}
            key={item.key}
            activeClassName="first-menu-activity" className="menu-item" to={item.path}
          >
            {item.icon ? <MenuIcon type={item.icon} /> : null}
            <span className="nav-text">
               {item.name}
            </span>
          </Link>
        )
      } else { //非叶子节点
        const className = classNames('menu-item', {'first-menu-activity': openKeys.indexOf(item.key) != -1});
        result.push(
          <div
            key={item.key}
            className={className}
            // onMouseEnter={this.firstMenuItemEnter.bind(this, item.children, item.key)}
            // onMouseMove={this.firstMenuItemEnter.bind(this, item.children, item.key)}
            // onMouseLeave={this.onMenuLeave}
            onClick={this.firstMenuItemClick.bind(this, item.children, item.key)}
          >
            {item.icon ? <MenuIcon type={item.icon} /> : null}
            <span className="nav-text">
               {item.name}
            </span>
          </div>
        )
      }
    });

    return result
  };

  /**
   * 渲染二级菜单
   * @param menuData 二级菜单原数据
   * @returns {Array} 二级菜单dom
   */
  renderSecondMenu = (menuData = []) => {
    const result = [];
    const {secondMenuItemClick} = this;
    const {openKeys} = this.state;
    menuData.forEach((item) => {
      const className = classNames('second-menu-item', {'second-menu-activity': openKeys.indexOf(item.key) != -1});
      result.push(
        <div
          key={item.key}
          className={className}
          onClick={secondMenuItemClick.bind(this, item)}>
          {item.icon ? <MenuIcon type={item.icon} /> : null}
          <span className="nav-text">
            {item.name}
          </span>
        </div>
      )
    });
    return result
  };

  /**
   * 渲染二级浮层菜单
   * @param menuData 二级菜单原数据
   * @returns {Array} 二级菜单dom
   */
  renderUpperSecondMenu = (menuData = []) => {
    const result = [];
    const {upperSecondMenuItemClick} = this;
    const {openKeys} = this.state;
    menuData.forEach((item) => {
      const className = classNames('second-menu-item', {'second-menu-activity': openKeys.indexOf(item.key) != -1});
      result.push(
        <div
          key={item.key}
          className={className}
          onClick={upperSecondMenuItemClick.bind(this, item)}>
          {item.icon ? <MenuIcon type={item.icon} /> : null}
          <span className="nav-text">
            {item.name}
          </span>
        </div>
      )
    });
    return result
  };

  /**
   * 渲染三级菜单
   * @param menuData 三级菜单原数据
   * @returns {any[]} 三级菜单dom
   */
  renderThirdMenu = (menuData = []) => {
    const result = menuData.map(item => {
      return (
        <Link
          activeClassName="tabs-menu-item-active"
          className="tabs-menu-item"
          key={item.key}
          to={item.path}>
          {item.name}
        </Link>
      )
    });

    if (result.length > 0)
      return (
        <div className="main-tabs-button">
          {result}
        </div>
      )
    else
      return null;
  }

  /**
   * 菜单移出事件
   */
  mouseLeave = () => {
    this.setState({
      secondMenuData: []
    })
  };

  /**
   * 折叠菜单选择事件
   * @param obj
   * @param ancestoryKey
   */
  onFoldMenuSelect = (obj, ancestoryKey) => {
    if (obj.key === 'home') {
      this.setState({
        openKeys: obj.selectedKeys
      });
    } else {
      const currentKey = obj.key;
      const {menuData} = this.props;
      const matchedMenu = menuData.filter(item => item.key == ancestoryKey)[0].children; //根据firstkey来获取二级菜单数据
      const {
        targetUrl,
        openKeys
      } = this.getAllChildrenKey(matchedMenu, currentKey);

      openKeys.push(ancestoryKey);//加入第一层节点

      this.setState({
        openKeys: openKeys
      });
      Util.push(targetUrl);
    }
  };

  /**
   * 渲染左侧菜单
   * @returns {*}
   */
  renderLeftMenu = () => {
    const {openKeys, secondMenuData, isFold} = this.state;
    const [firstKey, secondKey, thirdKey] = openKeys;
    const {menuData, ancestorKeys} = this.props;
    const childMenuObj = this.getMenChildByKey(firstKey, secondKey, thirdKey, menuData);

    const trigger = isFold ? 'double-right' : 'double-left';

    if (isFold) {
      return (
        <div className="fold-menu-wrapper">
          <FoldMenu menuData={menuData}
                    selectedKeys={openKeys}
                    ancestorKeys={ancestorKeys}
                    onMenuSelect={this.onFoldMenuSelect}
          />
          <div className="menu-switch" onClick={this.switchMenu}>
            <Icon type={`${trigger}`} />
          </div>
        </div>
      )
    } else {
      return (
        <div className="expand-menu-wrapper">
          <div className="first-menu-wrapper">
            {this.renderFirstMenu(menuData)}
            <div className="menu-switch" onClick={this.switchMenu}>
              <Icon type={`${trigger}`} />
            </div>
          </div>
          <div className="second-menu-wrapper">
            {this.renderSecondMenu(childMenuObj.secondMenuList)}
          </div>
          <div className="upper-second-menu-wrapper">
            <CSSTransitionGroup
              transitionName="slide-left"
              transitionAppear={true}
              transitionEnterTimeout={300}
              transitionLeaveTimeout={0}
            >
              {this.renderUpperSecondMenu(secondMenuData)}
            </CSSTransitionGroup>
          </div>
        </div>
      )
    }
  };

  /**
   * 切换菜单
   */
  switchMenu = () => {
    this.setState({
      isFold: !this.state.isFold
    })
  };

  render() {
    const {openKeys: [firstKey, secondKey, thirdKey], isFold} = this.state;
    const {menuData, children} = this.props;
    const childMenuObj = this.getMenChildByKey(firstKey, secondKey, thirdKey, menuData);

    return (
      <div className="main-layout">
        <div className="main-layout-left-side" onMouseLeave={this.mouseLeave}>
          {this.renderLeftMenu()}
        </div>
        <div className="main-layout-right-side" id="main-content">
          {this.renderThirdMenu(childMenuObj.thirdMenuList)}
          <div style={{height: '100%'}}>
            {children ? React.cloneElement(children, {menuResult: menuData}) : null}
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}
