import React from 'react';
import Icon from 'react-fa';
import { Util, MessageNew } from 'component';

import Actions from '../../../actions/actions';

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      msg: '',
    };
  }

  componentDidMount() {
    this.isExtist = true;
    this.person.focus();
  }

  componentWillUnmount() {
    this.isExtist = false;
  }

  gtRegister = () => {
    this.props.changeType({ inputType: 'getBack' });
  };

  renderLoading = () => {
    if (this.state.loading) {
      return <Icon name="spinner" spin style={{ color: '#2DB7F5', marginRight: '10px' }} />;
    }
    return null;
  };

  handleKeyDown = e => {
    if (e.keyCode == 13) {
      this.submit();
    }
  };

  submit = () => {
    const person = this.person.value;
    const pwd = this.password.value;
    let msg = '';
    if (person.isEmpty() || pwd.isEmpty()) {
      this.setState({ msg: '请输入用户名和密码' });
    } else {
      this.setState({ loading: true });
      Actions.signin(person, pwd).then(
        result => {
          if (result.errorCode == 0 && result.data) {
            msg = '';
            if (result.data.staffList.length == 0) {
              msg = '未创建雇员, 请联系管理员!';
            } else if (result.data.staffList.length == 1) {
              // 特殊处理演示运营商跳转
              if (result.data.staffList[0].orgId === 104442) {
                Util.push('/housekeeper/roomStatus/list');
              } else {
                Util.push('/home', { id: result.data.person.id, staffId: result.data.staffList[0].id });
              }
            } else if (result.data.staffList.length > 1) {
              Util.push('/center', { id: result.data.person.id });
            }
          } else if (result.errorCode != 0) {
            if (result.errorCode == 43010) {
              MessageNew.warning(
                {
                  title: '提示',
                  content: result.message,
                  delay: 5,
                },
                () => {
                  this.gtRegister();
                }
              );
            } else if (result.errorCode == 43011) {
              // 需要强制修改密码时
              MessageNew.warning(
                {
                  title: '提示',
                  content: result.message,
                  delay: 5,
                },
                () => {
                  this.gtRegister();
                }
              );
            } else {
              msg = result.message;
            }
          } else {
            msg = '登陆数据异常!';
          }
          this.isExtist &&
            this.setState({
              msg: msg,
              loading: false,
            });
        },
        err => {
          this.isExtist &&
            this.setState({
              msg: '登陆失败! 错误码:' + err.status,
              loading: false,
            });
        }
      );
    }
  };

  replaceEmpty = e => {
    e.target.value = e.target.value.replace(/\s/g, '');
  };

  render() {
    return (
      <div className="login" style={{ padding: '32px 64px 0' }}>
        <div className="input-name">用户名</div>
        <input
          ref={person => (this.person = person)}
          className="input-style"
          type="text"
          name="userName"
          onKeyDown={this.handleKeyDown}
          onChange={this.replaceEmpty}
          placeholder="请输入手机号或者用户名"
        />
        <div className="input-name" style={{ paddingTop: '9px' }}>
          密码
        </div>
        <input
          ref={password => (this.password = password)}
          className="input-style"
          type="password"
          name="passWord"
          onKeyDown={this.handleKeyDown}
          placeholder="请输入密码"
        />
        <button className="btn-login" disabled={this.state.loading} onClick={this.submit} style={{ marginTop: '55px' }}>
          {this.renderLoading()}登 录
        </button>
        <div className="login-msg">{this.state.msg}</div>
        <div className="login-line" />
        <a className="lose-password" onClick={this.gtRegister}>
          忘记密码？
        </a>
      </div>
    );
  }
}
