/**
 * Created by neo on 16/4/12.
 */
import {ElementClass} from 'component';

function getValue(string) {
  return string || '';
}

const allBodyClass = ['app-home', 'app-main', 'app-signin'];

export default {

  getDefaultCity() {
    let cityJson = Dict.Common.defaultCity;

    if (localStorage.managerDefaultCity) {
      cityJson = JSON.parse(localStorage.managerDefaultCity);
    }

    return cityJson;
  },

  setDefaultCity(city) {
    let cityJson = Dict.Common.defaultCity;

    if (localStorage.managerDefaultCity && city == false) {
      cityJson = JSON.parse(localStorage.managerDefaultCity);
    } else if (city) {
      cityJson = city;
      localStorage.managerDefaultCity = JSON.stringify(cityJson);
    }

    return cityJson;
  },

  formatAddress(address, building, floor, unit, room) {
    return getValue(address) + getValue(building) + (floor ? floor + '楼' : '') + getValue(unit) + getValue(room)
  },
  //判断运营商类型
  whichOrganization(type) {
    const staffInfo = common.getStaff();

    let result = false;

    if (staffInfo && staffInfo.orgTypes) {
      const orgTypes = staffInfo.orgTypes.map(item => item.orgType);

      if (orgTypes.indexOf(type + '') >= 0) {
        result = true;
      }
    }

    return result;
  },
  //是资产
  isAssetOrg(asset) {
    return !!this.whichOrganization(asset);
  },
  //是公寓
  isApartmentOrg(apartment) {
    return !!this.whichOrganization(apartment);
  },
  //是电费
  isElectricityOrg(electricity) {
    return !!this.whichOrganization(electricity);
  },
  //是物业公司
  isPropertyOrg(property = Dict.OrgTypes.Property) {
    return !!this.whichOrganization(property);
  },
  switchBody(targetClass, clear = false) {
    const app = ElementClass(document.body);

    allBodyClass.forEach(cls => {
      if (cls != targetClass) {
        app.remove(cls);
      }
    });

    if (app.has(targetClass)) {
      if (clear) {
        app.remove(targetClass);
      }
    } else {
      app.add(targetClass);
    }
  },
  getUserDuty(DutyList, duty) {
    let isDuty = false;
    for (let i = 0; i < DutyList.length; i++) {
      if (DutyList[i].dutyName == duty) {
        isDuty = true;
        break;
      }
    }
    return isDuty;
  },
  getShowPlace(menuResult, moduleId) {
    const matchModule = menuResult.filter(item => {
      return item.key == moduleId;
    });

    const funcKey = {};

    if (matchModule.length) {
      const btnList = matchModule[0].btnList;

      btnList.forEach(item => {
        funcKey[`f${item.funCode}`] = true;
      })
    }

    return funcKey;
  }
}

