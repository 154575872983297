import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { PanelFactory } from 'component';

import util, { layComponent } from '../util';

const create = PanelFactory.create;

export default function financial(app) {
  return (
    <Route name="账务" path="/financial" component={create()} onEnter={util.requireAuth} menuKey="110">
      <IndexRoute onEnter={(nextState, replace) => replace('/financial/platformAccount')} />
      <Route
        name="待办事项"
        path="/financial/todo"
        component={layComponent(() => import('../../Financial/Todo'))}
        onEnter={util.requireAuth}
        menuKey="110007"
      />
      <Route
        name="预付费流水"
        path="/financial/predepositJournal"
        component={layComponent(() => import('../../Financial/PredepositJournal'))}
        onEnter={util.requireAuth}
        menuKey="110005"
      />
      <Route
        name="预付费"
        path="/financial/prepaid"
        component={layComponent(() => import('../../Financial/Prepaid'))}
        onEnter={util.requireAuth}
        menuKey="110006"
      />
      <Route
        name="账本流水"
        path="/financial/bookJournal"
        component={layComponent(() => import('../../Financial/BookJournal'))}
        onEnter={util.requireAuth}
        menuKey="110002"
      />
      <Route
        name="账单管理"
        path="/financial/billManagement"
        component={layComponent(() => import('../../Financial/BillManagement'))}
        onEnter={util.requireAuth}
        menuKey="110003"
      />
      <Route
        name="应收账款"
        path="/financial/plansReceivable"
        component={layComponent(() => import('../../Financial/PlansReceivable'))}
        onEnter={util.requireAuth}
        menuKey="110004"
      />
      <Route
        name="手工入账"
        path="/financial/manualCheckin"
        component={layComponent(() => import('../../Financial/ManualCheckin'))}
        onEnter={util.requireAuth}
        menuKey="110010"
      />
      <Route
        name="管家结账"
        path="/financial/housekeeperCheckout/(:action)"
        component={layComponent(() => import('../../Financial/HousekeeperCheckout'))}
        onEnter={util.requireAuth}
        menuKey="110011"
      />
      <Route
        name="充值结账"
        path="/financial/recharge-audit"
        component={layComponent(() => import('../../Financial/RechargeAudit'))}
        onEnter={util.requireAuth}
        menuKey="110012"
      />
      <Route
        name="租客退款"
        path="/financial/renterRefund"
        component={layComponent(() => import('../../Financial/RenterRefund'))}
        onEnter={util.requireAuth}
        menuKey="110008"
      />
      <Route
        name="平台结算"
        path="/financial/platformCheckout"
        component={layComponent(() => import('../../Financial/PlatformCheckout'))}
        onEnter={util.requireAuth}
        menuKey="110015"
      />
      <Route
        name="退房结算"
        path="/financial/settlementApproval"
        component={layComponent(() => import('../../Financial/SettlementApproval'))}
        onEnter={util.requireAuth}
        menuKey="110009"
      />
      <Route
        name="公章管理"
        path="/financial/stamp"
        component={layComponent(() => import('../../Financial/StampManagement/StampManagement'))}
        onEnter={util.requireAuth}
        menuKey="110014"
      />
      <Route
        name="参数管理"
        path="/financial/config"
        component={layComponent(() => import('../../Financial/SystemConfig'))}
        onEnter={util.requireAuth}
        menuKey="110013"
      />
      <Route
        name="平台账户"
        path="/financial/platformAccount/(:action)"
        component={layComponent(() => import('../../Financial/PlatformAccount'))}
        onEnter={util.requireAuth}
        menuKey="110001"
      />
      <Route
        name="提现记录"
        path="/financial/withdrawRecord"
        component={layComponent(() => import('../../Financial/WithDrawRecord'))}
        onEnter={util.requireAuth}
      />
      <Route
        name="月度账单"
        path="/financial/monthlyBill"
        component={layComponent(() => import('../../Financial/MonthlyBill'))}
        onEnter={util.requireAuth}
        menuKey="110017"
      />
    </Route>
  );
}
