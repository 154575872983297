/* eslint-disable */
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment';
import 'moment/locale/zh-cn';

import './assets/css/style.css';
// import './views/entry/style.css';
import './assets/style/resetAnt/reset.less';
import './views/User/SignIn/platform-check.css';

import './require-babel-polyfill';
import AppConfig from './config/app.config';
import Dict from './config/dict';
import App from './views';

common.api = AppConfig.api;
common.baseInfo = AppConfig.baseInfo;
window.Dict = Dict;

moment.locale('zh-cn');

ReactDOM.render(
  <App />,
  document.getElementById('app'),
)
