/* eslint-disable react/self-closing-comp,max-len,no-irregular-whitespace*/
import React from 'react';

const agreementTxt = `
<h6 style="color:#4e5052; font-size:0.9rem;">特别提示：  </h6>
<p>您在使用宅在张江提供的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容，特别是以粗体标注的部分，包括但不限于免除或者限制责任的条款。
您一旦在本网站注册，即视为您已了解并完全同意本服务协议各项内容，包括宅在张江对服务协议随时所做的任何修改，并成为宅在张江用户。 </p>
<p>
<h6 style="color:#4e5052; font-size:0.9rem;">一、总则  </h6>
1.1在进行注册过程中只要您点击注册页面的"注册"按钮并按照本网站注册程序成功注册为用户，您的行为表示您同意并签署了本服务协议，
本服务协议即在您和本网站之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本网站咨询。<br/> 
1.2用户注册成功后，宅在张江将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。<br/> 
1.3用户一经注册宅在张江帐号，除非子频道要求单独开通权限，用户有权利用该帐号使用宅在张江各个频道的单项服务，当用户使用宅在张江各单项服务时，用户的使用行为
视为其对该单项服务的服务条款以及宅在张江在该单项服务中发出的各类公告的同意。<br/> 
</p>
<p>
<h6 style="color:#4e5052; font-size:0.9rem;">二、用户信息使用及保护规则</h6>
2.1宅在张江帐号（即宅在张江用户ID）的所有权归宅在张江，用户按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得宅在张江帐号并成为宅在张
江用户。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实或更新
不及时而引发的相关问题，宅在张江不负任何责任。用户提交的帐号名称、头像和简介等注册信息中不得出现违法和不良信息，经宅在张江审核，如存在上述情况，宅在张江将
不予注册；同时，在注册后，如发现用户以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，宅在张江有权不经通知单方采取限期改正、暂
停使用、注销登记、收回等措施。 <br/>  2.2为了向您提供更好的服务，宅在张江会在您首次注册或使用本网站服务的情况下系统自动存储您的相关信息。如您发现您的帐号遭他人
非法使用，应立即通知宅在张江。互联网上不排除因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，此类情况与宅在张江无关。<br/>   
2.3因您使用宅在张江服务而获取的信息有  <br/> （1）日志信息。当您使用宅在张江服务时，服务器会自动记录一些信息，例如您对我们服务的使用情况、IP地址、所访问服务的
URL、浏览器的类型和使用的语言以及访问日期和时间等。  <br/> （2）设备信息。某些产品和/或服务包含唯一应用程序编号。当您安装、激活、更新、卸载相关服务或当这些服务
定期与宅在张江通信（例如软件的更新）时，系统会将此编号以及与安装相关的信息（例如操作系统类型和应用程序版本号）发送给宅在张江。   当您使用具有定位功能的宅在
张江服务时，系统会自动处理有关设备的位置信息，以使得您不需要手动输入自身地理坐标就可获得相关服务。例如在宅在张江app找房服务中，如果您使用“城市”进行房源查
询，宅在张江可能会向您提供基于位置的服务。  为了提供更好的服务与改善用户体验，宅在张江可能会记录硬件型号、操作系统版本号、国际移动设备身份码（IMEI）、网络
设备硬件地址（MAC）等信息。  <br/> （3）Cookie和匿名标示符等工具。cookie主要的功能是便于您使用网站产品和/或服务，以及帮助网站统计独立访客数量等。运用cookie技术
，宅在张江能够为您提供更加周到的个性化服务，并允许您设定您特定的服务选项。  当您可以选择拒绝 cookie。您可以通过修改浏览器设置的方式拒绝cookie。如果您选择拒
绝cookie，则您可能无法登录或使用依赖于cookie的宅在张江服务或功能。  以上数据信息都采用匿名的方式。同时，我们也会对信息采取加密处理，保证信息的安全性。 
<br/> 2.4宅在张江将合理使用所存储的信息，使用范围包括【 1、为了让您更多地了解市场变化趋势，及时掌握有关讯息，我们会根据您预留的联系方式，不定期向您推送最新的房产
知识及行情变化； 2、为了更好地服务客户，我们会不断地对本网站系统进行技术更新，在此过程中可能需要使用您的信息进行技术改造相关工作；3、我们可能会对宅在张江服务
的使用情况进行统计。同时，宅在张江可能会与公众分享这些统计信息，以展示我们服务的整体使用趋势或市场行情的客观情况，这些统计信息不包含您的任何身份识别信息； 4、
为实现您房屋买卖或出租承租之居间义务而查看、向其他宅在张江客户推荐、与第三方合作机构共享您的个人信息；5、为了更好的向您提供资产管理服务，我们可能会将您的有
限信息提供给宅在张江旗下的资产管理公司；】。宅在张江承诺，以上信息的使用将严格遵循最少够用原则，即尽量最少的使用您的信息以达到使用目的。  
<br/>2.5请您不要将您的帐号、密码转让或出借予他人使用。如您发现您的帐号遭他人非法使用，应立即通知宅在张江，互联网上不排除因黑客行为或用户的保管疏忽导致帐号、密码
遭他人非法使用，此类情况与宅在张江无关。 <br/> 
2.6当您使用宅在张江产品进行支付、登录等操作的时候，服务器会自动收取并记录一些必要的信息，如IP地址、服务访问异常信息、以及部分设备信息等，以保障您在使用宅
在张江服务时账户登录和支付过程的安全，进而保护您的上网安全。您使用宅在张江的服务，即表示您同意宅在张江可以按照本协议的相关约定处理您的个人信息。宅在张江可
能会与合作伙伴共同向您提供您所要求的服务或者共同向您展示您可能感兴趣的内容。在信息为该项产品/服务所必须的情况下，您同意宅在张江可与其分享必要的信息。并且，
宅在张江会要求其确保数据安全并且禁止用于任何其他用途。除此之外，宅在张江不会向任何无关第三方提供或分享信息。<br/>   2.7在如下情况下，宅在张江可能会披露您的信息: <br/>
（1）事先获得您的授权； <br/> （2）您使用共享功能；<br/>  （3）根据法律、法规、法律程序的要求或政府主管部门的强制性要求；<br/>  （4）以学术研究或公共利益为目的；<br/>  （5）为维护宅在
张江的合法权益，例如查找、预防、处理欺诈或安全方面的问题；<br/>  （6）符合相关服务条款或使用协议的规定。<br/>  2.8您知悉并授权，宅在张江仅在必需的情况下使用或与关联公
司同步您的信息，以为用户提供征信相关服务。<br/> 2.9为更好地向用户提供服务，您同意宅在张江通过短信等形式向您发送相关服务信息。
</p>
<p>
<h6 style="color:#4e5052; font-size:0.9rem;">三、使用规则</h6>  
3.1 用户在使用宅在张江服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限
于下列行为:  <br/>（1）上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：<br/>  &emsp;&emsp;1）反对宪法所确定的基本原则的； <br/> &emsp;&emsp;2）危害国家安全，泄露国家秘密，颠覆国家政
权，破坏国家统一的；<br/>  &emsp;&emsp;3）损害国家荣誉和利益的；  <br/> &emsp;&emsp;4）煽动民族仇恨、民族歧视、破坏民族团结的； <br/>&emsp;&emsp;5）破坏国家宗教政策，
宣扬邪教和封建迷信的；<br/> &emsp;&emsp; 6）散布谣言，扰乱社会秩序，破坏社会稳定的； <br/> &emsp;&emsp;7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>&emsp;&emsp;8）侮
辱或者诽谤他人，侵害他人合法权利的；<br/> &emsp;&emsp;9）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；<br/>&emsp;&emsp;10）
含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；  <br/>（2）不得为任何非法目的而使用网络服务系统；<br/>（3）不利用宅在张江服务从事以下活动：<br/> &emsp;&emsp; 1)未经允许，进入计算机信息网络或者使用计算机信息网络资源的； <br/>  
&emsp;&emsp;2)未经允许，对计算机信息网络功能进行删除、修改或者增加的；<br/> &emsp;&emsp;3)未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；<br/>  
 &emsp;&emsp;4)故意制作、传播计算机病毒等破坏性程序的； &emsp;&emsp;5)其他危害计算机信息网络安全的行为。  <br/> 3.2 用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的
任何索赔、要求或损失，包括合理的律师费，您同意赔偿宅在张江与合作公司、关联公司，并使之免受损害。对此，宅在张江有权视用户的行为性质，采取包括但不限于删除
用户发布信息内容、暂停使用许可、终止服务、限制使用、回收宅在张江帐号、追究法律责任等措施。对恶意注册宅在张江帐号或利用宅在张江帐号进行违法活动、捣乱、骚
扰、欺骗、其他用户以及其他违反本协议的行为，宅在张江有权回收其帐号。同时，宅在张江公司会视司法部门的要求，协助调查。 <br/>  3.3 用户不得对本服务任何部分或本
服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。<br/>   3.4 用户须对自己在使用宅在张江服务过程中的行为承担法律责任。用户承担法律责任的形式
包括但不限于：对受到侵害者进行赔偿，以及在宅在张江公司首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予宅在张江公司等额的赔偿。 
</p>
<p><h6 style="color:#4e5052; font-size:0.9rem;">四、服务内容</h6>
  4.1 宅在张江网络服务的具体内容由宅在张江根据实际情况提供。<br/>   4.2 除非本服务协议另有其它明示规定，宅在张江所推出的新产品、新功能、新服务，
 均受到本服务协议之规范。  <br/> 4.3 为使用本服务，您必须能够自行经有法律资格对您提供互联网接入服务的第三方，进入国际互联网，并应自行支付相关服务
 费用。此外，您必须自行配备及负责与国际联网连线所需之一切必要装备，包括计算机、数据机或其它存取装置。 <br/>  4.4 鉴于网络服务的特殊性，用户同意
 宅在张江有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。宅在张江不担保网络服务不会中断，对网络服务的及时性、安全
 性、准确性也都不作担保。  <br/> 4.5 免责声明：因以下情况造成网络服务在合理时间内的中断，宅在张江无需为此承担任何责任；<br/>   （1）宅在张江需
 要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，宅在张江保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。 
  <br/> （2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因； <br/>  （3）用户的电脑软硬件和通信线路、供电线路出现故障的； <br/>  （4）因
 病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。  尽管有前款约定
 ，宅在张江将采取合理行动积极促使服务恢复正常。 <br/>  4.6 本服务或第三人可提供与其它国际互联网上之网站或资源之链接。由于宅在张江无法控制这
 些网站及资源，您了解并同意，此类网站或资源是否可供利用，宅在张江不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，宅在张江
 亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，宅在张江不承担任何责任。
  <br/>  4.7 终止服务  您同意宅在张江得基于其自行之考虑，因任何理由，包含但不限于长时间（超过一年）未使用，或宅在张江认为您已经违反本服务协议的文字
  及精神，终止您的密码、帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无
  需进行事先通知即可中断或终止，您承认并同意，宅在张江可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，
  您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，宅在张江对您或任何第三人均不承担任何责任。 
</p>
<p>
<h6 style="color:#4e5052; font-size:0.9rem;">五、其他 </h6>
 5.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。 <br/>
 5.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向宅在张江所在地上海市浦东新区人民法院提起诉讼。<br/> 
 5.3 宅在张江未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。<br/>  5.4 如本协议中的任何条款无论因何种原因完全或部分无效或<br/>
不具有执行力，本协议的其余条款仍应有效并且有约束力。 <br/> 5.5 本网站有权在必要时修改服务条款，本网站服务条款一旦发生变动，将会在重要页面上提示
修改内容。如果不同意所改动的内容，用户可以主动取消获得的本网站信息服务。如果用户继续享用本网站信息服务，则视为接受服务条款的变动。本网站保
留随时修改或中 断服务而不需通知用户的权利。本网站行使修改或中断服务的权利，不需对用户或第三方负责。本协议的修改与解释权归智房联东公司。</p>`;

export default function agreement() {
  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: agreementTxt }} />
  );
}
