export default {
  105: { name: '配置', path: 'config/product/list', icon: 'config' },
  105001: { name: '客房产品', path: 'config/product/list', leaf: 'true' },
  105002: { name: '资源价格', path: 'config/resourcePrice/list', leaf: 'true' },
  // 105003: { name: '共享资源', path: '/apartment/resources/list', leaf: 'true' },
  105004: { name: '支付方式', path: '/apartment/rentpaymethod', leaf: 'true' },
  105005: { name: '数据导入', path: '/data/import', leaf: 'true' },
  105006: { name: '第三方导入', path: '/data/thirdimport', leaf: 'true' },
  105007: { name: '参数配置', path: '/apartment/ManagementCenterParameters', leaf: 'true' },
  // 105008: {name: '同住人授权', path: '/apartment/housemate', leaf: 'true'},
  105009: { name: '欠费策略', path: '/apartment/arrears', leaf: 'true' },
  105001001: { name: '客房产品配置', path: 'product/list' },
  105002001: { name: '资源价格配置', path: 'resourcePrice/list' },
  105003001: { name: '共享资源配置', path: '/apartment/resources/list' },
  105004001: { name: '支付方式配置', path: '/apartment/rentpaymethod' },
  105005001: { name: '数据导入', path: '/data/import' },
  105006001: { name: '第三方导入', path: '/data/thirdimport' },
  105007001: { name: '客房参数配置', path: '/apartment/ManagementCenterParameters' },
  105008001: { name: '同住人授权', path: '/apartment/housemate' },
  105009001: { name: '欠费策略', path: '/apartment/arrears' },
};
