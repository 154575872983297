import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';

import util, {layComponent} from '../util';

const create = PanelFactory.create;

export default function equipment(app) {
  return (
    <Route name="设备"
      path="/maintain"
      component={create()}
      onEnter={util.requireAuth}
      menuKey={104}
    >
      <IndexRoute onEnter={(nextState, replace) => replace('/maintain/devicestatus')}/>
      <Route
        name="设备状态"
        path="/maintain/devicestatus"
        component={layComponent(() => import('../../Maintain/DeviceStatus'))}
        onEnter={util.requireAuth}
        menuKey={104001}
      />
      <Route
        name="门锁配置"
        path="/maintain/lock-config"
        component={layComponent(() => import('../../Maintain/LockConfig'))}
        onEnter={util.requireAuth}
        menuKey={104003}
      />
      <Route
        name="门锁管理"
        path="/maintain/lockmanage"
        component={layComponent(() => import('../../Maintain/LockManage/LockManage'))}
        onEnter={util.requireAuth}
        menuKey={104004}
      />
      <Route
        name="门锁日志"
        path="/maintain/locklog"
        component={layComponent(() => import('../../Maintain/LockLog'))}
        onEnter={util.requireAuth}
        menuKey={104005}
      />
      <Route
        name="设备配置"
        path="/maintain/device-config"
        component={layComponent(() => import('../../Maintain/DeviceConfig'))}
        onEnter={util.requireAuth}
        menuKey={104002}
      />
      <Route
        name="水表配置"
        path="/maintain/wartermeter-config"
        component={layComponent(() => import('../../Maintain/WaterMeterConfig'))}
        onEnter={util.requireAuth}
        menuKey={104006}
      />
      <Route
        name="水表管理"
        path="/maintain/wartermeter-management"
        component={layComponent(() => import('../../Maintain/WaterMeterManagement'))}
        onEnter={util.requireAuth}
        menuKey={104007}
      />
      <Route
        name="装锁申请"
        path="/maintain/LockApplyFor/ApplyForList"
        component={layComponent(() => import('../../Maintain/LockApplyFor/ApplyForList'))}
        onEnter={util.requireAuth}
        menuKey={104008}
      />
    </Route>
  )
}
