//财务
export default {
  110: {
    name: '财务',
    path: '/financial',
    icon: 'finance',
  },
  // 110001: {
  //   name: '平台账户',
  //   path: '/financial/platformAccount/',
  //   leaf: true,
  // },
  110002: {
    name: '账本流水',
    path: '/financial/bookJournal',
    leaf: true,
  },
  110003: {
    name: '账单管理',
    path: '/financial/billManagement',
    leaf: true,
  },
  110004: {
    name: '应收账款',
    path: '/financial/plansReceivable',
    leaf: true,
  },
  110005: {
    name: '预付费流水',
    path: '/financial/predepositJournal',
    leaf: true,
  },
  110006: {
    name: '预付费',
    path: '/financial/prepaid',
    leaf: true,
  },
  110007: {
    name: '待办事项',
    path: '/financial/todo',
    leaf: true,
  },
  110008: {
    name: '租客退款',
    path: '/financial/renterRefund',
    leaf: true,
  },
  110009: {
    name: '退房结算',
    path: '/financial/settlementApproval',
    leaf: true,
  },
  // 110010: {
  //   name: '手工入账',
  //   path: '/financial/manualCheckin',
  //   leaf: true,
  // },
  // 110011: {
  //   name: '管家结账',
  //   path: '/financial/housekeeperCheckout/',
  //   leaf: true,
  // },
  // 110012: {
  //   name: '充值结账',
  //   path: '/financial/recharge-audit',
  //   leaf: true,
  // },
  // 110013: {
  //   name: '参数管理',
  //   path: '/financial/config',
  //   leaf: true,
  // },
  110014: {
    name: '公章管理',
    path: '/financial/stamp',
    leaf: true,
  },
  110015: {
    name: '平台结算',
    path: '/financial/platformCheckout',
    leaf: true,
  },
  110016: {
    name: '收款账户',
    path: '/financial/account',
    leaf: true,
  },
  110017: {
    name: '月度账单',
    path: '/financial/monthlyBill',
    leaf: true,
  },
};
