export default {
  104: { name: '设备', path: '/maintain/devicestatus', icon: 'equipment' },
  // 104001: { name: '设备状态', path: '/maintain/devicestatus', leaf: 'true' },
  // 104002: {name: '设备配置', path: '/maintain/device-config', leaf: 'true'},
  // 104003: {name: '门锁配置', path: '/maintain/lock-config', leaf: 'true'},
  104004: { name: '门锁管理', path: '/maintain/lockmanage', leaf: 'true' },
  104005: { name: '门锁日志', path: '/maintain/locklog', leaf: 'true' },
  // 104006: { name: '水表配置', path: '/maintain/wartermeter-config', leaf: 'true' },
  // 104007: { name: '水表管理', path: '/maintain/wartermeter-management', leaf: 'true' },
  // 104008: {name: '装锁申请', path: '/maintain/LockApplyFor/ApplyForList', leaf: 'true'},
  104001001: { name: '设备状态', path: '/maintain/devicestatus' },
  104002001: { name: '设备配置', path: '/maintain/device-config' },
  104003001: { name: '门锁配置', path: '/maintain/lock-config' },
  104004001: { name: '门锁管理', path: '/maintain/lockmanage' },
  104005001: { name: '门锁日志', path: '/maintain/locklog' },
  104006001: { name: '水表配置', path: '/maintain/wartermeter-config' },
  104007001: { name: '水表管理', path: '/maintain/wartermeter-management' },
  104008001: { name: '装锁申请', path: '/maintain/LockApplyFor/ApplyForList' },
};
