import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';

import util, {layComponent, getPropertyNavName} from '../util';

const create = PanelFactory.create;

export default function property(app) {
  return (
    <Route
      name="物业"
      path="/property"
      component={create()}
      onEnter={util.requireAuth}
      menuKey={102}
    >
      <IndexRoute onEnter={(nextState, replace) => replace('/property/manage/list')}/>
      <Route
        name="物业管理"
        path="/property/manage/(:action)"
        component={layComponent(() => import('../../HouseResource/ManageWrapper'))}
        onEnter={util.requireAuth}
        getDisplayName={getPropertyNavName('新建')}
        menuKey={102001}
      />
      <Route
        name="业主管理"
        path="/property/homeOwner/(:action)"
        component={layComponent(() => import('../../HouseResource/HomeOwner'))}
        onEnter={util.requireAuth}
        menuKey={102002}
      />
      <Route
        name="关联客房"
        path="/property/houses"
        component={layComponent(() => import('../../HouseResource/HouseMount'))}
        onEnter={util.requireAuth}
        menuKey={102003}
      />
      <Route
        name="充值管理"
        path="/property/recharge"
        component={layComponent(() => import('../../HouseResource/RechargeManage'))}
        onEnter={util.requireAuth}
        menuKey={102004}
      />
      <Route
        name="租期开门配置"
        path="/property/rentDoor/(:action)"
        component={layComponent(() => import('../../HouseResource/RentDoor'))}
        onEnter={util.requireAuth}
        getDisplayName={getPropertyNavName('设置')}
        menuKey={102005}
      />
    </Route>
  )
}
