/* eslint-disable react/no-string-refs */
/**
 * Created on 2018-07
 * 主题内容的footer
 */

import React from 'react';
import { Util, MessageNew } from 'component';
import { notification, Button, Icon, Popover } from 'antd';
import config from '../../config/config';
import DeployInfoModal from '../DeployInfoModal';
import './index.less';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wechat_qr: false,
      showNotification: false,
      deployment: {},
    };
  }

  notification = () => {
    const params = {
      message: '更新提示',
      description: '检查到系统有新功能发布，是否立即查看？',
      duration: 0,
      btn: (
        <Button type="primary" size="small" onClick={this.onVersionClick}>
          查看
        </Button>
      ),
      key: config.deployKey,
    };
    if (this.state.showNotification) {
      notification.open(params);
    }
  };

  renderYear = () => {
    const d = new Date();
    return d.getFullYear();
  };

  onDeployInfoModalClose = () => {
    if (localStorage) {
      localStorage.setItem('localVersion', this.state.deployment.versionNo || '');
    }
    notification.close(config.deployKey);
  };

  render() {
    const { hide = false } = this.props;

    return (
      <footer style={{ display: hide ? 'none' : 'block' }}>
        <div className="main-footer" ref="footer">
          <span className="nopadding copyright">
            COPYRIGHT ©<a target="_blank">上海联寓智能科技有限公司</a>
          </span>
        </div>
        <DeployInfoModal ref="update" deployment={this.state.deployment} onClose={this.onDeployInfoModalClose} />
      </footer>
    );
  }
}

export default Footer;
