/**
 * Created by neo on 16/5/6.
 */

import React from 'react';
import {Util} from 'component';
import {notification, Card} from 'antd';
import LocalUtil from '../../../components/Util';
import Footer from './Footer';
import Actions from '../../../actions/actions';
import SigninStore from '../../../stores/signinStore'
import config from '../../../config/config';
import './center.css';

class Center extends React.Component {
  constructor(props) {
    super(props);

    const staffList = common.getStaffList();

    this.state = {
      staffList: staffList
    };
  }

  componentDidMount() {
    LocalUtil.switchBody('app-signin')
  }

  componentWillMount() {
    const cookie = common.getCookie(common.baseInfo.cookieName);

    if (localStorage[common.userInfo] == undefined || cookie == '' || this.state.staffList == false || this.state.staffList.length <= 0) {
      Util.push('/signin');
    }
  }

  componentWillUnmount() {
    notification.close(config.deployKey);
  }

  chooseOrg = (org) => {
    //设置之后,跳转到首页
    const params = {
      staffId: org.id,
      loadingElementId: 'body'
    };
    Actions.chooseOperation(params)
      .then((result) => {
        if (result.errorCode == 0) {
          SigninStore.updateCurrentStaff(org);
          // MessageNew.success("成功!", 1, function () {
          // Util.push("/housekeeper/roomStatistics")
          // 特殊处理演示运营商跳转
          if (org.orgId === 104442) {
            Util.push('/housekeeper/roomStatus/list')
          } else {
            Util.push('/home', {id: this.props.location.query.id, staffId: org.id})
          }
          // });
        } else {
          /*  MessageNew.error({
              title: '切换失败',
              content: result.message
            });*/
        }
      });
  }

  renderOperationList() {
    let result = [];
    if (this.state.staffList) {
      result = (
        this.state.staffList.map(item => (
          <li key={item.id}>
            <a onClick={this.chooseOrg.bind(this, item)}>{item.orgName || '获取名称失败'}</a>
          </li>
        ))
      );
    }
    return result;
  }

  render() {
    return (
      <div className="operations-center clearfix">
        <div className="operations-list">
          <Card title="选择运营商">
            <ul className="clearfix">
              {this.renderOperationList()}
            </ul>
          </Card>
        </div>
        <Footer hide/>
      </div>
    );
  }
}

export default Center;
