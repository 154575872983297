import assets from './assets';
import configuration from './configuration';
import customer from './customer';
import employee from './employee';
import equipment from './equipment';
import finance from './finance';
import operation from './operation';
import property from './property';
import report from './report';
import room from './room';
import service from './service';
import risk from './risk'

export default {
  ...assets,
  ...configuration,
  ...customer,
  ...employee,
  ...equipment,
  ...finance,
  ...operation,
  ...property,
  ...report,
  ...room,
  ...service,
  ...risk
}
