/**
 * Created by neo on 2017/5/22.
 */

import React from 'react';
import {Route, IndexRoute} from 'react-router';
import {PanelFactory} from 'component';

import util, {layComponent} from '../util';

const create = PanelFactory.create;

export default function zone(app) {
  return (
    <Route name="个人中心"
      path="/zone"
      component={create()}
      onEnter={util.requireAuth}>
      <IndexRoute onEnter={(nextState, replace) => replace('/zone/personal')}/>
      <Route name=""
        path="/zone/personal"
        component={layComponent(() => import('../../Zone/PersonalIndex'))}
        onEnter={util.requireAuth}/>
    </Route>
  )
}
